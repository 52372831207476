import React from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/Components/ui/dialog'
import { EditNoteInput } from './Overview'
import * as Icon from 'react-feather'
import moment from 'moment'

const noteKeys = [ 
    'assessment_interventions', 'assessment_progress', 'assessment_observations', 
    'subjective_history', 'subjective_psychological', 'subjective_relevant', 
    'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary' 
]

const NoteCard = ({ edit=false, note }) => {

    // const [read, setRead] = useState(false)

  return (
    <div className="w-full bg-white border mt-5 px-4 tablet:px-5 py-5 rounded-[16px] border-[#E2E4E9]">
         <header className="flex items-center justify-between w-full">
          <h3 className="text-[#0A0D14] text-base font-medium">
            Past Session Highlight
          </h3>
          <h3 className="text-[#0a0d1495] text-sm font-medium">
            {moment(note && note?.updated_at).format("MMMM Do YYYY")}
          </h3>
        </header>
        <div className="mt-5 ">
          <p className="text-xs text-[#525866]">Summary</p>
          <p className="text-sm pt-1 text-[#0A0D14]">
            {note.summary ?? 'N/A'}
          </p>
          {/* <button className="text-sm font-medium mt-2 underline text-[#1A6A73]">
            Read Full Note
          </button> */}
          <Dialog>
            <DialogTrigger className="text-sm font-medium mt-2 underline text-[#1A6A73]">
              Read Full Note
            </DialogTrigger>
            <DialogContent style={{ scrollbarWidth: "none", msOverflowStyle: "none" }} className="w-[90vw] overflow-y-scroll max-h-[80vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]">
              <DialogHeader>
                <DialogTitle className="text-left text-[#0A0D14]">
                  Session Note
                </DialogTitle>
              </DialogHeader>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2" />
                <div className="space-y-2">
                  <section className="flex flex-col gap-1">
                      <header className="flex items-center gap-3 text-lg font-semibold">Summary</header>
                      <EditNoteInput
                        name='summary'
                        edit={edit}
                        // onChange={handleInputChange}
                        value={note?.summary ?? 'N/A'}
                        rows={1}
                      />
                    </section>
                    <section className="flex flex-col gap-3">
                      <header className="text-lg font-semibold flex items-center gap-3">Assessment </header>
                      <ul className="flex flex-col gap-3">
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Interventions</span>
                          <EditNoteInput 
                            name={noteKeys[0]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys?.[0]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Progress and Response</span>
                          <EditNoteInput 
                            name={noteKeys[1]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys[1]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Observations</span>
                          <EditNoteInput 
                            name={noteKeys[2]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys[2]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                      </ul>
                    </section>
                    <section className="flex flex-col gap-3">
                      <header className="text-lg font-semibold flex items-center gap-3">Subjective </header>
                      <ul className="flex flex-col gap-3">
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">History of Presenting Complaint</span>
                          <EditNoteInput 
                            name={noteKeys[3]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys?.[3]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Psychological Factors</span>
                          <EditNoteInput 
                            name={noteKeys[4]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys[4]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Relevant Personal and Social History</span>
                          <EditNoteInput 
                            name={noteKeys[5]}
                            edit={edit}
                            // onChange={handleInputChange}
                            value={note?.[noteKeys[5]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                      </ul>
                    </section>
                    <section className="flex flex-col gap-3">
                    <header className="text-lg font-semibold flex items-center gap-3">Objective </header>
                    <ul className="flex flex-col gap-3">
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Appearance</span>
                        <EditNoteInput 
                          name={noteKeys[6]}
                          edit={edit}
                        //   onChange={handleInputChange}
                          value={note?.[noteKeys?.[6]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Behavior</span>
                        <EditNoteInput 
                          name={noteKeys[7]}
                          edit={edit}
                        //   onChange={handleInputChange}
                          value={note?.[noteKeys[7]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Symptoms Observed</span>
                        <EditNoteInput 
                          name={noteKeys[8]}
                          edit={edit}
                        //   onChange={handleInputChange}
                          value={note?.[noteKeys[8]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Risk Assessment</span>
                        <EditNoteInput 
                          name={noteKeys[9]}
                          edit={edit}
                        //   onChange={handleInputChange}
                          value={note?.[noteKeys[9]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                    </ul>
                  </section>
                  {/* <div>
                    {details?.last_session?.note ?? '---'}
                  </div> */}
                </div>
                {/* <button onClick={handleCreateSessionNote} className={`w-fit rounded-[50px] border bg-[#242628] text-[#ffffff] text-sm  py-[10px] font-semibold px-4`}>
                  {isLoading ? <FormLoader /> : 'Update Note'}
                </button> */}
            </DialogContent>
          </Dialog>
        </div>
      </div>
  )
}

export default NoteCard