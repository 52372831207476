import { motion } from "framer-motion";
import mission from "../../assets/Assets/Images/mission.jpg";

const Mission = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      key="hello"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="flex flex-col items-center  justify-center text-[#333333]">
        <div>
          <h1 className="philosopher text-[48px] text-center font-bold mb-3 max-[767px]:text-[24px]">
            Mission Statement
          </h1>
          <div className="w-full max-h-[330px] overflow-hidden rounded-[10px] border-[10px] border-[#f0f0f0]">
            <img
              className="w-full object-cover object-center max-h-[330px]  mx-auto"
              src={mission}
              alt="passion led us here"
            />
          </div>
          <h1 className="text-[18px] mt-5 font-medium mb-[1%] max-[767px]:text-[16px] text-center">
            At Mentra, our mission is to transform mental and emotional support
            by blending cutting-edge technology with genuine human empathy. Our
            goal is to provide personalized, accessible, and impactful support
            for all, removing obstacles to care and empowering individuals to
            achieve wellness and improve quality of life globally.
          </h1>
        </div>

        {/* <div className="mt-[5%] tablet:w-[75%]">
          <img alt="missionVideo" src={missionVideo} />
        </div> */}
      </div>
    </motion.div>
  );
};

export default Mission;
