import { useFavoriteToggleMutation, useGetAllArticlesQuery } from "@/App/services/resourcesApiSlice";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import empty from "../../Images/noWorksheet.png";
import Loader from "@/Components/loader";
import * as Icon from 'react-feather'

const variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
};

const dummyarticles = [
  {
    id: 1,
    title: "Rubbish",
    text: "Find definitions and references for functions and other symbols in this file by clicking a symbol below or in the code.",
    img: "https://plus.unsplash.com/premium_photo-1716309256554-b55aa49d5fbe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHx8"
  },
  {
    id: 2,
    title: "Rubbish",
    text: "Find definitions and references for functions and other symbols in this file by clicking a symbol below or in the code.",
    img: "https://images.unsplash.com/photo-1716316858979-1bd9fd9da3a5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMHx8fGVufDB8fHx8fA%3D%3D",
    favourite: true
  },
  {
    id: 3,
    title: "clicking a symbol below or in the code clicking a symbol below or in the code",
    text: "Find definitions and references for functions and other symbols in this file by clicking a symbol below or in the code.",
    img: "https://plus.unsplash.com/premium_photo-1716309256554-b55aa49d5fbe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHx8"
  },
  {
    id: 4,
    title: "Rubbish",
    text: "Find definitions and references for functions and other symbols in this file by clicking a symbol below or in the code.",
    img: "https://images.unsplash.com/photo-1716319486953-2349501663aa?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D",
    favourite: true
  },
  {
    id: 5,
    title: "Rubbish",
    text: "Find definitions and references for functions and other symbols in this file by clicking a symbol below or in the code.",
    img: ""
  },
]

function Others() {

  const { data:articles, isLoading } = useGetAllArticlesQuery();
  const [ favoriteToggle, { isError, error } ] = useFavoriteToggleMutation();

  const toggleFavoriteArticle = async(event, courseId) => {
    event.stopPropagation();
    const course = {
      wellness_course_id : courseId
    }
    try {
      const res = await favoriteToggle({ ...course }).unwrap();
      toast.success(res?.message)
    } catch(err) {
      toast.error(err?.data?.message)
    }
  }

  return (
    <motion.div
      key="hello"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="w-full bg-white border p-4 tablet:p-5 py-5 rounded-[16px] border-[#E2E4E9]">
        <div className="w-full  flex-col my-auto min-h-[100px] laptop:min-h-[292px]  flex justify-center items-center">
          {/* <h3 className="font-semibold text-center text-xl">No article</h3> */}

          {
            isLoading ?
            <Loader />
            :
            !articles?.data?.length ?
            <div className='flex items-center justify-center m-auto w-full h-full p-6 overflow-y-auto'>
              <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                  <img src={empty} className="w-[108px]" alt="empty" />
                  <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                      There are no articles yet. <br />
                  </p>
              </div>
            </div>
            :
            // <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            //   {
            //     dummyarticles?.map((article) => {
            //       return (
            //         <ArticleCard 
            //           id={article.id}
            //           key={article.id}
            //           text={article.text}
            //           title={article.title}
            //           img={article?.img}
            //           slug={article?.id}
            //           onFavorite={toggleFavoriteArticle}
            //           favorite={article.favourite}
            //           isError={isError}
            //           error={error}
            //           favoriteState={favoriteState}
            //           setFavoriteState={setFavoriteState}
            //         />
            //       )

            //     }) 
            //   }
            // </div>
            <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
              {
                articles?.data?.map((article, index) => (
                  <ArticleCard
                    id={article.id}
                    key={index}
                    text={article.description}
                    title={article.title}
                    img={article?.cover?.url}
                    slug={article?.slug}
                    onFavorite={toggleFavoriteArticle}
                    favorite={article.favourite}
                    isError={isError}
                    error={error}
                    article={article}
                  />
                )) 
              }
            </div>
          }
        </div>
      </div>
    </motion.div>
  );
}

export default Others;


const ArticleCard = ({ id, img, title, text, slug, favorite, onFavorite, article }) => {

  const navigate = useNavigate();

  const onToggleFavorite = (event) => {
    onFavorite(event, id);
  };

  // console.log(img)

  return (
    <div onClick={() => navigate(`article/${slug}`)} className="flex flex-col items-start rounded-xl border overflow-hidden cursor-pointer">
      <section className="w-full relative">
        <div onClick={(e) => onToggleFavorite(e)} className="flex items-center gap-1 absolute top-0 right-0 text-sm bg-black bg-opacity-30 backdrop-blur-sm rounded-full p-1 m-2 cursor-pointer">
          {/* <span className="text-white">Add to favorites</span> */}
          <Icon.Heart fill={`${ favorite ? "#ff0000" : "none" }`} strokeWidth={1} color="#FFFFFF" size={18} />
        </div>
        <img 
          src={img ?? "https://active-asics.de/wp-content/plugins/breedr//assets/no-image-available.png"}
          className="w-full h-full"
          style={{
            backgroundSize: "100% 100%",
            objectFit: "cover",
            width: "100%",
            height: "200px",
            borderRadius: "3px",
            // objectPosition: "top",
          }}
          onError={(e) => {
            e.target.onerror = null || "undefined";
            e.target.src = 
              "https://active-asics.de/wp-content/plugins/breedr//assets/no-image-available.png"
          }}
        />
      </section>
      <main className="flex flex-col items-start gap-3 p-4">
        <p className="text-base font-semibold text-[#0A0D14]">{title}</p>
        <span className="text-[#525866] text-sm line-clamp-3">{text}</span>
      </main>
    </div>
  )
}