import moment from 'moment';
import { motion } from 'framer-motion';
import { messageVariants } from '@/App/pages/Messages';

export const UserCard = ({ text, time }) => {

    return (
        <motion.li variants={messageVariants} className="flex justify-end items-end">
            <div className="flex justify-end">
                <section className='flex flex-col gap-1.5'>
                    <div className='flex item-center justify-between gap-6'>
                        <span className='text-[10px] text-[#475467]'>You</span>
                        <span className="text-[10px] flex items-start justify-start text-[#475467]">{moment(time).format('LT')}</span>
                    </div>
                    <div className="max-w-xl">
                        <div 
                            style={{
                                wordWrap: 'break-word'
                            }} 
                            className='max-w-xl text-wrap'
                        >
                            <p className="p-3 text-sm text-white rounded-l-xl rounded-b-xl bg-[#0D4044]">
                                {text}
                            </p>
                        </div>
                    </div>
                </section>
            </div>
       </motion.li>
    )
}