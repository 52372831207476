import Footer from "../Components/Footer";
import Header from "../Components/Header";
import minus from "../assets/Assets/Images/acc-minus.png";
import plus from "../assets/Assets/Images/acc-plus.png";
import avatar from "../assets/Assets/Images/faq-avatar.png";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import JoinMentra2 from "../Components/JoinMentra2";
import Title from "../Components/Title";
import Loader from "../Components/loader";
import "./pages.css";
import { motion } from "framer-motion";

const Faq = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          import.meta.env.VITE_BASE_API_URL + "/web/faqs"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }

    fetchData();
    return () => {};
  }, []);

  return (
    <div>
      <Title title={"Faqs"} />
      <div className="bg-[#FFFDF2] ">
        <div className="text-center w-full bg-transparent tablet:bg-therapist  lg:bg-cover  bg-no-repeat pt-[150px] min-[768px]:pt-[180px] pb-16 px-5">
          <h1 className="text-[56px] mt-[2%] font-bold max-[767px]:text-[32px] max-[767px]:mt-[5%] leading-[65px] max-[767px]:leading-[40px]">
            Frequently asked <br />
            questions
          </h1>
          <p className="text-[18px] mt-[3%] min-[768px]:mt-[2%] max-[767px]:text-[16px]">
            Answers to the most frequently asked questions.
          </p>
        </div>
        <div className="laptop:max-w-[60%] mt-[80px] w-full mx-auto px-[5vw]">
          {data ? (
            <div className="mt-[80px]">
              <Accordion items={data.data} />
            </div>
          ) : (
            <div className="mt-[20px] mb-[100px]">
              <Loader />
            </div>
          )}
        </div>
        <div className="mt-16 mx-[5vw] mb-0 tablet:mb-8 text-center flex flex-col items-center justify-center px-5 py-12  rounded-[34px] laptop:mx-[15%] bg-[#C7D69F]">
          <img src={avatar} alt="avatar" className="w-[120px] mb-8" />
          <h2 className="font-bold text-[24px] mb-2 leading-[32px]">
            Still have questions?
          </h2>
          <p className="font-medium text-[16px] mb-8 leading-[24px]">
            Can’t find the answer you’re looking for? Please chat to our
            friendly team.
          </p>
          <Link to="/contact">
            <button className="bg-[#20232D] text-white w-[160px] h-[45px] text-[14px] rounded-[30px] shadow-lg max-[1290px]:w-[130px] ">
              Contact us
            </button>
          </Link>
        </div>
        <div className="">
          <JoinMentra2 />
        </div>
        <div className=" ">
          <Footer />
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const togglePanel = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      key="faq"
      variants={variants}
      initial="initial"
      animate="animate"
      className="w-full space-y-[25px]"
    >
      {items.map((item, index) => (
        <div className="w-full" key={index}>
          <div
            className="flex justify-between gap-1 cursor-pointer items-center"
            onClick={() => togglePanel(index)}
          >
            <p className=" capitalize text-[16px] sm:text-[18px] font-medium">
              {item.question}
            </p>
            <img
              src={expandedIndex === index ? minus : plus}
              className="size-[24px]"
              alt="open or close"
            />
          </div>
          <div
            className={`text-[14px] mt-3  leading-normal sm:text-[16px] font-normal overflow-hidden  sm:leading-[24px] ${
              expandedIndex === index ? "max-h-max" : "max-h-0"
            }`}
          >
            <p>{<ConvertHtml value={item.answer} />}</p>
          </div>
          <hr className="h-[1px] mt-6 w-full bg-[#C7D69F]" />
        </div>
      ))}
    </motion.div>
  );
};

function ConvertHtml({ value }) {
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
}

export default Faq;
