import { motion } from "framer-motion";
import adenike from "../../assets/Assets/Images/adenike.png";
import riffa from "../../assets/Assets/Images/riffa.jpeg";
import aseem from "../../assets/Assets/Images/aseem.png";
import linkedin from "../../assets/Assets/Images/linkedin.png";
// import sofia from "../../assets/Assets/Images/sofia.jpg";
import ben from "../../assets/Assets/Images/ben.jpg";
import pramod from "../../assets/Assets/Images/pramod.jpg";
import shazia from "../../assets/Assets/Images/shazia.jpg";

const Team = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      key="team"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="flex flex-col w-full items-center justify-center text-[#333333]">
        <div>
          <h1 className="philosopher text-[48px] text-center font-bold max-[767px]:text-[24px]">
            Our Team
          </h1>
          <h1 className="text-[18px] font-medium mb-[3%] text-center">
            Meet the Minds Behind Mentra
          </h1>
        </div>

        <div className="flex gap-6 pt-8 tablet:gap-14 flex-wrap justify-center">
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={adenike}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="adenike"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] leading-[25px]">
                  Dr. Adenike (Omo) Dairo
                </h2>
                <p className="text-[14px] font-medium capitalize">Founder</p>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/dr-adenike-omo%E2%80%99-d-31125a25/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden ">
                <img
                  src={aseem}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="aseem"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] leading-[25px]">
                  Aseem Srivastava
                </h2>
                <p className="text-[14px] font-medium capitalize">
                  Head of Tech
                </p>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/as3eem/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={riffa}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="riffa"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] leading-[25px]">
                  Riffa Syed
                </h2>
                <p className="text-[14px] font-medium capitalize">
                  Wellness Manager
                </p>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/riffa-syed-a26a40174/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* needs fixing */}
          {/* <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={dami}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="dami"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] leading-[25px]">
                  Damilola Salu
                </h2>
                <p className="text-[14px] font-medium capitalize">
                  Product manager
                </p>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/riffa-syed-a26a40174/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mt-[50px] text-[#333333]">
        <div>
          <h1 className="philosopher text-[48px] text-center font-bold  max-[767px]:text-[24px]">
            Our Advisors
          </h1>
        </div>

        <div className="flex gap-6 pt-8 tablet:gap-14 flex-wrap justify-center">
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={ben}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="adenike"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] mb-[14px] leading-[25px]">
                  Ben Furneaux
                </h2>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/ben-furneaux/"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={shazia}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="adenike"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] mb-[14px] leading-[25px]">
                  Shazia Iqbal
                </h2>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/shaziaiqbal1/"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={sofia}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="adenike"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] mb-[14px] leading-[25px]">
                  Sofia Zaman
                </h2>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/sofiazaman/"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div className=" ">
            <div className="h-[329px] w-[268px] overflow-hidden rounded-[74px] border-4 border-[#FBEDB1]">
              <div className="w-full h-[67%] overflow-hidden">
                <img
                  src={pramod}
                  className=""
                  style={{ objectFit: "cover", objectPosition: "top" }}
                  alt="adenike"
                />
              </div>
              <div className="bg-white h-[33%] pt-4 text-center">
                <h2 className="font-extrabold capitalize text-[18px] mb-[14px] leading-[25px]">
                  Pramod Balakrishnan
                </h2>
                <div className="flex pt-[2px] tablet:pt-[6px] justify-center items-center gap-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/pramod-balakrishnan-666b3810/"
                  >
                    <img
                      className="size-[24px]"
                      src={linkedin}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Team;
