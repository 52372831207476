import { AnimatePresence } from "framer-motion";
import Worksheets from "../components/Resources/Worksheets";
import You from "../components/Resources/You";
import Others from "../components/Resources/Others";
import { useState } from "react";

function Resources() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div>
        <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          Resources
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          Resources for you and your clients.
        </p>
      </div>
      <hr className="w-full mt-7 border-none mb-7 h-[1px] bg-[#EAECF0]" />
      <div className="w-full bg-transparent">
        <div className="bg-white w-full rounded-[10px] p-[10px] overflow-hidden">
          <div className="h-[52px] z-[-2] bg-[#F6F8FA] w-full p-2 rounded-[10px] ">
            <div className="flex *:font-medium  *:text-[13px] *:tablet:text-[15px] *:tablet:leading-[22px] items-center justify-center size-full relative">
              <button
                onClick={() => handleTabChange(0)}
                className={`w-[33.33%] z-[2] ${
                  activeTab === 0 ? "text-[#0A0D14]" : "text-[#868C98]"
                } text-center`}
              >
                Worksheets
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`w-[33.33%] z-[2] ${
                  activeTab === 1 ? "text-[#0A0D14]" : "text-[#868C98]"
                } text-center`}
              >
                For You
              </button>
              <button
                onClick={() => handleTabChange(2)}
                className={`w-[33.33%] z-[2] ${
                  activeTab === 2 ? "text-[#0A0D14]" : "text-[#868C98]"
                } text-center`}
              >
                Others
              </button>
              <div
                className={`bg-white transition-all duration-500 ease-in-out z-[1] top-1/2 transform -translate-y-1/2 absolute rounded-[6px] `}
                style={{
                  width: "33.33%",
                  height: "calc(100%)",
                  left: `calc(${activeTab * 33.33}%)`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5">
          <AnimatePresence mode="wait">
            {activeTab === 0 ? (
              <Worksheets />
            ) : activeTab === 1 ? (
              <You />
            ) : activeTab === 2 ? (
              <Others />
            ) : null}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default Resources;
