import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "../../Components/Footer";
import JoinMentra from "../../Components/JoinMentra";
import { Link, useNavigate } from "react-router-dom";
import { getCoursesApi } from "../../Api";
import { motion } from "framer-motion";
import Title from "../../Components/Title";
import Loader from "../../Components/loader";
import left from "../../assets/Assets/Images/leftArrow.png";
import right from "../../assets/Assets/Images/rightArrow.png";
import audio from "../../assets/Assets/Images/audioIcon.png";

const WellnessIndex = () => {
  const [getCourses, setGetCourses] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // ${getCourses.length > 3 ? "h-[1700px] tablet:h-[944px]" : "h-fit" }
  const [dataFetched, setDataFetched] = useState(false);
  const navigateTo = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(getCourses.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = getCourses.slice(startIndex, endIndex);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 250);
  };

  const generatePaginationNumbers = () => {
    const pages = [];

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      for (let i = 1; i <= 3; i++) {
        pages.push(i);
      }
      if (currentPage > 3 && currentPage <= totalPages - 1) {
        pages.push("...");
        pages.push(currentPage);
        pages.push("...");
        pages.push(totalPages);
      } else {
        pages.push("...");
        pages.push(totalPages);
      }
    }

    // Check if current page is within the range to display ellipsis and replace with "..currentPageNumber.."
    return pages;
  };

  // fetch courses from the api
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCoursesApi();
        const data = response.data;
        setGetCourses(data.data || []);
        setDataFetched(true);
        // console.log(data.data);
      } catch (error) {
        console.error("Error fetching courses:", error.message);
      }
    };

    if (!dataFetched) {
      fetchCourses();
    }
  }, [dataFetched]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle course card click
  const handleCourseClick = (course) => {
    navigateTo(generateSlug(course), { state: course });
  };

  const generateSlug = (course) => {
    return `/wellness-library/${course.slug}/${course.title
      .split(":")[0]
      .replaceAll(" ", "-")
      .toLowerCase()}`;
  };

  //function that checks the the number of words in course.desccription and shortens it not to exceed 20 words and then add ...
  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 15) {
      const truncatedText = words.slice(0, 20).join(" ");
      // const remainingText = words.slice(20).join(" ");
      return (
        <span>
          {truncatedText}...{" "}
          <span className="underline text-blue-600">view more</span>
        </span>
      );
    } else {
      return description;
    }
  };

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <div>
      <Title title={"Wellness"} />

      <div className="bg-[#FFFDF2]">
        <div className="text-center w-full bg-[#FFFDF2] pt-[100px] max-[767px]:pt-16">
          <button className="bg-[none] border-[1px] border-[#FFCC63] text-[#FFCC63] text-[14px] tablet:text-[16px] mt-[2%] rounded-[50px]  py-[8px] px-4 max-[767px]:p-3 max-[767px]:mt-14">
            Wellness library
          </button>
          <h1 className="philosopher max-[767px]:px-[5vw] text-[56px] font-bold mt-[2%] max-[767px]:text-[32px] max-[767px]:mt-[5%] leading-[65px] max-[767px]:leading-[40px]">
            Explore Your Wellness <br />
            Library
          </h1>
          <h1 className="text-[18px] mt-[2%] max-[767px]:text-[14px]">
            Discover a curated collection of resources <br /> designed to
            empower and uplift. From guided <br />
            meditations to insightful articles, explore
            <br />
            content that nurtures your mental health
            <br /> journey.
          </h1>
        </div>
        {getCourses.length === 0 ? (
          <div className="pt-[7%] pb-[5%] tablet:py-[5%]">
            <Loader />
          </div>
        ) : (
          <motion.div
            key={currentPage}
            variants={variants}
            initial="initial"
            animate="animate"
            className={`text-center bg-[#FFFDF2] pt-5 ${
              getCourses.length > 3 ? "pb-10" : "pb-0 tablet:pb-10"
            } px-[5vw] laptop:px-[20%] max-[767px]:px-[5%]`}
            // ${getCourses.length > 3 ? "h-[1700px] tablet:h-[944px]" : "h-fit" }
          >
            <div
              className={`flex flex-col justify-start   gap-6 tablet:gap-8 laptop:gap-5 mt-[2%] max-[767px]:px-[0%]`}
            >
              {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((course, index) => (
                  <Link key={index} to={generateSlug(course)}>
                    <div
                      key={index}
                      onClick={() => handleCourseClick(course)}
                      className="w-full h-[300px] flex justify-start tablet:justify-center border-[1px] overflow-hidden border-[#EAECF0] rounded-[16px] bg-white max-[767px]:flex-col  max-[767px]:h-[555px]"
                    >
                      <div className="flex items-center object-contain w-[35%] h-[100%] overflow-hidden max-[767px]:h-[250px] max-[767px]:w-[100%]">
                        {/* Use course.cover.url or a relevant property from your API response */}
                        <img
                          alt="cover"
                          className=" h-[100%] w-full object-cover  max-[767px]:h-full"
                          // style={{height: "100%"}}
                          src={course.cover.url}
                        />
                      </div>

                      <div className=" flex flex-col justify-between text-left py-6 px-6 w-[65%] max-[767px]:w-[100%]">
                        {/* Use relevant properties from your API response */}
                        <div>
                          <h1 className="text-[14px] text-[#6941C6] font-semibold mb-[5%]">
                            {course.category.name} •{" "}
                            {
                              new Date(course.created_at)
                                .toISOString()
                                .split("T")[0]
                            }
                          </h1>
                          <h1 className="text-[#002C3C] text-[18px] font-medium mb-[5%]">
                            {course.title}
                          </h1>

                          <h1 className="text-[#475467] text-[16px] font-regular">
                            {truncateDescription(course.description)}
                          </h1>
                        </div>

                        {/* Use relevant properties from your API response */}
                        <div className="flex justify-between items-center max-[767px]:mt-[5%] ">
                          {/* Use course.category.name or another property representing tags */}
                          <button className="bg-[#F9F5FF] h-fit px-[15px] border-[#E9D7FE] border-[1px] rounded-[10px] mr-[3%] text-[#6941C6]">
                            {course.category.name}
                          </button>
                          {course.attachments?.length > 0 &&
                          course.attachments.find(
                            (item) => item.file?.mime_type === "mp3"
                          ) ? (
                            <img
                              className="w-[24px]"
                              src={audio}
                              alt="audio icon"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p></p>
              )}
            </div>
            <div></div>
          </motion.div>
        )}
      </div>
      {getCourses.length !== 0 && getCourses.length > 3 ? (
        <div>
          <div className="bg-[#FFFDF2]">
            <hr className="h-[1px] bg-[#475467] mx-[5vw] laptop:mx-[15%]" />
          </div>
          <div className="flex tablet:hidden items-center pt-4 justify-center bg-[#FFFDF2]">
            {generatePaginationNumbers().map((pageNumber, index) => (
              <button
                className={`size-[40px] transition-all duration-100 ease-in-out text-[14px] ${
                  currentPage === pageNumber ? "bg-[#F9FAFB]" : ""
                } rounded-[8px] leading-[20px] font-medium flex items-center justify-center cursor-pointer`}
                key={index}
                onClick={() => handlePageClick(pageNumber)}
                disabled={pageNumber === "..." || pageNumber === currentPage}
              >
                {pageNumber}
              </button>
            ))}
          </div>
          <div className=" bg-[#FFFDF2] flex items-center pt-3 tablet:pt-6 px-[5vw] pb-4 laptop:px-[15%] justify-between">
            <button
              className={`flex ${
                currentPage === 1 ? "opacity-0" : ""
              } text-[#475467] text-[14px] tablet:text-[16px] transform hover:font-semibold transition-all duration-300 ease-in-out cursor-pointer items-center justify-between font-medium gap-2`}
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img
                src={left}
                alt="previous"
                className="tablet:size-[14px] size-[12px]"
              />
              Previous
            </button>
            <div className=" hidden tablet:flex items-center">
              {generatePaginationNumbers().map((pageNumber, index) => (
                <button
                  className={`size-[40px] transition-all duration-100 ease-in-out text-[14px] ${
                    currentPage === pageNumber ? "bg-[#F9FAFB]" : ""
                  } rounded-[8px] leading-[20px] font-medium flex items-center justify-center cursor-pointer`}
                  key={index}
                  onClick={() => handlePageClick(pageNumber)}
                  disabled={pageNumber === "..." || pageNumber === currentPage}
                >
                  {pageNumber}
                </button>
              ))}
            </div>
            <button
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`flex ${
                currentPage === totalPages ? "opacity-0" : ""
              } text-[#475467] text-[14px] tablet:text-[16px] transform hover:font-semibold transition-all duration-300 ease-in-out  cursor-pointer items-center justify-between font-medium gap-2`}
            >
              Next
              <img
                src={right}
                className="tablet:size-[14px] size-[12px]"
                alt="next"
              />
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <JoinMentra />
      <Footer />
    </div>
  );
};

export default WellnessIndex;
