import { apiSlice } from "../api/apiSlice";

export const forgotPasscodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: ({formData})  => ({
        url: `/auth/password/forgot`,
        method: "post",
        body: formData,
      }),
    }),
    sendOtp: builder.mutation({
      query: ({formData})  => ({
        url: `/auth/otp/verify`,
        method: "post",
        body: formData,
      }),
    }),
    resetPassCode: builder.mutation({
      query: ({formData})  => ({
        url: `/auth/password/reset-therapy`,
        method: "post",
        body: formData,
      }),
    }),
  }),
});

export const { useSendEmailMutation, useSendOtpMutation, useResetPassCodeMutation } = forgotPasscodeApiSlice;
