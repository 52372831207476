import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Pusher from "pusher-js";
import moment from "moment";
import * as Icon from "react-feather";
import DialingTone from "../../assets/Assets/Audio/dailing.mp3";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "../services/authSlice";
import SessionNote from "../components/videoChatPage/sessionnote";
import { Dialog, DialogContent } from "@/Components/ui/dialog";
import EndIcon from "../../assets/Assets/Svgs/end.svg";
import GreenTickIcon from "../../assets/Assets/Svgs/greentick.svg";
import { Storage } from "../api/storage";
import ConnectionScreen from "./connectionscreen";
import { useMediaQuery } from "../components/hooks/useMediaQuery";
import { toast } from "sonner";
import { saveAs } from "file-saver";
import uploadFileToBlob from "../utils/uploadonazure";

const VideoScreen = ({ iceCandidates, room, caller, client }) => {
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const [showModal, setShowModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [localUserMedia, setLocalUserMedia] = useState(null);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [micEnabled, setMicEnabled] = useState(true);
  const [showSessionNote, setShowsessionNote] = useState(false);
  const [isLocalSpeaking, setILocalSpeaking] = useState(false);
  const [isRemoteSpeaking, setIsRemoteSpeaking] = useState(false);
  const [remoteVideoState, setRemoteVideoState] = useState(true);
  const [remoteAudioState, setRemoteAudioState] = useState(true);
  const [callerObject, setCallerObject] = useState(null);
  const [showReconnectionScreen, setShowReconnectionScreen] = useState(false);
  const [time, setTime] = useState(moment().format("LT"));
  const [userEndedcall, setUserEndedcall] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const my_id = currentUser?.id;
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const dailerAudioRef = useRef(null);
  const { id: therapy_session_id } = useParams();
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  let clientEndsCall = false;
  let mediaLocalRecorder;
  let mediaRemoteRecorder;
  const connectToPusher = () => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
      cluster: "mt1",
      encrypted: true,
      authEndpoint: "pusher/auth",
    });
    const channel = pusher.subscribe("user_" + my_id);
    channel.unbind();

    channel.bind("newCall", function (msg) {
      var sessionDesc = new RTCSessionDescription(
        JSON.parse(atob(msg.sdpOffer))
      );
      // Set the remote description first
      caller
        .setRemoteDescription(sessionDesc)
        .then(function () {
          // Once remote description is set, create an answer
          caller
            .createAnswer()
            .then(function (sdp) {
              caller.setLocalDescription(new RTCSessionDescription(sdp));
              controlSound(dailerAudioRef, false);
              pushEvent("answer-call", {
                therapy_session_id: therapy_session_id,
                calleeId: msg.calleeId,
                callerId: msg.callerId,
                sdpAnswer: btoa(JSON.stringify(sdp)),
              }).then(() => {
                iceCandidates.forEach((candidate) => {
                  pushEvent("ice-candidate", {
                    iceCandidate: btoa(JSON.stringify(candidate)),
                    calleeId: msg.calleeId,
                    callerId: msg.callerId,
                  });
                });
                triggerCallAction(
                  "videoStateChanged",
                  cameraEnabled ? "enabled" : "disabled"
                );
                triggerCallAction(
                  "audioStateChanged",
                  micEnabled ? "enabled" : "disabled"
                );
              });
            })
            .catch(function (error) {
              console.log("Error creating answer:", error);
            });
        })
        .catch(function (error) {
          console.log("Error setting remote description:", error);
        });
    });

    //Listening for answer to offer sent to remote peer
    channel.bind("callAnswered", function (answer) {
      caller.setRemoteDescription(
        new RTCSessionDescription(JSON.parse(atob(answer.sdpAnswer)))
      );
    });

    channel.bind("IceCandidate", function (msg) {
      caller.addIceCandidate(
        new RTCIceCandidate(JSON.parse(atob(msg.iceCandidate)))
      );
    });

    // channel.bind("onIceConnectionState", function (msg) {
    //     // caller.addIceCandidate(new RTCIceCandidate(JSON.parse(atob(msg.iceCandidate))));
    //     console.log(msg.iceCandidate);
    //     console.log(msg);
    //     if(msg.iceCandidate?.iceConnectionState === "disconnected")
    //         setShowReconnectionScreen(() => true);
    //     if(msg.iceCandidate?.iceConnectionState === "new" || msg.iceCandidate?.iceConnectionState === "connected" )
    //         setShowReconnectionScreen(() => false);
    // });

    channel.bind("callRejected", function (msg) {
      toast.info("call to " + answer?.rejected + "was politely declined");
      endCall();
    });
    channel.bind("callEnded", function (msg) {
      // setUserEndedcall(() => true)
      // setShowReconnectionScreen(false);
      // setOpenEndModal(true);
      // endCall();
      console.log(msg);
      clientEndsCall = true;
      setUserEndedcall(() => {
        setShowReconnectionScreen(false);
        setOpenEndModal(true);
        endCall();
        return true; // Ensure userEndedCall is updated before the reconnection logic runs
      });
    });
    channel.bind("callAction", function (msg) {
      console.log("Incoming call action", "msg:", msg, "room:", room);
      if (msg.action === "videoStateChanged") {
        setRemoteVideoState(msg.value == "enabled");
      }
      if (msg.action === "audioStateChanged") {
        setRemoteAudioState(msg.value == "enabled");
      }
    });
  };

  useEffect(() => {
    connectToPusher();
    GetRTCPeerConnection();
    GetRTCSessionDescription();
    GetRTCIceCandidate();
    prepareCaller();
    setupMedia().then(() => {
      callUser();
    });
    return () => {
      //
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // This is necessary for some browsers to trigger the confirmation dialog.
      event.returnValue =
        "Do you really want to reload the page? Any unsaved changes will be lost.";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const filehandle = async () => await combineAudioFiles();
    filehandle();
  }, [file1, file2]);

  // useEffect(() => {

  //     console.log(caller, callerObject)
  //     callerObject?.iceConnectionState === 'disconnected' ?
  //     setShowReconnectionScreen(true)
  //     :
  //     setShowReconnectionScreen(false)

  // },[callerObject])

  async function setupMedia() {
    const supportedConstraints =
      navigator.mediaDevices.getSupportedConstraints();

    const videoConfig = {
      frameRate: { ideal: 30 },
      facingMode: "user",
      aspectRatio: 16 / 9,
    };

    const audioConfig = {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
      sampleRate: 48000,
      channelCount: 2,
    };

    if (supportedConstraints.suppressLocalAudioPlayback) {
      audioConfig.suppressLocalAudioPlayback = true;
    }

    const mediaOptions = {
      video: videoConfig,
      audio: audioConfig,
    };

    let stream = await navigator.mediaDevices.getUserMedia(mediaOptions);

    let mediaStream = new MediaStream(stream.getAudioTracks());
    mediaLocalRecorder = new MediaRecorder(mediaStream);

    let recordedChunks = [];

    mediaLocalRecorder.ondataavailable = function (event) {
      if (event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };

    mediaLocalRecorder.onstop = function () {
      const blob = new Blob(recordedChunks, { type: "audio/ogg; codecs=opus" });
      recordedChunks = [];
      setFile1(blob);
    };

    if (!cameraEnabled) {
      stream?.getVideoTracks()?.forEach((track) => {
        track.enabled = false;
      });
    }
    if (!micEnabled) {
      stream?.getAudioTracks()?.forEach((track) => {
        track.enabled = false;
      });
    }

    setLocalUserMedia(stream);
    localVideoRef.current.srcObject = stream;

    triggerCallAction(
      "videoStateChanged",
      cameraEnabled ? "enabled" : "disabled"
    );
    triggerCallAction("audioStateChanged", micEnabled ? "enabled" : "disabled");

    // Add tracks to the peer connection
    stream.getTracks().forEach((track) => {
      caller.addTrack(track, stream);
    });

    checkIfSpeaking(stream, (isSpeaking) => {
      setILocalSpeaking(isSpeaking);
    });
  }

  async function prepareCaller() {
    const configuration = {
      iceServers: [
        {
          urls: import.meta.env.VITE_ICE_SERVER_1_URLS.split(","),
        },
        {
          urls: import.meta.env.VITE_ICE_SERVER_2_URLS.split(","),
          username: import.meta.env.VITE_ICE_SERVER_2_USERNAME,
          credential: import.meta.env.VITE_ICE_SERVER_2_CREDENTIAL,
        },
      ],
    };

    //Initializing a peer connection
    caller = new window.RTCPeerConnection(configuration);

    //Listen for ICE Candidates and send them to remote peers
    caller.onicecandidate = function (evt) {
      if (!evt.candidate) return;
      iceCandidates.push(evt.candidate);
    };

    // Listen for remote tracks and add them to the remote video element
    caller.ontrack = function (evt) {
      let stream = evt.streams[0];
      remoteVideoRef.current.srcObject = stream;
      try {
        checkIfSpeaking(stream, (isSpeaking) => {
          setIsRemoteSpeaking(isSpeaking);
        });
      } catch (error) {
        console.log(
          "Failed to listen to remote stream is speaking",
          error.message
        );
      }
      let mediaStream = new MediaStream(stream.getAudioTracks());
      mediaRemoteRecorder = new MediaRecorder(mediaStream);

      let recordedChunks = [];

      mediaRemoteRecorder.ondataavailable = function (event) {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      mediaRemoteRecorder.onstop = function () {
        const blob = new Blob(recordedChunks, {
          type: "audio/ogg; codecs=opus",
        });
        recordedChunks = [];
        setFile2(blob);
      };
      mediaLocalRecorder.start();
      mediaRemoteRecorder.start();
    };

    let reconnectionTimeout;

    caller.oniceconnectionstatechange = function () {
      // console.log(caller.iceConnectionState);
      // console.log("user ended call:", clientEndsCall)
      // console.log("Don't show screen if true", caller.iceConnectionState === "disconnected" && !clientEndsCall )

      if (
        caller.iceConnectionState === "disconnected" &&
        clientEndsCall === false
      ) {
        console.log(clientEndsCall);
        setShowReconnectionScreen(true);
        // Set timeout to end the call after a minute if not reconnected
        reconnectionTimeout = setTimeout(() => {
          if (
            caller.iceConnectionState === "disconnected" &&
            clientEndsCall === false
          ) {
            setShowReconnectionScreen(false);
            setOpenEndModal(true);
            endCurrentCall();
          }
        }, 60000);
      }

      if (
        caller.iceConnectionState === "connected" ||
        (caller.iceConnectionState === "connected" && clientEndsCall)
      ) {
        // Hide the reconnection screen
        setShowReconnectionScreen(false);

        // Clear the reconnection timeout if the call is reconnected
        clearTimeout(reconnectionTimeout);

        // Trigger call actions
        triggerCallAction(
          "videoStateChanged",
          cameraEnabled ? "enabled" : "disabled"
        );
        triggerCallAction(
          "audioStateChanged",
          micEnabled ? "enabled" : "disabled"
        );
      }
    };

    setCallerObject(() => caller);
  }

  function GetRTCIceCandidate() {
    window.RTCIceCandidate =
      window.RTCIceCandidate ||
      window.webkitRTCIceCandidate ||
      window.mozRTCIceCandidate ||
      window.msRTCIceCandidate;
    return window.RTCIceCandidate;
  }
  function GetRTCPeerConnection() {
    window.RTCPeerConnection =
      window.RTCPeerConnection ||
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.msRTCPeerConnection;
    return window.RTCPeerConnection;
  }
  function GetRTCSessionDescription() {
    window.RTCSessionDescription =
      window.RTCSessionDescription ||
      window.webkitRTCSessionDescription ||
      window.mozRTCSessionDescription ||
      window.msRTCSessionDescription;
    return window.RTCSessionDescription;
  }

  //Create and send offer to remote peer on button click
  function callUser() {
    caller.createOffer({ offerToReceiveVideo: 1 }).then(function (description) {
      caller.setLocalDescription(new RTCSessionDescription(description));
      controlSound(dailerAudioRef, true);
      pushEvent("make-call", {
        therapy_session_id: therapy_session_id,
        callerId: +currentUser?.id,
        calleeId: +client?.userId || +client?.user?.id,
        sdpOffer: btoa(JSON.stringify(description)),
      });
      room = +client?.userId || +client?.user?.id;
    });
  }

  function endCall() {
    room = undefined;
    controlSound(dailerAudioRef, false);
    [localVideoRef, remoteVideoRef].forEach((ref) => {
      let vid = ref.current;
      if (!vid?.srcObject) return;
      let stream = vid.srcObject;
      vid.pause();
      vid.srcObject = null;
      stream.getTracks().forEach((track) => {
        try {
          track?.stop();
        } catch (error) {
          // Failed to stop track
        }
      });
      stream = null;
    });
    try {
      caller?.stop();
    } catch (error) {
      // Failed to stop call
    }
    setLocalUserMedia(null);
  }

  function endCurrentCall() {
    try {
      pushEvent("end-call", {
        therapy_session_id: therapy_session_id,
        callerId: +currentUser?.id,
        calleeId: client?.userId || client?.user?.id,
      });
      endCall();
      // navigate( -1, { replace: true });
    } catch (error) {
      console.log(error);
    }
    setShowReconnectionScreen(() => false);
  }

  function triggerCallAction(action, value) {
    try {
      pushEvent("call-action", {
        therapy_session_id: therapy_session_id,
        callerId: +currentUser?.id,
        calleeId: client?.userId || client?.user?.id,
        action: action,
        value: value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const pushEvent = async (endpoint, data) => {
    return axios
      .post(`${import.meta.env.VITE_BASE_API_URL}/webrtc/` + endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log('Call successful:', response.data);
        return response.data;
      })
      .catch((error) => {
        // console.error('Error making call:', error);
        throw error;
      });
  };

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.2,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.2,
      },
    },
  };

  // Function to toggle video
  const toggleVideo = async () => {
    console.log(cameraEnabled);
    try {
      // Toggle camera state
      setCameraEnabled((prevCameraState) => {
        const newCameraState = !prevCameraState;

        // Toggle the enabled state of the video track
        localUserMedia?.getVideoTracks()?.forEach((track) => {
          track.enabled = newCameraState;
        });

        // Use the new state for triggering the call action
        triggerCallAction(
          "videoStateChanged",
          newCameraState ? "enabled" : "disabled"
        );

        return newCameraState;
      });
    } catch (error) {
      console.error("Error toggling video:", error);
    }
    console.log(cameraEnabled);
  };

  // Function to toggle audio
  const toggleAudio = async () => {
    try {
      // Toggle mic state
      setMicEnabled((prevMicState) => {
        const newMicState = !prevMicState;

        // Toggle the enabled state of the audio track
        localUserMedia?.getAudioTracks()?.forEach((track) => {
          track.enabled = newMicState;
        });
        // Use the new state for triggering the call action
        triggerCallAction(
          "audioStateChanged",
          newMicState ? "enabled" : "disabled"
        );

        return newMicState;
      });
    } catch (error) {
      console.error("Error toggling audio:", error);
    }
  };

  const checkIfSpeaking = async (stream, callback = () => {}) => {
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    let mediaStreamSource = audioContext.createMediaStreamSource(stream);

    // Create a ScriptProcessorNode to process the audio data
    let scriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    // Connect the media stream source to the script processor
    mediaStreamSource.connect(scriptNode);

    // Connect the script processor to the destination (audio context output)
    scriptNode.connect(audioContext.destination);

    // Function to handle audio processing
    scriptNode.onaudioprocess = function (event) {
      let inputData = event.inputBuffer.getChannelData(0);
      let total = 0;
      let rms;

      // Calculate RMS (Root Mean Square) value to estimate audio energy
      for (let i = 0; i < inputData.length; ++i) {
        total += inputData[i] * inputData[i];
      }
      rms = Math.sqrt(total / inputData.length);

      // You can define a threshold value to determine if the user is speaking
      let threshold = 0.01; // Adjust this value according to your needs

      callback(rms > threshold);
    };
  };

  const controlSound = (ref, play) => {
    if (play) {
      ref.current.loop = true;
      ref.current.play();
    } else {
      ref.current.loop = false;
      ref.current.currentTime = 0;
      ref.current.pause();
    }
  };

  // const playButton = document.querySelector('button#play');
  // playButton.addEventListener('click', () => {
  //     const mimeType = "video/webm";
  //     const superBuffer = new Blob(recordedBlobs, { type: mimeType });
  //     recordedVideo.src = null;
  //     recordedVideo.srcObject = null;
  //     recordedVideo.src = window.URL.createObjectURL(superBuffer);
  //     recordedVideo.controls = true;
  //     recordedVideo.play();
  // });

  // const downloadButton = document.querySelector('button#download');
  // downloadButton.addEventListener('click', () => {
  //     const mimeType = codecPreferences.options[codecPreferences.selectedIndex].value.split(';', 1)[0];
  //     const blob = new Blob(recordedBlobs, { type: mimeType });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.style.display = 'none';
  //     a.href = url;
  //     a.download = mimeType === 'video/mp4' ? 'test.mp4' : 'test.webm';
  //     document.body.appendChild(a);
  //     a.click();
  //     setTimeout(() => {
  //         document.body.removeChild(a);
  //         window.URL.revokeObjectURL(url);
  //     }, 100);
  // });

  // function handleDataAvailable(event) {
  //     console.log('handleDataAvailable', event);
  //     if (event.data && event.data.size > 0) {
  //         recordedBlobs.push(event.data);
  //     }
  // }

  // function getSupportedMimeTypes() {
  //     const possibleTypes = [
  //         'video/webm;codecs=vp9,opus',
  //         'video/webm;codecs=vp8,opus',
  //         'video/webm;codecs=h264,opus',
  //         'video/webm;codecs=av01,opus',
  //         'video/mp4;codecs=h264,aac',
  //         'video/mp4;codecs=avc1,mp4a.40.2',
  //         'video/mp4',
  //     ];
  //     return possibleTypes.filter(mimeType => {
  //         return MediaRecorder.isTypeSupported(mimeType);
  //     });
  // }

  // async function startRecording() {
  //     recordedBlobs = [];
  //     const mimeType = "video/mp4";
  //     const options = { mimeType };
  //     if (mimeType.split(';', 1)[0] === 'video/mp4') {
  //         // Adjust sampling rate to 48khz.
  //         const track = window.stream.getAudioTracks()[0];
  //         const { sampleRate } = track.getSettings();
  //         if (sampleRate != 48000) {
  //             track.stop();
  //             window.stream.removeTrack(track);
  //             const newStream = await navigator.mediaDevices.getUserMedia({ audio: { sampleRate: 48000 } });
  //             window.stream.addTrack(newStream.getTracks()[0]);
  //         }
  //     }
  //     try {
  //         mediaRecorder = new MediaRecorder(window.stream, options);
  //     } catch (e) {
  //         console.error('Exception while creating MediaRecorder:', e);
  //         errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
  //         return;
  //     }

  //     console.log('Created MediaRecorder', mediaRecorder, 'with options', options);
  //     recordButton.textContent = 'Stop Recording';
  //     playButton.disabled = true;
  //     downloadButton.disabled = true;
  //     codecPreferences.disabled = true;
  //     mediaRecorder.onstop = (event) => {
  //         console.log('Recorder stopped: ', event);
  //         console.log('Recorded Blobs: ', recordedBlobs);
  //     };
  //     mediaRecorder.ondataavailable = handleDataAvailable;
  //     mediaRecorder.start();
  //     console.log('MediaRecorder started', mediaRecorder);
  // }

  // function stopRecording() {
  //     mediaRecorder.stop();
  // }

  const hasVideoOn = (stream) => {
    return stream?.getVideoTracks()?.some((track) => track.enabled);
  };

  // setInterval(() => {
  //     console.log("Check if video is on", hasVideoOn(remoteUserMedia), remoteUserMedia?.getVideoTracks());
  // }, 2000);

  function updatetime() {
    const newtime = moment().format("LT");
    setTime(newtime);
  }
  setInterval(updatetime, 1000);

  const combineAudioFiles = async () => {
    console.log("file combining");
    if (!file1 || !file2) {
      console.log("UPLOAD FILE");
      return;
    }

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    const buffer1 = await fileToAudioBuffer(file1, audioContext);
    const buffer2 = await fileToAudioBuffer(file2, audioContext);
    const combinedBuffer = mixAudioBuffers(buffer1, buffer2, audioContext);

    let combinedBlob = audioBufferToWavBlob(
      combinedBuffer,
      audioContext.sampleRate
    );
    combinedBlob.name = therapy_session_id;
    combinedBlob.therapist_id = currentUser?.id;
    combinedBlob.patient_id = client?.userId || client?.user?.id;
    await uploadFileToBlob(combinedBlob);
    combinedBlob = {};

    // Optionally, save the combined audio as a file
    // saveAs(combinedBlob, "combined.wav");
  };

  const fileToAudioBuffer = async (file, audioContext) => {
    const arrayBuffer = await file.arrayBuffer();
    return await audioContext.decodeAudioData(arrayBuffer);
  };

  const mixAudioBuffers = (buffer1, buffer2, audioContext) => {
    const numberOfChannels = Math.max(
      buffer1.numberOfChannels,
      buffer2.numberOfChannels
    );
    const length = Math.max(buffer1.length, buffer2.length);
    const mixedBuffer = audioContext.createBuffer(
      numberOfChannels,
      length,
      buffer1.sampleRate
    );

    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData1 =
        buffer1.numberOfChannels > channel
          ? buffer1.getChannelData(channel)
          : new Float32Array(length);
      const channelData2 =
        buffer2.numberOfChannels > channel
          ? buffer2.getChannelData(channel)
          : new Float32Array(length);
      const mixedChannelData = mixedBuffer.getChannelData(channel);

      for (let i = 0; i < length; i++) {
        const sample1 = i < channelData1.length ? channelData1[i] : 0;
        const sample2 = i < channelData2.length ? channelData2[i] : 0;
        mixedChannelData[i] = sample1 + sample2;
      }
    }

    return mixedBuffer;
  };
  const audioBufferToWavBlob = (buffer, sampleRate) => {
    const wavArrayBuffer = audioBufferToWav(buffer, { sampleRate });
    return new Blob([new DataView(wavArrayBuffer)], { type: "audio/wav" });
  };

  // Using audio-buffer-to-wav library
  const audioBufferToWav = (buffer, options) => {
    const numOfChan = buffer.numberOfChannels,
      length = buffer.length * numOfChan * 2 + 44,
      bufferArray = new ArrayBuffer(length),
      view = new DataView(bufferArray),
      channels = [],
      sampleRate = options.sampleRate;

    let offset = 0,
      pos = 0;

    // write WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(sampleRate);
    setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    // write interleaved data
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = Math.max(
          -1,
          Math.min(1, buffer.getChannelData(channel)[i])
        ); // clamp
        sample = sample < 0 ? sample * 0x8000 : sample * 0x7fff; // scale to 16-bit signed int
        view.setInt16(pos, sample, true); // write 16-bit sample
        pos += 2;
      }
    }

    return bufferArray;

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }


    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  };

  return (
    <AnimatePresence mode="wait">
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        id="app"
        className=" no-scrollbar absolute top-0 bottom-0 left-0 right-0 bg-black h-dvh w-full flex items-center justify-between flex-col px-4 md:px-12"
      >
        <header className="w-full flex justify-between items-center h-[10%]">
          <p
            onClick={() =>
              navigate("/dashboard/appointments", { replace: true })
            }
            className="cursor-pointer text-xl font-semibold text-white"
          >
            Mentra
          </p>
          <p className="bg-slate-700 text-white rounded-lg p-2">{time}</p>
        </header>

        <section
          className={`grid grid-cols-1 ${
            showSessionNote ? "md:grid-cols-3" : "md:grid-cols-2"
          } w-full gap-5 h-full md:h-[70%] py-3 md:py-0 overflow-hidden`}
        >
          {showSessionNote ? (
            <motion.div
              key="editor"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              className=" md:row-span-2 md:col-span-2 rounded-xl opacity-70 bg-white w-full h-full overflow-auto min-h-[300px]"
            >
              <SessionNote sessionId={therapy_session_id} />
            </motion.div>
          ) : null}
          <div
            style={{
              backgroundColor: "#1C1D22",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              height: "100%",
            }}
            className={`relative flex items-center justify-center m-auto rounded-xl bg-[#272A31] opacity-70  w-full h-full min-h-[300px] overflow-hidden transition-all ease-in-out duration-500 
                        ${
                          isLocalSpeaking && micEnabled
                            ? "video-border-animation"
                            : ""
                        } ${showSessionNote && isMobile ? "hidden" : "flex"} `}
          >
            <video
              muted="muted"
              ref={localVideoRef}
              className={`w-full h-full min-h-[300px] flex justify-center items-center m-auto object-cover ${
                hasVideoOn(localUserMedia) ? "" : "hidden"
              }`}
              autoPlay
            />
            {!hasVideoOn(localUserMedia) && (
              <img
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full overflow-hidden"
                src={currentUser?.avatar}
                alt="No video"
                style={{ width: "150px", height: "150px", objectFit: "cover" }} // Set width and height here
              />
            )}
            <span className="absolute bottom-4 left-4 bg-[#11131A] rounded-lg p-2 px-3 text-white text-sm font-light">
              {currentUser ? currentUser.name : null}
            </span>
          </div>

          <div
            style={{
              backgroundColor: "#1C1D22",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              height: "100%",
            }}
            className={`relative flex items-center justify-center m-auto opacity-70 rounded-xl bg-[#272A31] w-full h-full min-h-[300px] overflow-hidden transition-all ease-in-out duration-500 

                        ${isRemoteSpeaking ? "video-border-animation" : ""}`}
          >
            <video
              className={`w-full h-full min-h-[300px] flex justify-center items-center m-auto object-cover ${
                remoteVideoState ? "" : "hidden"
              }`}
              autoPlay
              ref={remoteVideoRef}
            />
            {!remoteVideoState && (
              <img
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full overflow-hidden"
                src={client?.avatar || +client?.user?.avatar}
                alt=""
                style={{ width: "150px", height: "150px", objectFit: "cover" }} // Set width and height here
              />
            )}
            <span className="absolute bottom-4 left-4 bg-[#11131A] rounded-lg p-2 px-3 text-white text-sm font-light">
              {client?.name || client?.user?.name}
            </span>
          </div>
          <audio ref={dailerAudioRef} src={DialingTone}></audio>
        </section>

        <footer className="w-full text-white flex items-center justify-between h-[10%]">
          <section className="text-white flex items-center gap-6">
            <span
              onClick={toggleAudio}
              className="hover:bg-[#2E3038] rounded-full p-2 cursor-pointer border border-[#272A31]"
            >
              {micEnabled ? <Icon.Mic /> : <Icon.MicOff />}
            </span>
            <span
              onClick={toggleVideo}
              className="hover:bg-[#2E3038] rounded-full p-2 cursor-pointer border border-[#272A31]"
            >
              {cameraEnabled ? <Icon.Video /> : <Icon.VideoOff />}
            </span>
          </section>

          <section className="flex items-center gap-6 px-2">
            <div
              id=""
              onClick={() => setShowsessionNote((prev) => !prev)}
              className="whitespace-nowrap cursor-pointer flex items-center gap-3 border border-[#2E3038] hover:bg-[#2E3038] p-3 rounded-full"
            >
              <Icon.Edit3 className="" />
              <span className="hidden md:block">Session Note</span>
            </div>
          </section>
          <section className="">
            <div
              id=""
              onClick={() => setShowModal((prev) => !prev)}
              className="cursor-pointer flex items-center gap-1 bg-[#C74E5B] p-3 rounded-full whitespace-nowrap flex-nowrap"
            >
              <span className="flex items-center justify-center">
                <Icon.LogOut size={20} className="rotate-180" />
              </span>
              <span className="hidden md:block">End Session</span>
            </div>
          </section>
        </footer>
        <PromptModal
          openModal={showModal}
          setModal={setShowModal}
          setOpenEndModal={setOpenEndModal}
          endCallFunc={endCurrentCall}
          mediaLocalRecorder={mediaLocalRecorder}
          mediaRemoteRecorder={mediaRemoteRecorder}
        />
        <EndCallModal
          openEndModal={openEndModal}
          setOpenEndModal={setOpenEndModal}
        />
      </div>
      {showReconnectionScreen ? <ConnectionScreen /> : null}{" "}
    </AnimatePresence>
  );
};

export default VideoScreen;

const PromptModal = ({
  openModal,
  setModal,
  setOpenEndModal,
  endCallFunc,
  mediaLocalRecorder,
  mediaRemoteRecorder,
}) => {
  const endCallFunction = async () => {
    try {
      await endCallFunc();
      setModal(false);
      setOpenEndModal((prev) => !prev);
      mediaLocalRecorder.stop();
      mediaRemoteRecorder.stop();
      // await combineAudioFiles();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog open={openModal} onOpenChange={setModal}>
      <DialogContent className="w-full overflow-auto border-none rounded-none">
        <div className="flex flex-col justify-center items-center gap-6 w-full">
          <img src={EndIcon} />
          <div className="flex items-center flex-col gap-2">
            <p className="text-base font-medium pt-1 text-[#0A0D14]">
              Are you sure you want to end the session?
            </p>
            <span className="w-3/4 text-center text-sm text-[#525866]">
              If you have any final questions or points to discuss, now is a
              good time.
            </span>
          </div>
          <hr className="w-full border-none h-[1px] bg-[#E2E4E9]" />
          <div className="w-full flex justify-end items-end gap-3">
            <button
              onClick={() => setModal(false)}
              className="rounded-[50px] border-[1px] border-[#E2E4E9] bg-none text-[#525866] text-sm  py-[10px] px-4"
            >
              Go Back
            </button>
            <button
              onClick={endCallFunction}
              className="rounded-[50px] border bg-[#DF1C41] text-[#ffffff] text-sm  py-[10px] font-semibold px-4"
            >
              End Session
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const EndCallModal = ({ openEndModal, setOpenEndModal }) => {
  const noteKeys = [
    "assessment_interventions",
    "assessment_progress",
    "assessment_observations",
    "subjective_history",
    "subjective_psychological",
    "subjective_relevant",
    "objective_appearance",
    "objective_behavior",
    "objective_symptoms",
    "objective_risk",
    "summary",
  ];
  const navigate = useNavigate();
  const handleLeaveSession = () => {
    noteKeys.forEach((key) => Storage.removeItem(key));
    setOpenEndModal(false);
    navigate(-1, { replace: true });
  };

  return (
    <Dialog open={openEndModal} onOpenChange={setOpenEndModal}>
      <DialogContent className="w-full overflow-auto border-none rounded-none">
        <div className="flex flex-col justify-center items-center gap-6 w-full">
          <img src={GreenTickIcon} />
          <div className="flex items-center flex-col gap-2">
            <p className="text-base font-medium pt-1 text-[#0A0D14]">
              The session has ended
            </p>
            <span className="w-3/4 text-center text-sm text-[#525866]">
              Please ensure that you have completed all necessary notes and
              follow-up actions before closing the session.
            </span>
          </div>
          <hr className="w-full border-none h-[1px] bg-[#E2E4E9]" />
          <div className="w-full flex justify-end items-end gap-3">
            <button
              onClick={() => handleLeaveSession()}
              className="rounded-[50px] border bg-[#DF1C41] text-[#ffffff] text-sm  py-[10px] font-semibold px-4"
            >
              Done
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
