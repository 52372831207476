import { AlertDialog, AlertDialogContent } from "@/Components/ui/alert-dialog";
import { useEffect, useRef, useState } from "react";
import chevron from "../../../Images/work-side.png";
import deleteImg from "../../../Images/delete.png";
import plus from "../../../Images/work-plus.png";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/Components/ui/accordion";

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { generateId } from "@/App/utils/generateId";
import Cookies from 'js-cookie'

import { Label } from "@/Components/ui/label";
import { questionSchema } from "@/App/schemas/worksheet.shemas";
import AssignWorksheet from "../Questions/AssignWorksheet";
import Summary from "./Summary";


function TaskWorksheet({ handleCancel }) {
  const worksheetForm = useRef(null)

  const [sessionPage, setSessioPage] = useState(1);
  const [tasks, setTasks] = useState([
    {
      day: "monday",
      tasks: []
    },
    {
      day: "tuesday",
      tasks: []
    },
    {
      day: "wednesday",
      tasks: []
    },
    {
      day: "thursday",
      tasks: []
    },
    {
      day: "friday",
      tasks: []
    },
    {
      day: "saturday",
      tasks: []
    },
    {
      day: "sunday",
      tasks: []
    },
  ]);


  const { 
    register,
    setValue,
    formState: { errors },
    handleSubmit
   } = useForm({
    resolver: yupResolver(questionSchema)
  });


  const onSubmit = async (data) => {
    try {
      console.log(data)
      Cookies.set('worksheet', JSON.stringify(data), { expires: 1 })
      setSessioPage(2);
    } catch (error) {
      console.log(error)
    } 
  }

  useEffect(() => {
    setValue('type', 'weekly-task')
    setValue('week_days', tasks)
  }, [])

  useEffect(() => {
    setValue('week_days', tasks)
  }, [tasks])

  function afterAssignClient(value) {
    setSessioPage(value);
  }

  function handleAddTasks(day, time) {
    setTasks((prevTasks) => {
      return prevTasks.map((task) => {
        if (task.day === day) {
          const newTask = { _id: generateId(), task: '' , time };
          task.tasks.push(newTask)
        }
        return task;
      });
    });
  }

  function handleChange(_id, value, day) {
    const updatedTasks = tasks.map(task => {
      if (task.day === day) {
        return {
          ...task,
          tasks: task.tasks.map(item => {
            if (item._id === _id) {
              return {
                ...item,
                task: value
              }
            }
            return item
          })
        }
      }
      return task
    })
    setTasks(updatedTasks)
  }

  function handleDeleteTask(_id) {
    setTasks((prevTasks) => {
      return prevTasks.map(task => {
        const taskObj = task.tasks
        const newTaskObj = taskObj.filter(item => item._id !== _id);
        return {...task, tasks: newTaskObj };
      });
    });
  }

  function handleNextStep() {
    worksheetForm.current.click()
  }

  return (
    <div>
      <AlertDialog open={true}>
        <AlertDialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="min-w-full bg-[#F6F8FA] p-0 border-none outline-none ring-0 !rounded-none h-screen overflow-y-scroll"
        >
          <div>
            {sessionPage === 1 ? (
              <div className="relative w-full min-h-full">
                <div className="bg-white h-[55px] border-b border-[#ECECED] duo:h-[64px] z-50 flex justify-end tablet:justify-between items-center px-[5vw]  w-full sticky top-0 ">
                  <h3 className="text-[#0A0D14] hidden tablet:inline text-base font-semibold">
                    Create Worksheet
                  </h3>
                  <div className="flex justify-center items-center gap-3">
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="border border-[#E2E4E9] text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-[#525866]"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleNextStep}
                      className="flex bg-[#1A6A73] justify-center items-center gap-3 text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-white"
                    >
                      <p>Assign to client</p>
                      <img src={chevron} alt="chevron" />
                    </button>
                  </div>
                </div>
                <div className="mb-8 mt-11">
                  <div className=" w-[90vw]  max-w-[900px] mx-auto ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <button type="submit" ref={worksheetForm} className="hidden"></button>
                      <div className="w-full space-y-4 border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
                        <div>
                          <Label
                            htmlFor="name"
                            className="text-left text-sm font-semibold text-[#0A0D14]"
                          >
                            Worksheet Title
                          </Label>

                          <input
                            style={{
                              outline: "none",
                              border: "1px solid #E2E4E9",
                              boxShadow: "none",
                            }}
                            id="title"
                            name="title"
                            type="text"
                            {...register("title")}
                            placeholder="Enter the title of the worksheet"
                            className="w-full rounded-[10px]  text-sm pl-[10px] py-[10px]  mt-1 "
                          />
                          {errors.title?.message && <p className="text-[13px] text-red-500 font-medium">{errors.title?.message}</p>}
                        </div>
                        <div>
                          <Label
                            className="text-left font-semibold text-[#0A0D14]"
                            htmlFor="description"
                          >
                            Description
                          </Label>
                          <textarea
                            style={{
                              outline: "none",
                              border: "1px solid #E2E4E9",
                              boxShadow: "none",
                            }}
                            {...register("description")}
                            className="w-full  rounded-[10px] h-[87px]  text-sm pl-[10px] py-[10px]  mt-1  max-h-[400px]"
                            placeholder="Write a short description here"
                            id="description"
                            name="description"
                          />
                          {errors.description?.message && <p className="text-[13px] text-red-500 font-medium">{errors.description?.message}</p>}
                        </div>
                      </div>
                      <div className="mt-6 w-full border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
                        <h2 className="text-sm mb-6 font-medium text-[#525866]">
                          Enter the questions you want to include in the
                          worksheet.
                        </h2>
                        <Accordion type="multiple" collapsible="true" className="w-full">
                          {tasks.map((data, index) => {
                            const day = data.day;
                            return (
                              <AccordionItem
                                className="border-none"
                                value={data.day}
                                key={index}
                              >
                                <AccordionTrigger className="hover:no-underline">
                                  <p className="text-[#0A0D14] capitalize  font-semibold text-sm">
                                    {data.day} &nbsp;
                                    <span className="font-normal text-[#525866] text-sm">
                                      ({data.tasks.length})
                                    </span>
                                  </p>
                                </AccordionTrigger>
                                <AccordionContent>
                                  <div className="flex flex-col gap-3 flex-wrap min-[550px]:gap-[2%] min-[840px]:gap-[1.5%] min-[550px]:flex-row min-[550px]:gap-y-[1.7vw] ">
                                    <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[550px]:w-[49%] min-[840px]:w-[32.33%]">
                                      <p className="text-sm font-medium text-[#0A0D14] mb-[7px]">
                                        Morning
                                      </p>
                                      <div className="pt-1 flex flex-col gap-2">
                                        {tasks[index].tasks.filter(item => item.time === 'morning').map(
                                          (item, taskIndex) => (
                                            <div
                                              key={item?._id}
                                              className="bg-white relative overflow-hidden rounded-[4px] w-full"
                                            >
                                              <textarea
                                                type="text" 
                                                onChange={(e) => 
                                                  handleChange( 
                                                    item._id, 
                                                    e.target.value,
                                                    data.day
                                                  ) 
                                                } 
                                                defaultValue={item.task} 
                                                style={{ 
                                                  outline: "none", 
                                                  border: "none", 
                                                  boxShadow: "none", 
                                                }} 
                                                placeholder="Task" 
                                                className="w-full text-[#0A0D14] h-[28px] font-medium text-[13px] leading-[18px] pl-[10px] pt-[8px] pb-0 pr-10 focus:outline-red-500!" 
                                              /> 
                                              <button 
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteTask(item?._id)
                                                }
                                                className="absolute top-[8px] right-3 transform  "
                                              >
                                                <img
                                                  className=" w-[15px]"
                                                  src={deleteImg}
                                                  alt="show password"
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleAddTasks(day, "morning")
                                        }
                                        className=" flex justify-center mt-[15px]  gap-3 items-center text-[#525866] font-meduim text-xs"
                                      >
                                        <img
                                          src={plus}
                                          alt="add"
                                          className="w-[8.5px]"
                                        />
                                        <p>New</p>
                                      </button>
                                    </div>
                                    <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[550px]:w-[49%] min-[840px]:w-[32.33%]">
                                      <p className="text-sm font-medium text-[#0A0D14] mb-[7px] ">
                                        Afternoon
                                      </p>
                                      <div className="pt-1 flex flex-col gap-2">
                                        {tasks[index].tasks.filter(item => item.time === 'afternoon').map(
                                          (item, taskIndex) => (
                                            <div
                                              key={item?._id}
                                              className="bg-white relative overflow-hidden  rounded-[4px] w-full"
                                            >
                                              <textarea
                                                onChange={(e) =>
                                                  handleChange(
                                                    item._id,
                                                    e.target.value,
                                                    data.day
                                                  )
                                                }
                                                defaultValue={item.task}
                                                style={{
                                                  outline: "none",
                                                  border: "none",
                                                  boxShadow: "none",
                                                }}
                                                placeholder="Task"
                                                className="w-full text-[#0A0D14] h-[28px] font-medium text-[13px] leading-[18px] pl-[10px] pt-[8px] pb-0 pr-10 focus:outline-red-500!  "
                                              />
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteTask(item?._id)
                                                }
                                                className="absolute top-[8px] right-3 transform  "
                                              >
                                                <img
                                                  className=" w-[15px]"
                                                  src={deleteImg}
                                                  alt="show password"
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleAddTasks(day, "afternoon")
                                        }
                                        className=" flex justify-center mt-[15px]  gap-3 items-center text-[#525866] font-meduim text-xs"
                                      >
                                        <img
                                          src={plus}
                                          alt="add"
                                          className="w-[8.5px]"
                                        />
                                        <p>New</p>
                                      </button>
                                    </div>
                                    <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[840px]:w-[32.33%]">
                                      <p className="text-sm font-medium text-[#0A0D14] mb-[7px]">
                                        Evening
                                      </p>
                                      <div className="pt-1 flex flex-col gap-2">
                                        {tasks[index].tasks.filter(item => item.time === 'evening').map(
                                          (item, taskIndex) => (
                                            <div
                                              key={item?._id}
                                              className="bg-white relative overflow-hidden  rounded-[4px] w-full"
                                            >
                                              <textarea
                                                onChange={(e) =>
                                                  handleChange(
                                                    item._id,
                                                    e.target.value,
                                                    data.day
                                                  )
                                                }
                                                defaultValue={item.task}
                                                style={{
                                                  outline: "none",
                                                  border: "none",
                                                  boxShadow: "none",
                                                }}
                                                placeholder="Task"
                                                className="w-full text-[#0A0D14] h-[28px] font-medium text-[13px] leading-[18px] pl-[10px] pt-[8px] pb-0 pr-10 focus:outline-red-500!  "
                                              />
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteTask(item?._id)
                                                }
                                                className="absolute top-[8px] right-3 transform  "
                                              >
                                                <img
                                                  className=" w-[15px]"
                                                  src={deleteImg}
                                                  alt="show password"
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleAddTasks(day, "evening")
                                        }
                                        className=" flex justify-center mt-[15px]  gap-3 items-center text-[#525866] font-meduim text-xs"
                                      >
                                        <img
                                          src={plus}
                                          alt="add"
                                          className="w-[8.5px]"
                                        />
                                        <p>New</p>
                                      </button>
                                    </div>
                                  </div>
                                </AccordionContent>
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : sessionPage === 2 ? (
              <AssignWorksheet
                handleCancel={handleCancel}
                nextStep={afterAssignClient}
              />
            ) : (
              <Summary handleCancel={handleCancel} nextStep={afterAssignClient}/>
            )}
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default TaskWorksheet;
