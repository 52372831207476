import copyimg from "../../Images/copy.png";
import profileImg from "../../Images/profile.png";
import chevron from "../../Images/chevron-right.png";
import toast from "react-hot-toast";
import { useState } from "react";
import { useUploadProfileImgMutation } from "@/App/services/settingsApiSlice";
import { Storage } from "@/App/api/storage";
import { useSettingsDataContext } from "@/App/pages/Settings";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setCredentials } from "@/App/services/authSlice";
import { useNavigate } from "react-router-dom";

function Personal() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser)

  const apexId = "A-12341234";
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apexId);
      toast.success("Copied to clipboard!", {
        id: "clipboard",
        position: "bottom-center",
      });
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy ID", {
        position: "bottom-center",
      });
    }
  };

  const { settingsData: personalData, refetch } = useSettingsDataContext();

  const [image, setImage] = useState(
    personalData.avatar_id ? personalData.avatar_id : profileImg
  );
  const USER = "MENTRA-USER";
  const user = window ? Storage.getItem(USER) : null;
  const userIdString = user.id.toString();

  const [uploadProfileImg] = useUploadProfileImgMutation();

  async function saveProfileImg(file) {
    const toastId = toast.loading("Uploading image...");
    try {
      const formData = new FormData();
      formData.append("user_id", userIdString);
      formData.append("image", file);

      await uploadProfileImg({ formData: formData })
        .unwrap()
        .then((data) => {
          dispatch(setCredentials({
            user: { ...currentUser, avatar: data.data.therapist.avatar_id }
          }));
          navigate( '/dashboard/settings/personal_info', { replace: true })
        })
        .catch((error) => console.log(error));
      toast.dismiss(toastId);
      toast.success("Profile image uploaded successfully");
      refetch();
    } catch (error) {
      toast.dismiss(toastId);
      console.error("Error uploading profile image:", error);
      toast.error("Error uploading profile image", {
        position: "bottom-center",
      });
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result); // Set the image state to the data URL of the selected file
      saveProfileImg(file); // Call saveProfileImg after setting the image state
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <div>
        <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
          Personal Info
        </h2>
        <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
          Personalize your privacy settings and enhance the security of your
          account.
        </p>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Therapist ID
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            {apexId}
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div
            onClick={handleCopy}
            className="p-[6px] border cursor-pointer w-fit border-[#E2E4E9] gap-[2px] rounded-[50px] flex justify-center items-center"
          >
            <img src={copyimg} className="w-5" alt="copy id" />
            <p className="text-[14px] font-medium leading-5 text-[#525866]">
              Copy ID
            </p>
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Profile Photo
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Min 400x400px, PNG or JPEG formats.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <label htmlFor="fileInput" className="cursor-pointer">
            <div className="flex justify-center items-center gap-2 tablet:gap-3">
              <img
                className="size-[45px] rounded-[100%] tablet:size-[56px]"
                src={image}
                alt="profileImg"
              />

              <p className="text-[14px] font-medium leading-5 text-[#525866] rounded-[50px] border-[#E2E4E9] border px-3 py-[5px]">
                Upload
              </p>
            </div>
          </label>
          <input
            type="file"
            id="fileInput"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageChange}
            className="file-input-element opacity-0 hidden"
          />
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Full Name
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Your name will be visible to your contacts.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium capitalize text-[14px] tablet:text-[15px] leading-5">
              {personalData.full_name}
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Email Address
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Business email address recommended.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium  text-[14px] tablet:text-[15px] leading-5">
              {personalData.email_address}
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Phone Number
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Business phone number recommended.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium capitalize text-[14px] tablet:text-[15px] leading-5">
              {personalData.phone_number}
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;

{
  /* The tremor ui libary is forcing a border on every input thats ehy i have to set the borders using inline styles and add !important to the tailwind styles*/
}
