import { useState, useEffect } from "react";
import workSide4 from "../../assets/Assets/Images/workSide.png";
import workSide from "../../assets/Assets/Images/workSide2.png";
import workSide3 from "../../assets/Assets/Images/workSide3.png";
import workSide2 from "../../assets/Assets/Images/workSide4.png";
import { AnimatePresence, motion } from "framer-motion";

function HowItWorks() {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((step) => (step < 4 ? step + 1 : 1));
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const ImgVars = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  return (
    <div className="px-[5%] pt-[3rem] tablet:pt-[4.5rem] tablet:pb-[4rem] bg-[#FFFDF2] max-[767px]:px-[5%]">
      <h1 className="philosopher laptop:w-[90%] min-[1400px]:w-[80%] mb-3 tablet:mb-6 laptop:mb-0 mx-auto text-[48px] text-[#002C3C] font-bold  max-[767px]:text-[34px] max-[767px]:py-[5%]">
        How it Works
      </h1>
      <div className="flex items-center  justify-center min-[900px]:h-[70vw] min-[1000px]:h-[70vw] laptop:h-[50vw] min-[1400px]:h-[45vw] min-[1500px]:h-[42vw] max-[899px]:flex-col overflow-hidden">
        <div className="flex items-center  h-[730px] min-[900px]:h-full gap-5 min-[900px]:gap-10 max-[899px]:w-[100%]  w-[47%]  laptop:w-[40%] min-[1400px]:w-[38%] min-[900px]:mr-[4%]">
          <div className="w-[4px] relative h-full min-[1050px]:h-[95%]  min-[1750px]:h-[99%] min-[1900px]:h-[95%] bg-[#F9F7F3]">
            <div
              className={`w-full h-[25%] ${
                activeStep === 1
                  ? "top-0"
                  : activeStep === 2
                  ? "top-1/4"
                  : activeStep === 3
                  ? "top-[50%]"
                  : "top-[75%]"
              } absolute transition-all duration-500 ease-in-out  left-0 bg-[#FAE19D]`}
            ></div>
          </div>
          <div className=" h-[90%] min-[1050px]:h-[90%] min-[1750px]:h-[90%] min-[1900px]:h-[85%] flex flex-col justify-between items-start">
            <div className="  min-[900px]:pr-[3%] min-[900px]:py-3  flex justify-center flex-col items-start">
              <div
                className={`flex items-center transition-all duration-1000 ease-in-out py-[2px] border ${
                  activeStep === 1
                    ? "border-[#FAE19D] bg-gradient-to-b from-white to-yellow-200"
                    : "border-[#EAECF0] bg-[#F9FAFB]"
                }  pr-[8px] pl-[8px]  rounded-[16px] gap-1 w-[78px] tablet:w-[70px] h-[20px] justify-center`}
              >
                <span
                  className={`size-[8px] transition-all duration-500 ease-in-out rounded-[50%]   ${
                    activeStep === 1 ? "bg-[#E15B2D]" : "bg-[#070707]"
                  }`}
                ></span>
                <p
                  className={`text-[12px] transition-all text-nowrap duration-500 ease-in-out  ${
                    activeStep === 1 ? "text-[#E15B2D]" : "text-[#333333]"
                  } font-semibold leading-[16px]`}
                >
                  Step 1
                </p>
              </div>
              <div className="mt-[3%]">
                <h1
                  className={`text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] leading-[24px] font-semibold transition-all duration-500 ease-in-out ${ activeStep === 1 ? "text-[#1A6A73]" : "text-[#002C3C]"}`}
                >
                  Download, Sign Up, Subscribe and Personalize
                </h1>
                <p className={`transition-all duration-500 ease-in-out ${ activeStep === 1 ? "text-[#1A6A73]" : "text-[#002C3C]"} text-[14px] tablet:text-[16px] m-0 nine:text-[13px] leading-[24px] laptop:text-[14px] mt-[2%]`}>
                  Get started in moments. Tell us a little about yourself to
                  personalize your experience.
                </p>
              </div>
            </div>

            <div className=" pr-[3%] min-[900px]:py-3  flex justify-center flex-col items-start ">
              <div
                className={`flex items-center transition-all duration-1000 ease-in-out py-[2px] border ${
                  activeStep === 2
                    ? "border-[#FAE19D] bg-gradient-to-b from-white to-yellow-200"
                    : "border-[#EAECF0] bg-[#F9FAFB]"
                }  pr-[8px] pl-[8px]  rounded-[16px] gap-1 w-[78px] tablet:w-[70px] h-[20px] justify-center`}
              >
                <span
                  className={`size-[8px] transition-all duration-500 ease-in-out rounded-[50%]   ${
                    activeStep === 2 ? "bg-[#E15B2D]" : "bg-[#070707]"
                  }`}
                ></span>
                <p
                  className={`text-[12px] transition-all text-nowrap duration-500 ease-in-out  ${
                    activeStep === 2 ? "text-[#E15B2D]" : "text-[#333333]"
                  } font-semibold leading-[16px]`}
                >
                  Step 2
                </p>
              </div>
              <div className="mt-[3%]">
                <h1
                  className={`text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold transition-all duration-500 ease-in-out ${ activeStep === 2 ? "text-[#1A6A73]" : "text-[#002C3C]"} leading-[24px]`}
                >
                  Meet Your AI Companion
                </h1>
                <p className={`transition-all duration-500 ease-in-out ${ activeStep === 2 ? "text-[#1A6A73]" : "text-[#002C3C]"} leading-[24px] text-[14px] tablet:text-[16px] m-0 nine:text-[13px] laptop:text-[14px] mt-[2%]`}>
                  Chat with your empathetic AI buddy, designed to understand and
                  respond to your emotions.
                </p>
              </div>
            </div>

            <div className=" pr-[3%] min-[900px]:py-3  flex justify-center flex-col items-start">
              <div
                className={`flex items-center transition-all duration-1000 ease-in-out py-[2px] border ${
                  activeStep === 3
                    ? "border-[#FAE19D] bg-gradient-to-b from-white to-yellow-200"
                    : "border-[#EAECF0] bg-[#F9FAFB]"
                }  pr-[8px] pl-[8px]  rounded-[16px] gap-1 w-[78px] tablet:w-[70px] h-[20px] justify-center`}
              >
                <span
                  className={`size-[8px] transition-all duration-500 ease-in-out rounded-[50%]   ${
                    activeStep === 3 ? "bg-[#E15B2D]" : "bg-[#070707]"
                  }`}
                ></span>
                <p
                  className={`text-[12px] transition-all text-nowrap duration-500 ease-in-out  ${
                    activeStep === 3 ? "text-[#E15B2D]" : "text-[#333333]"
                  } font-semibold leading-[16px]`}
                >
                  Step 3
                </p>
              </div>
              <div className="mt-[3%]">
                <h1
                  className={`text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold transition-all duration-500 ease-in-out ${ activeStep === 3 ? "text-[#1A6A73]" : "text-[#002C3C]"} leading-[24px]`}
                >
                  Need Professional Support? Get Matched with a Coach
                </h1>
                <p className={`transition-all duration-500 ease-in-out ${ activeStep === 3 ? "text-[#1A6A73]" : "text-[#002C3C]"}leading-[24px] text-[14px] tablet:text-[16px] m-0 nine:text-[13px] laptop:text-[14px] mt-[2%]`}>
                  Our smart match gets you the right Coach. Book sessions
                  that fit your schedule.
                </p>
              </div>
            </div>

            <div className=" pr-[3%] min-[900px]:py-3  flex justify-center flex-col items-start">
              <div
                className={`flex items-center transition-all duration-1000 ease-in-out py-[2px] border ${
                  activeStep === 4
                    ? "border-[#FAE19D] bg-gradient-to-b from-white to-yellow-200"
                    : "border-[#EAECF0] bg-[#F9FAFB]"
                }  pr-[8px] pl-[8px]  rounded-[16px] gap-1 w-[78px] tablet:w-[70px] h-[20px] justify-center`}
              >
                <span
                  className={`size-[8px] transition-all duration-500 ease-in-out rounded-[50%]   ${
                    activeStep === 4 ? "bg-[#E15B2D]" : "bg-[#070707]"
                  }`}
                ></span>
                <p
                  className={`text-[12px] transition-all text-nowrap duration-500 ease-in-out  ${
                    activeStep === 4 ? "text-[#E15B2D]" : "text-[#333333]"
                  } font-semibold leading-[16px]`}
                >
                  Step 4
                </p>
              </div>
              <div className="mt-[3%]">
                <h1
                  className={`text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold transition-all duration-500 ease-in-out ${ activeStep === 4 ? "text-[#1A6A73]" : "text-[#002C3C]"} leading-[24px]`}
                >
                  Evolving with You
                </h1>
                <p className={`transition-all duration-500 ease-in-out ${ activeStep === 4 ? "text-[#1A6A73]" : "text-[#002C3C]"} leading-[24px] text-[14px] tablet:text-[16px] m-0 nine:text-[13px] laptop:text-[14px] mt-[2%]`}>
                  Mentra learns and adapts with you. Get tips and activities
                  made just for you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-[899px]:mt-8 max-[899px]:mx-auto relative h-[500px] min-[900px]:h-auto max-[899px]:w-full max-[899px]:max-w-[400px] min-[900px]:w-[46%] min-[1400px]:w-[38%]">
          <AnimatePresence>
            {activeStep === 1 ? (
              <motion.div
                key="work1"
                variants={ImgVars}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <img alt="" width={600} src={workSide} />
              </motion.div>
            ) : activeStep === 2 ? (
              <motion.div
                key="work2"
                variants={ImgVars}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <img alt="" width={600} src={workSide2} />
              </motion.div>
            ) : activeStep === 3 ? (
              <motion.div
                key="work3"
                variants={ImgVars}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <img alt="" width={600} src={workSide3} />
              </motion.div>
            ) : (
              <motion.div
                key="work4"
                variants={ImgVars}
                initial="initial"
                animate="animate"
                exit="exit"
                className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <img alt="" width={600} src={workSide4} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
