import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import cloud from "../../assets/Assets/Images/cloud.png";
import png from "../../assets/Assets/Images/png-icon.png";
import pdf from "../../assets/Assets/Images/pdf-icon.png";
import jpg from "../../assets/Assets/Images/jpg-icon.png";
import doc from "../../assets/Assets/Images/doc-icon.png";
import docx from "../../assets/Assets/Images/docx-icon.png";
import deleteIcon from "../../assets/Assets/Images/delete-icon.png";

import { useSignUpContext } from "./SignUpContext";
// import SignUpCredentials from "./SignUpCredentials";

const SignupFormNine = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(10);
  }, [setProgress]);
  const { setSignupData, signupData } = useSignUpContext();

  const [filled, setFilled] = useState(true);
  const [files, setFiles] = useState([...signupData.licence]);


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && !files.some((f) => f.name === selectedFile.name)) {
      setFiles((prevFiles) => [...prevFiles, selectedFile]);
    }
  };

  const handleDeleteFile = (name) => {
    const newFiles = files.filter((f) => f.name !== name);
    setFiles(newFiles);
  };

  const handleClick = () => {
    if (files.length > 0) {
      setSignupData((prevData) => ({
        ...prevData,
        licence: files,
      }));
      setStep(11);

    } else {
      setFilled(false);
    }
  };

  return (
    <div>
   
        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="flex flex-col pb-8 shadow-lg items-center h-[100%] bg-white  rounded-[20px] mt-3 z-1 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
        >
          <div className="pt-[9%] lg:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] ">
            <img alt="" src={profileIcon} />
            <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
              Upload License
            </h1>
            <p>Upload a valid practice licence.</p>
          </div>
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please upload a license!
          </p>
          <div className=" border-dashed border-2 border-[#E7E7E0] w-[90%] px-[2%] pt-8 pb-4 rounded-[20px] text-center">
            <div className="text-center">
              <img src={cloud} alt="cloud" className="mx-auto w-[28px]" />
              <h1 className="text-[18px] font-semibold">Choose a file</h1>
              <p className="mt-[2%]">JPEG, PNG, PDF formats, up to 50 MB.</p>
            </div>
            <div className="mt-10">
              <label
                htmlFor="fileInput"
                className="bg-[white] cursor-pointer font-semibold shadow-md text-center px-5 mx-auto py-[3%]  text-[#000000] border-[1px] rounded-[30px] max-[767px]:w-[70%]"
              >
                Browse Files
              </label>
              <input
                type="file"
                id="fileInput"
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                onChange={handleFileChange}
                className="file-input-element opacity-0"
              />
            </div>
          </div>
          {files &&
            files.map((file, index) => (
              <div
                key={index}
                className="w-[90%] py-4 px-5 mt-5 rounded-[20px] border-2 border-[#E7E7E0] flex justify-between items-center"
              >
                <div className="flex justify-start items-center max-w-[90%] gap-3">
                  <img
                    src={
                      file.name.includes(".png")
                        ? png
                        : file.name.includes(".pdf")
                        ? pdf
                        : file.name.includes(".doc")
                        ? doc
                        : file.name.includes(".docx")
                        ? docx
                        : jpg
                    }
                    alt=""
                    className="w-[45px]"
                  />
                  <div className="space-y-[2px] overflow-hidden">
                    <p className="font-medium  text-[14px]">{file.name}</p>
                    <p className="font-normal text-[12px] text-[#333333]">
                      {file.size / 1024 > 1024
                        ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                        : `${Math.ceil(file.size / 1024)} KB`}
                    </p>
                  </div>
                </div>
                <button onClick={() => handleDeleteFile(file.name)}>
                  <img src={deleteIcon} className="w-[24px]" alt="delete" />
                </button>
              </div>
            ))}

          <div className="w-full">
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-5 text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
     
    </div>
  );
};

export default SignupFormNine;
