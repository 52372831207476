import { AlertDialog, AlertDialogContent } from "@/Components/ui/alert-dialog";
import { useEffect, useRef, useState } from "react";
import chevron from "../../../Images/work-side.png";
import deleteImg from "../../../Images/delete.png";
import plus from "../../../Images/work-plus.png";

import { Label } from "@/Components/ui/label";
import AssignWorksheet from "./AssignWorksheet";
import Summary from "./Summary";

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { questionSchema } from "@/App/schemas/worksheet.shemas";
import { generateId } from "@/App/utils/generateId";

import Cookies from 'js-cookie'

function Question({handleCancel}) {
  const questionnaireForm = useRef(null)
  const { 
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit
   } = useForm({
    resolver: yupResolver(questionSchema)
  });


  const onSubmit = async (data) => {
    try {
      Cookies.set('worksheet', JSON.stringify(data), { expires: 1 })
      setSessionPage(2);
    } catch (error) {
      console.log(error)
    } 
  }

  useEffect(() => {
    setValue('type', 'registration-form')
    setValue('questions', [""])
  }, [])

  const [sessionPage, setSessionPage] = useState(1);
  const [questions, setQuestions] = useState(['']);

  useEffect(() => {
    setValue('questions', questions)
  }, [questions])
  
  function handleAddQuestion() {
    const oldQuestions = getValues('questions')
    setQuestions([...oldQuestions, ""]);
  }


  function handleRemoveQuestion(indexToRemove) {
    setQuestions(() => {
      const oldQuestions = getValues('questions')
      const newQuestions = oldQuestions.filter((_, index) => index !== indexToRemove) 
      return newQuestions
    });
  }

  function handleNextStep(){
    questionnaireForm.current.click()
  }

  function afterAssignClient(value){
    //change made to make assign component reusable
    setSessionPage(value);
  }

  return (
    <div>
      <AlertDialog open={true}>
        <AlertDialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="min-w-full bg-[#F6F8FA] p-0 !rounded-none h-screen overflow-y-scroll"
        >
          <div>
            {sessionPage === 1 ? (
              <div className="relative w-full min-h-full">
                <div className="bg-white h-[55px] border-b border-[#ECECED] duo:h-[64px] z-10 flex justify-end tablet:justify-between items-center px-[5vw]  w-full sticky top-0">
                  <h3 className="text-[#0A0D14] hidden tablet:inline text-base font-semibold">
                    Create Worksheet
                  </h3>
                  <div  className="flex justify-center items-center gap-3">
                    <button onClick={handleCancel} className="border border-[#E2E4E9] text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-[#525866]">
                      Cancel
                    </button>
                    <button onClick={handleNextStep} className="flex bg-[#1A6A73] justify-center items-center gap-3 text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-white">
                      <p>Assign to client</p>
                      <img src={chevron} alt="chevron" />
                    </button>
                  </div>
                </div>
                <div className="mb-8 mt-11">
                  <div className=" w-[90vw]  max-w-[638px] mx-auto ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <button type="submit" ref={questionnaireForm} className="hidden"></button>
                      <div className="w-full space-y-4 border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
                        {errors.type?.message && <p className="text-[13px] text-red-500 font-medium">{errors.type?.message}</p>}
                        <div>
                          <Label
                            htmlFor="name"
                            className="text-left text-sm font-semibold text-[#0A0D14]"
                          >
                            Worksheet Title
                          </Label>

                          <input
                            style={{
                              outline: "none",
                              border: "1px solid #E2E4E9",
                              boxShadow: "none",
                            }}
                            id="title"
                            name="title"
                            type="text"
                            {...register("title")}
                            placeholder="Enter the title of the worksheet"
                            className="w-full rounded-[10px]  text-sm pl-[10px] py-[10px]  mt-1 "
                          />
                          {errors.title?.message && <p className="text-[13px] text-red-500 font-medium">{errors.title?.message}</p>}
                        </div>
                        <div>
                          <Label
                            className="text-left font-semibold text-[#0A0D14]"
                            htmlFor="description"
                          >
                            Description
                          </Label>
                          <textarea
                            style={{
                              outline: "none",
                              border: "1px solid #E2E4E9",
                              boxShadow: "none",
                            }}
                            {...register("description")}
                            className="w-full  rounded-[10px] h-[87px]  text-sm pl-[10px] py-[10px]  mt-1  max-h-[400px]"
                            placeholder="Write a short description here"
                            id="description"
                            name="description"
                          />
                          {errors.description?.message && <p className="text-[13px] text-red-500 font-medium">{errors.description?.message}</p>}
                        </div>
                      </div>
                      <div className="mt-6 w-full border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
                        <h2 className="text-sm mb-6 font-medium text-[#525866]">
                          Enter the questions you want to include in the
                          worksheet.
                        </h2>
                        <div key="questions-collection" className="border border-[#E2E4E9] space-y-5 p-4 rounded-[8px]">
                          {questions?.map((item, index) => (
                            <div key={index+generateId()}>
                              <Label
                                htmlFor="name"
                                className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                              >
                                Question {index + 1}
                              </Label>

                              <div className="border border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full">
                                <input
                                  type="text"
                                  style={{
                                    outline: "none",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                  defaultValue={item}
                                  id={`questions.${index}`}
                                  name={`questions.${index}`}
                                  {...register(`questions.${index}`)}
                                  placeholder="Type your question"
                                  // onChange={e => handleChange(e.target.value, index)}
                                  className="w-full h-[40px] font-medium text-sm pl-[10px] py-[10px] pr-10 focus:outline-red-500!"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleRemoveQuestion(index)}
                                  className="absolute top-[13px] right-3 transform"
                                >
                                  <img
                                    className=" w-[19px]"
                                    src={deleteImg}
                                  />
                                </button>
                              </div>
                              {errors.questions && errors.questions[index]?.message && <p className="text-[13px] text-red-500 font-medium">{errors.questions[index]?.message}</p>}
                            </div>
                          ))}
                        </div>
                        <button
                          type="button"
                          onClick={handleAddQuestion}
                          className="bg-[#F6F8FA]  w-full flex mt-6 justify-center gap-3 items-center rounded-[50px] p-[10px] text-[#525866] font-meduim text-sm"
                        >
                          <img src={plus} alt="add" className="w-[10.5px]" />
                          <p>Add Question</p>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : sessionPage === 2 ? (
              <AssignWorksheet handleCancel={handleCancel} nextStep={setSessionPage}/>
            ) : (
              <Summary handleCancel={handleCancel} nextStep={afterAssignClient}/>
            )}
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default Question;
