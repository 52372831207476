import { apiSlice } from "../api/apiSlice";

export const worksheetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorksheets: builder.query({
      query: () => ({
        url: `/therapist/work-sheet`,
        method: "get",
      }),
      providesTags: ['sheets']
    }),
    getWorksheetDetails: builder.query({
      query: (id) => ({
        url: `/therapist/work-sheet/${id}`,
        method: "get",
      })
    }),
    createWorksheet: builder.mutation({
      query: body => ({
        url: `/therapist/work-sheet`,
        method: "post",
        body
      }),
      invalidatesTags: ['sheets']
    }),
    updateWorksheet: builder.mutation({
      query: ({id, body}) => ({
        url: `/therapist/work-sheet/${id}`,
        method: "post",
        body: { ...body }
      }),
      invalidatesTags: ['sheets']
    }),
    updateWorksheetTask: builder.mutation({
      query: (task_id, body) => ({
        url: `/therapist/work-sheet/update-task/${task_id}`,
        method: "put",
        body
      }),
      invalidatesTags: ['sheets']
    }),
    updateWorksheetQuestion: builder.mutation({
      query: (question_id, body) => ({
        url: `/therapist/work-sheet/update-question/${question_id}`,
        method: "put",
        body
      }),
      invalidatesTags: ['sheets']
    }),
    deleteWorksheet: builder.mutation({
      query: (id) => ({
        url: `/therapist/work-sheet/${id}`,
        method: "delete",
      }),
      invalidatesTags: ['sheets']
    }),
    deleteWorksheetTask: builder.mutation({
      query: (task_id) => ({
        url: `/therapist/work-sheet/delete-task/${task_id}`,
        method: "delete",
      }),
      invalidatesTags: ['sheets']
    }),
    deleteWorksheetQuestion: builder.mutation({
      query: (question_id) => ({
        url: `/therapist/work-sheet/delete-question/${question_id}`,
        method: "delete",
      }),
      invalidatesTags: ['sheets']
    })
  }),
});


export const { 
  useGetWorksheetsQuery, useGetWorksheetDetailsQuery, useCreateWorksheetMutation,
  useUpdateWorksheetMutation, useUpdateWorksheetTaskMutation, useUpdateWorksheetQuestionMutation,
  useDeleteWorksheetMutation, useDeleteWorksheetTaskMutation, useDeleteWorksheetQuestionMutation
} = worksheetApiSlice;