import Footer from "../Components/Footer";

import JoinMentra from "../Components/JoinMentra";
import Title from "../Components/Title";
import checked from "../assets/Assets/Svgs/circleChecked.svg";
import TherapistBg from '../assets/Assets/Images/therapistBg.png'

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

import Waitlist from "../Components/Waitlist";
import { AnimatePresence } from "framer-motion";
import AppStore from "../assets/Assets/Svgs/appstore.svg";
import PlayStore from "../assets/Assets/Svgs/playstore.svg";
const ForTherapist = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const onStaging = window.location.href.includes("staging");
  const onLocal = window.location.href.includes("localhost");

  return (
    <div>
      <Title title={"Therapist"} />
      <div className="bg-[#FEFBF0] ">
        <div
          style={{ backgroundImage: `url(${TherapistBg})` }}
          className="text-center max-[767px]:pt-16 tablet:pt-[100px] h-[100%] bg-cover bg-transparent bg-no-repeat pb-8 laptop:pb-[3%]"
        >
          <h1 className="philosopher text-[54px]  mt-14 tablet:mt-[2%] font-bold max-[767px]:text-[32px] max-[767px]:mt-[15%] leading-[65px] max-[767px]:leading-[40px]">
          Join Mentra as a Mental Health Coach <br/>
          </h1>
          <h1 className="text-[18px] mt-[2%] max-[767px]:text-[14px] max-[767px]:mt-[5%] max-[767px]:px-[3%]">
          As a coach partnering with us, you'll embark on a journey of making a meaningful impact in the lives of individuals seeking support.
          </h1>
          
            <Link to="/signup">
              <button className="bg-[#1A6A73]  text-white w-[210px] h-[45px] text-[16px] mt-6 rounded-[30px] shadow-lg mx-auto tablet:text-[16px] tablet:w-[220px]  font-normal laptop:text-[16px] laptop:h-[45px] tablet:h-[50px]  cursor pointer">
                Sign up as a coach
              </button>
            </Link>
          
        
        </div>
        <div className="text-center bg-[#FFFDF2] pt-[2%] pb-[3%] pl-[6vw] pr-[5vw] laptop:px-[15%] max-[767px]:px-[0%]">
          <h1 className="text-[20px] font-normal px-[15%] max-[767px]:text-[16px] max-[767px]:px-[5%]">
          Discover the benefits of collaborating with Mentra and how our platform empowers coaches to connect, engage, and contribute to the well-being of our diverse community.
          </h1>
          <div className="flex flex-wrap  justify-between items-start gap-x-4 gap-y-[30px] tablet:gap-y-10 min-[903px]:gap-y-[52px] laptop:ml-[3%] mt-[8%] tablet:mt-[5%]  max-[767px]:px-[5%] max-[767px]:ml-[0%]">
            <div className="tablet:w-[45%] min-[920px]:w-[30%] w-[100%]">
              <div className="flex items-center max-[767px]:justify-start">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] font-semibold text-left  text-[#243141] ml-[2%] max-[767px]:text-[16px] ">
                  Empowering Connections
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Forge meaningful connections with clients seeking support on
                their mental health journey.
              </h1>
            </div>

            <div className="tablet:w-[45%] min-[920px]:w-[30%] max-[767px]:mt-[5%] w-[100%]">
              <div className="flex items-center">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] text-left   font-semibold text-[#243141] ml-[2%] max-[767px]:text-[16px] max-[767px]:w-[70%] max-[767px]:text-left">
                  Flexible Engagement
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Enjoy the flexibility to engage with clients through secure and
                innovative online platforms.
              </h1>
            </div>

            <div className="tablet:w-[45%] min-[920px]:w-[30%] max-[767px]:mt-[5%] w-[100%]">
              <div className="flex items-center max-[767px]:justify-start">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] text-left font-semibold text-[#243141] ml-[2%] max-[767px]:text-[16px] max-[767px]:w-[70%] max-[767px]:text-left">
                  Community Impact
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Contribute to the well-being of a diverse community, making a
                positive impact on individual lives.
              </h1>
            </div>

            <div className="tablet:w-[45%] min-[920px]:w-[30%] w-[100%] max-[767px]:mt-[5%]">
              <div className="flex items-center max-[767px]:justify-start">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] text-left font-semibold text-[#243141] ml-[2%] max-[767px]:text-[16px] max-[767px]:w-[70%] max-[767px]:text-left">
                  Innovative Technology
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Leverage cutting-edge technology that enhances the therapeutic
                experience for both coaches and clients.
              </h1>
            </div>

            <div className="tablet:w-[45%] min-[920px]:w-[30%] w-[100%] max-[767px]:mt-[5%]">
              <div className="flex items-center max-[767px]:justify-start">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] text-left font-semibold text-[#243141] ml-[2%] max-[767px]:text-[16px] max-[767px]:w-[70%] max-[767px]:text-left">
                  Secure and Confidential
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Ensure a secure and confidential environment for therapy
                sessions, prioritizing client privacy.
              </h1>
            </div>

            <div className="tablet:w-[45%] min-[920px]:w-[30%] w-[100%] max-[767px]:mt-[5%]">
              <div className="flex items-center max-[767px]:justify-start">
                <img alt="checked" src={checked} />
                <h1 className="text-[18px] text-left font-semibold text-[#243141] ml-[2%] max-[767px]:text-[16px] max-[767px]:w-[70%] max-[767px]:text-left">
                  Comprehensive Support
                </h1>
              </div>
              <h1 className="text-[#8E959D] text-left mt-[2%] max-[767px]:w-[100%] max-[767px]:text-[14px]">
                Benefit from our comprehensive support system, including
                resources, training, and a collaborative network.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
      </AnimatePresence>

      <JoinMentra />
      <Footer />
    </div>
  );
};

export default ForTherapist;
