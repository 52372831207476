import { apiSlice } from "../api/apiSlice"

export const notificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllNotifications: builder.query({
            query: () => ({
                url: `/notifications/list`,
                method: "get",
            }),
            providesTags: ['notifications']
        }),
        clearAllNotifications: builder.mutation({
            query: () => ({
                url: `/notifications/clear-all`,
                method: "post",
            }),
            invalidatesTags: ['notifications']
        }),
        markAllNotificationsAsRead: builder.mutation({
            query: () => ({
                url: `/notifications/mark-all`,
                method: "post",
            }),
            invalidatesTags: ['notifications']
        }),
        readNotification: builder.query({
            query: (id) => ({
                url: `notifications/${id}/show`,
                method: 'get'
            }),
            invalidatesTags: ['notifications']
        })
    })
})

export const {
    useGetAllNotificationsQuery,
    useClearAllNotificationsMutation,
    useMarkAllNotificationsAsReadMutation,
    useReadNotificationQuery,
    useLazyReadNotificationQuery,
} = notificationApiSlice