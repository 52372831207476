import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import sessionBut from "../Images/session-button.png";
import now from "../Images/now-clock.png";
import other from "../Images/other-clock.png";
import ava1 from "../Images/avatar1.png";
import ava2 from "../Images/avatar2.png";
import ava3 from "../Images/avatar3.png";
import { AreaChart } from "@tremor/react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { motion } from "framer-motion";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import {
  useDashOverviewQuery,
  useUnreadMessagesQuery,
  useUpcomingSessionsQuery,
} from "../services/dashboardApiSlice";
import Loader from "@/Components/loader";
import { Storage } from "../api/storage";
import { toast } from "sonner";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useLazyShowConversationDetailsQuery } from "../services/messagesSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "../api/apiSlice";
// import { useSettingsDataQuery } from "../services/settingsApiSlice";

function Dashboard() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dashData, setDashData] = useState("");
  const [daysAgo, setDaysAgo] = useState("7");
  const [upcomingSessions, setUpcomingSessions] = useState("");
  const [unreadMessages, setUnreadMessages] = useState("");

  function getDateRange() {
    const currentDate = new Date();
    let startDate = new Date();

    if (daysAgo === "7") {
      startDate.setDate(currentDate.getDate() - 7);
    } else if (daysAgo === "14") {
      startDate.setDate(currentDate.getDate() - 14);
    } else if (daysAgo === "month") {
      startDate.setMonth(currentDate.getMonth() - 1);
    } else if (daysAgo === "six months") {
      startDate.setMonth(currentDate.getMonth() - 6);
    } else if (daysAgo === "year") {
      startDate.setFullYear(currentDate.getFullYear() - 1);
    }

    // Formatting the startDate to 'YYYY-MM-DD' format
    const year = startDate.getFullYear();
    const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchOverview,
  } = useDashOverviewQuery(getDateRange());
  const { data: upcomingSessionsData, error: sessionError } = useUpcomingSessionsQuery();

  const { data: unreadMessagesData, error: unreadMessagesError } =
    useUnreadMessagesQuery('_', { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true });

  const [rating, setRating] = useState('');
  const [graphData, setGraphData] = useState("");
  const [rated, setRated] = useState(true);
  const [ trigger ] = useLazyShowConversationDetailsQuery();

  const handleUnreadMessage = (item) => {
    trigger(item?.last_message?.conversation_id);
    dispatch(apiSlice.endpoints.unreadMessages.initiate());
    navigate(`${ `/dashboard/messages` }`, { state: item?.last_message?.conversation_id })
  }

  useEffect(() => {
    try {
      if (error || sessionError || unreadMessagesError) {
        toast.error(
          "An error ocurred please check your internet connection and try again"
        );
      }
      if (!isLoading && !error && !sessionError && !unreadMessagesError) {
        setDashData(data.data);
        if (rating !== data.data.avg_rating) {
          setRating(data.data.avg_rating);
        }

        if (graphData !== data.data.session_overtime) {
          setGraphData(data.data.session_overtime);
        }
        setUpcomingSessions(upcomingSessionsData.data);
        setUnreadMessages(unreadMessagesData.data);
        if (!data.data.avg_rating && data.data.avg_rating !== 0) {
          setRated(false);
        }
      }
    } catch (error) {
      console.error(error?.data?.message);
    }
  }, [
    isLoading,
    error,
    data,
    sessionError,
    unreadMessagesError,
    upcomingSessionsData,
    unreadMessagesData,
    rating,
    graphData
  ]);
  useEffect(() => {
    refetchOverview(); // Trigger API call again whenever daysAgo changes
  }, [refetchOverview, daysAgo]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [ratingColor, setColor] = useState("");
  const [remarks, setRemarks] = useState("");

  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const [tooltip, setTooltip] = useState(false);
  const [tooltipName, setTooltipName] = useState("");

  const USER = "MENTRA-USER";
  const user = window ? Storage.getItem(USER) : null;
  // console.log(user);

  useEffect(() => {
    if (dashData) {
      const handleResize = () => {
        const width = document.getElementById("clientContainer").clientWidth;
        const height = document.getElementById("clientContainer").clientHeight;
        setContainerWidth(width * (90 / 100));
        setContainerHeight(height * (70 / 100));
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [dashData]);

  useEffect(() => {
    if (rating || rating === 0) {
      const inputValue = parseFloat(rating);
      const clampedValue = Math.min(5.0, Math.max(0, inputValue)); // Clamp the value between 0 and 5.0

      const arc = document.getElementById("ratingpath");
      const arc2 = document.getElementById("ratingpath2");
      if (arc) {
        const arcLength = arc.getTotalLength();
        const step = arcLength / 5.0; // Divide the arc length into 5 segments
        const dashLength = clampedValue * step; // Map value to arc length
        const dashLength2 = 5 * step; // Map value to arc length
        arc.style.strokeDasharray = `${dashLength} ${arcLength - dashLength}`;
        arc2.style.strokeDasharray = `${dashLength2} ${arcLength - dashLength}`;
      }

      if (rating < 2.5) {
        setColor("#D76B66");
        setRemarks("Poor");
      } else if (rating < 3.5) {
        setColor("#F6CF7D");
        setRemarks("Average");
      } else if (rating < 4.5) {
        setColor("#EA973D");
        setRemarks("Good");
      } else {
        setColor("#75C57F");
        setRemarks("Excellent");
      }
    }
  }, [rating]);

  function handleMouseEnter(value) {
    setTooltip(true);
    setTooltipName(value);
  }

  function handleMouseLeave() {
    setTooltip(false);
    setTooltipName("");
  }

  function formatNumber(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    } else {
      return number?.toString();
    }
  }

  const transformedClientData = (isSuccess && data) && Object.entries(data?.data?.age_range)?.map(([key, value]) => {
    return {
      name: key,
      value: value
    }
  });

  const COLORS = ["#F2AE50", "#1A6A73", "#12B28C", '#000000', "#DFB300", "#FFBB28", '#FF00D6'];

  const chartdata = [
    {
      date: "Jan ",
      "Active users": graphData?.january,
    },
    {
      date: "Feb ",
      "Active users": graphData?.february,
    },
    {
      date: "Mar ",
      "Active users": graphData?.march,
    },
    {
      date: "Apr ",
      "Active users": graphData?.april,
    },
    {
      date: "May ",
      "Active users": graphData?.may,
    },
    {
      date: "Jun ",
      "Active users": graphData?.june,
    },
    {
      date: "Jul ",
      "Active users": graphData?.july,
    },
    {
      date: "Aug ",
      "Active users": graphData?.august,
    },
    {
      date: "Sep ",
      "Active users": graphData?.september,
    },
    {
      date: "Oct ",
      "Active users": graphData?.october,
    },
    {
      date: "Nov ",
      "Active users": graphData?.november,
    },
    {
      date: "Dec ",
      "Active users": graphData?.december,
    },
  ];

  const variants = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const renderColorfulLegendText = (value, entry, index) => {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
  };

  const getLegendKeyStyles = (color) => {
    return {
        backgroundColor: color,
        width: '6px',
        height: '6px',
        borderRadius: '50px',
    }
  }

  const renderLegend = (props) => {
    const { payload } = props;

    return (
        <div className="w-full flex items-center justify-center gap-4" >
            {
                payload?.map((data) => (
                    <div className="flex items-center justify-between" key={data.value}>
                        <div className="flex items-center gap-1">
                            <span className="" style={getLegendKeyStyles(data.color)} key={data.name} ></span>
                            <h6 className="text-xs text-[#525866]" >{data.value}</h6>
                        </div>
                    </div>
                ))
            }
        </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div className="flex items-start justify-center flex-col rounded-lg bg-white border-2 border-[#D9D9D920] p-3 gap-2 w-[180px]">
            <div className="flex items-center gap-1">
                <span className="" style={getLegendKeyStyles(payload[0]?.payload.fill)}></span>
                <p className="text-[0.7rem] text-[#525866] font-bold">Age: {payload[0]?.name}</p>
            </div>
            <div className="flex items-start flex-col">
                {/* <p className="text-sm font-medium text-tgray-150">Age: {payload[0]?.name}</p> */}
                <p className="text-sm font-bold text-[#525866]">{`value: ${payload[0]?.value}`}</p>
            </div>
        </div>
      );
    }
    return null;
  };

  return dashData ? (
    <motion.div
      key="dash"
      variants={variants}
      initial="initial"
      animate="animate"
      className=" "
    >
      <div>
        <h2 className="text-[#101828] capitalize text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          {user.name}
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          View and manage your sessions, track performance metrics, and access
          resources to support your practice.
        </p>
      </div>
      <hr className="w-full my-7 border-none h-[1px] bg-[#EAECF0]" />
      <div className="dashboard-grid  w-full *:rounded-[16px] *:bg-white *:border *:border-[#E2E4E9] *:px-5 *:py-5">
        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="sessions overflow-y-scroll"
        >
          <div className=" w-full flex justify-between items-center">
            <h3 className="text-[#0A0D14] font-medium text-[1rem] leading-6">
              Upcoming Sessions
            </h3>
            <Link to="/dashboard/appointments">
              <p className="text-[14px] text-[#525866] text-center w-[70px] py-[6px] leading-5 font-medium rounded-[50px] border border-[#E2E4E9]">
                View all
              </p>
            </Link>
          </div>
          {upcomingSessions.length > 0 ? (
            <div className="mt-6 space-y-2">
              {upcomingSessions.map((data, index) => {

                let isTimeNow;
                const NewstartTime = moment(data?.starts_at).subtract(5, 'minutes').format()
                const endTime = moment(NewstartTime).add(data?.duration + 15, 'minutes').format()

                if(moment().isSameOrAfter(NewstartTime) && moment().isSameOrBefore(endTime) ){
                  isTimeNow = true
                };

                console.log(data)

                return <div
                  key={index}
                  className="pt-4 px-2 pb-2 space-y-3 border border-[#E2E4E9] rounded-[12px]"
                >
                  <div className="px-2 flex flex-col justify-start items-start">
                    <div className="flex justify-between w-full items-center">
                      <h4 onClick={() => navigate(`/dashboard/client_list/${data?.user?.id}`) } className="font-medium text-[14px] leading-5 text-[#0A0D14]">
                        {data?.user?.name}
                      </h4>
                      <div className="flex justify-center items-center gap-[6px] ">
                        <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#FBDFB1] rounded-[50px] px-2 py-[2px]">
                          {data?.status}
                        </p>
                        {/* <img className="w-[24px]" src={sessionBut} alt="view" /> */}
                      </div>
                    </div>
                    <p className="text-[#525866] text-[12px] font-normal leading-4 pt-[2px]">
                      {moment(data?.starts_at).format("LT")}
                    </p>
                  </div>
                  <div
                    className={`${
                      (isTimeNow && data.status !== 'Cancelled')? "bg-[#CBF5E5]" : data.status === "Completed" ? "bg-[#F6F8FA] text-[#525866]"
                       : 
                       data.status === "Upcoming" || data.status === "Confirmed" ? "bg-[#F6F8FA] text-[#525866]"
                        : 
                        data.status === "Cancelled" ? "bg-[#ffcac2f6] text-[#6E330C]"
                        :
                        data.status === 'Awaiting_Approval' ? "bg-[#FBDFB1] text-[#6E330C]"
                        : "bg-[#0000002b] text-[#5654539d]"
                    } p-2 rounded-[8px] flex justify-between items-center`}
                  >
                    <div className="flex justify-center items-center gap-1">
                      <img
                        src={data?.when === "Now" ? now : other}
                        className="w-4"
                        alt="clock"
                      />
                      <p className={` ${ data.status === 'Cancelled' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        Cancelled
                      </p>
                      <p className={` ${ data.status === 'Upcoming' || data.status === 'Confirmed' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        {(moment(data?.starts_at).endOf('').fromNow())}
                      </p>
                      <p className={` ${ data.status === 'Completed' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        Completed
                      </p>
                      <p className={` ${ data.status === 'Awaiting_Approval' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        Awaiting_Approval
                      </p>
                      <p className={` ${ data.status === 'Not_Started' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        Not Started
                      </p>
                      <p className={` ${ data.status === 'No_Show' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                        Not Show
                      </p>
                    </div>
                    {isTimeNow && data.status !== 'Cancelled' ? (
                      <p onClick={(event) => { navigate(`/video-chat/${data?.id}`, { state: data })}} className="z-40 underline font-medium text-[12px] leading-4 text-[#176448] cursor-pointer">
                        Start Call
                      </p>
                    ) : (
                      <p className="text-[#525866] text-[12px] leading-4">
                        {moment(data?.starts_at).format("ddd, MMM Do YYYY")}
                      </p>
                    )}
                  </div>
                </div>
                })}
            </div>
          ) : (
            <div className="min-h-[90%] tablet:min-h-fit tablet:mt-10 flex justify-center items-center">
              <p className=" text-center  text-[#525866] text-sm font-bold">
                No Upcoming sessions.
              </p>
            </div>
          )}
        </div>

        <div className="rating ">
          <div className="flex justify-start items-center w-full">
            <p className="text-[#525866] text-[14px] leading-[18px] font-semibold">
              Your Average Rating
            </p>
          </div>
          <div className="mt-6 w-full flex justify-center">
            <div
              id="container"
              className="r-cont w-full max-w-[346px] mx-auto flex items-start justify-center aspect-square relative "
            >
              {/* <hr className="h-[1px] bg-blue-600 w-full absolute border-none top-[52.9%] transform  left-0" /> */}
              <svg
                version="1.1"
                baseProfile="full"
                viewBox="0 0 190 190"
                className="w-[90%] overflow-visible z-20 absolute top-0 left-1/2 transform -translate-x-1/2  r-svg aspect-square"
                xmlns="http://www.w3.org/2000/svg"
              >
                <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
                  <stop offset="0%" stopColor="#b96e85" />
                  <stop offset="100%" stopColor="#ae69bb" />
                </linearGradient>

                <path
                  className="transition-all duration-2000 ease-in-out"
                  id="ratingpath"
                  d="M5 95 A80 80 0 0 1 185 95"
                  stroke={ratingColor}
                  fill="none"
                  // strokeWidth="10"
                  strokeLinecap="round"
                  strokeDasharray="0 282.78"
                />
              </svg>
              <svg
                version="1.1"
                baseProfile="full"
                viewBox="0 0 190 190"
                className="w-[90%] overflow-visible z-10 absolute top-0 left-1/2 transform -translate-x-1/2  r-svg2 aspect-square"
                xmlns="http://www.w3.org/2000/svg"
              >
                <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
                  <stop offset="0%" stopColor="#b96e85" />
                  <stop offset="100%" stopColor="#ae69bb" />
                </linearGradient>

                <path
                  id="ratingpath2"
                  d="M5 95 A80 80 0 0 1 185 95"
                  fill="none"
                  // strokeWidth="10"
                  strokeLinecap="round"
                  strokeDasharray="0 282.78"
                />
              </svg>
              <div className="w-[90%] px-[12px] absolute top-[23%] left-1/2 transform -translate-x-1/2">
                <h1 className="text-center  text-[44px] leading-[54px] tablet:text-[48px] tablet:leading-[58px] text-[#0A0D14] font-semibold">
                  {rating}
                </h1>
                <p className="text-center text-[15px] tablet:text-[16px] leading-5 text-[#0A0D14] pt-[4px] font-semibold">
                  {rated
                    ? `Your rating is ${remarks}`
                    : "You haven't been rated"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="insight ">
          <div className="flex justify-between items-center w-full">
            <p className="text-[#525866] text-[14px] leading-[18px] font-semibold">
              Insights
            </p>
            <Select
              value={daysAgo}
              onValueChange={(value) => setDaysAgo(value)}
            >
              <SelectTrigger className=" w-[140px] px-[10px] nine:px-3 nine:w-[155px] h-[34px] focus:ring-offset-0 focus:ring-0 nine:h-[38px] text-[13px] nine:text-[14px] font-semibold text-[#525866] ">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className=" *:font-medium  ">
                  {/* <SelectLabel>Fruits</SelectLabel> */}
                  <SelectItem value="7">Past 7 Days</SelectItem>
                  <SelectItem value="14">Past 14 Days</SelectItem>
                  <SelectItem value="month">Past Month</SelectItem>
                  <SelectItem value="six months">Past Six Months</SelectItem>
                  <SelectItem value="year">Past Year</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="mt-6 space-y-[2%]">
            <div className="flex justify-center items-center gap-[2%]">
              <div className="border-[#F2F2F2] h-[99px] tablet:h-[93px] w-[49%] flex flex-col justify-center items-start border gap-2 rounded-[8px] pl-3 pr-1 py-1">
                <div className="flex gap-2 justify-center items-center">
                  <span className="size-[6px]  min-w-[6px] rounded-[50%] bg-[#978FED]"></span>
                  <p className="font-semibold  text-[#525866] text-[13px] nine:text-[14px] leading-[18px]">
                    Total Appointments
                  </p>
                </div>
                <h3 className="font-semibold text-[20px] leading-6 tablet:text-[24px] tablet:leading-7 text-[#0A0D14]">
                  {formatNumber(dashData.total_sessions)}
                </h3>
              </div>
              <div className="border-[#F2F2F2] h-[99px] tablet:h-[93px] w-[49%] flex flex-col justify-center items-start border gap-2 rounded-[8px] pl-3 pr-1 py-1">
                <div className="flex gap-2 justify-center items-center">
                  <span className="size-[6px]  min-w-[6px] rounded-[50%] bg-[#A4DDDC]"></span>
                  <p className="font-semibold  text-[#525866] text-[13px] nine:text-[14px] leading-[18px]">
                    Total Clients
                  </p>
                </div>
                <h3 className="font-semibold text-[20px] leading-6 tablet:text-[24px] tablet:leading-7 text-[#0A0D14]">
                  {formatNumber(dashData.no_of_clients)}
                </h3>
              </div>
            </div>
            <div className="flex justify-center items-center gap-[2%]">
              <div className="border-[#F2F2F2] h-[99px] tablet:h-[93px] w-[49%] flex flex-col justify-center items-start border gap-2 rounded-[8px] pl-3 pr-1 py-1">
                <div className="flex gap-2 justify-center  items-center">
                  <span className="size-[6px]  min-w-[6px]  rounded-[50%] bg-[#F6CF7D]"></span>
                  <p className="font-semibold  text-[#525866]  text-[13px] nine:text-[14px] leading-[18px]">
                    Avg Session Duration
                  </p>
                </div>
                <h3 className="font-semibold text-[20px] leading-6 tablet:text-[24px] tablet:leading-7 text-[#0A0D14]">
                  {formatNumber(dashData.avr_session_duration)}
                </h3>
              </div>
              <div className="border-[#F2F2F2] h-[99px] tablet:h-[93px] w-[49%] flex flex-col justify-center items-start border gap-2 rounded-[8px] pl-3 pr-1 py-1">
                <div className="flex gap-2 justify-center items-center">
                  <span className="size-[6px]  min-w-[6px] rounded-[50%] bg-[#697BE9]"></span>
                  <p className="font-semibold  text-[#525866] text-[13px] nine:text-[14px] leading-[18px]">
                    Assigned Worksheets
                  </p>
                </div>
                <h3 className="font-semibold text-[20px] leading-6 tablet:text-[24px] tablet:leading-7 text-[#0A0D14]">
                  {formatNumber(dashData.assigned_worksheet)}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div id="clientContainer" className="client">
          <div className="flex justify-start items-center w-full">
            <p className="text-[#525866] text-[14px] leading-[18px] font-semibold">
              Client Breakdown
            </p>
          </div>
          <div
            className={`flex relative justify-center flex-col h-full ${
              transformedClientData && transformedClientData?.filter((item) => item.value > 0).length > 0
                ? ""
                : "min-h-[100%]"
            } items-center w-full`}
          >
            {transformedClientData && transformedClientData?.filter((item) => item.value > 0).length > 0 ? (
              <ResponsiveContainer width= "100%" height = "100%">
                <PieChart
                  className=""
                  width={containerWidth}
                  height={containerHeight}
                >
                  <Legend
                    align='center' 
                    verticalAlign="bottom" 
                    layout="horizontal"
                    iconSize = {8} 
                    // height = "100%" 
                    // width="100%"
                    iconType = 'circle'
                    // margin={{
                    //     top: 0,
                    //     right: 0,
                    //     left: 0,
                    //     bottom: 0
                    // }} 
                    content = {renderLegend}
                    formatter = {renderColorfulLegendText}
                  />
                  <Pie
                    data={transformedClientData ?? {}}
                    // cx={120}
                    // cy={200}
                    innerRadius={65}
                    outerRadius={130}
                    startAngle={90}
                    endAngle={450}
                    // fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {transformedClientData && transformedClientData?.map((entry, index) => (
                      <Cell
                        onMouseEnter={() => handleMouseEnter(entry.name)}
                        onMouseLeave={handleMouseLeave}
                        className=" cursor-pointer border-none outline-none"
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <p className=" text-center text-[#525866] text-sm font-bold">
                No clients to show
              </p>
            )}
            {/* Legend */}

            {/* <div
              className={`${
                transformedClientData.filter((item) => item.value > 0).length > 0
                  ? "flex"
                  : "hidden"
              } justify-center items-center gap-4 mt-4`}
            >
              <div className="flex gap-2 justify-center items-center">
                <span className="size-[8px] min-w-[8px] rounded-[50%] bg-[#1A6A73]"></span>
                <p className="font-semibold  text-[#525866]  text-[14px] leading-[15px]">
                  Male
                </p>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <span className="size-[8px] min-w-[8px] rounded-[50%] bg-[#F2AE40]"></span>
                <p className="font-semibold text-[#525866] text-[14px] leading-[15px]">
                  Female
                </p>
              </div>
            </div> */}

            {/* Tooltip */}
            {/* <div
              className={`bg-white shadow absolute ${
                tooltip && tooltipName.toLowerCase()
                  ? "flex"
                  : "hidden"
              } top-1/3 right-0 border rounded-[8px] border-[#E2E4E9]  justify-center items-center px-4 py-3 gap-9`}
            >
              <div className="flex gap-2 justify-center items-center">
                <span className="size-[8px] min-w-[8px] rounded-[50%] bg-[#1A6A73]"></span>
                <p className="font-semibold  text-[#525866]  text-[14px] leading-[16px]">
                  Male
                </p>
              </div>
              <h2 className="text-[#101828] font-semibold text-[16px] leading-[15px]">
                20
              </h2>
            </div>

            <div
              className={`bg-white absolute ${
                tooltip && tooltipName.toLowerCase()
                  ? "flex"
                  : "hidden"
              } shadow top-1/2 left-0 border rounded-[8px] border-[#E2E4E9]  justify-center items-center px-4 py-3 gap-9`}
            >
              <div className="flex gap-2 justify-center items-center">
                <span className="size-[8px] min-w-[8px] rounded-[50%] bg-[#F2AE40]"></span>
                <p className="font-semibold  text-[#525866]  text-[14px] leading-[16px]">
                  Female
                </p>
              </div>
              <h2 className="text-[#101828] font-semibold text-[16px] leading-[15px]">
                80
              </h2>
            </div> */}
          </div>
        </div>

        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="messages overflow-auto tablet:overscroll-none relative !p-0"
        >
          <div className="flex justify-start items-center w-full sticky top-0 bg-white p-4">
            <p className="text-[#525866] text-[14px] font-semibold w-full">
              Unread Messages
            </p>
          </div>
          {unreadMessages.length > 0 ? (
            <div className="mt-2 *:p-4 ">
              {unreadMessages?.slice()?.reverse()?.map((item, index) => {
                // console.log(item)
                return (
                  <div
                    onClick={() => handleUnreadMessage(item) }
                    key={index}
                    className="flex items-center justify-between gap-2 cursor-pointer border-b border-[#E2E4E9] hover:bg-[#F6F8FA] mx-4"
                  >
                    <section className="flex items-center justify-between gap-2">
                      <img className="w-[40px]" src={item?.user_id.avatar} alt="avartar" />
                      <div className="flex flex-col justify-start items-start">
                        <p className="text-[#525866] text-[12px] leading-4 ">
                          {item?.user_id?.name}
                        </p>
                        <h3 className="text-[#0A0D14] text-left text-[14px] leading-5">
                          {item?.last_message?.message}
                        </h3>
                      </div>
                    </section>
                    <div>
                      <span className="text-xs">{moment(item?.updated_at).format("ddd, MMMM Do YYYY,  LT")}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="min-h-[90%] flex justify-center items-center">
              <p className=" text-center text-[#525866] text-sm font-bold">
                No messages to show.
              </p>
            </div>
          )}
        </div>

        <div className="graph  relative">
          <div className="flex justify-start items-center w-full">
            <p className="text-[#525866] text-[14px] leading-[18px] font-semibold">
              Session Over Time
            </p>
          </div>
          <AreaChart
            className="mt-6 tablet:pl-10 h-[85%] tablet:h-[80%] text-tremor-label"
            data={chartdata}
            index="date"
            yAxisWidth={35}
            showLegend={false}
            categories={["Active users"]}
            colors={["slate-400"]}
            // curveType="natural"
            // valueFormatter={valueFormatter}
          />
          <p className="text-[#525866] text-center hidden tablet:block text-[12px] font-medium tracking-wider leading-[18px]">
            Month
          </p>
          <p className="text-[#525866] absolute hidden tablet:block left-0 top-1/2 -translate-y-1/2 w-fit transform rotate-[270deg] tracking-wider text-center text-[12px] font-medium m-0 p-0">
            Active users
          </p>
        </div>
      </div>
    </motion.div>
  ) : (
    <div className="mt-[16vh]">
      <Loader />
    </div>
  );
}

export default Dashboard;
