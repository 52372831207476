import { apiSlice } from "../api/apiSlice";

export const messagesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query({
        keepUnusedDataFor: 10,
        query: ({search=null}) => ({
            url: `therapist/messages/get-conversations?search=${search}`,
            method: "get",
        }),
        providesTags: ['convos']
    }),
    getMessages: builder.query({
        keepUnusedDataFor: 10,
        query: id => ({
            url: `/therapist/messages/get-messages/${id}`,
            method: "get",
        }),
        providesTags: ['messages']
    }),
    sendMessage: builder.mutation({
        query: message => ({
            url: `/therapist/messages/send-message`,
            method: "post",
            body: { ...message }
        }),
        // invalidatesTags: ['messages']
    }),
    createNewConversation: builder.mutation({
        query: details => ({
            url: `/therapist/messages/current-conversation`,
            method: "post",
            body: { ...details }
        }),
        invalidatesTags: ['convos']
    }),
    showConversationDetails: builder.query({
        query: id => ({
            url: `/therapist/messages/conversations/${id}/show`,
            method: 'get'
        }),
        invalidatesTags: ['convos']
    }),
  }),
});

export const { 
    useGetConversationsQuery,
    useGetMessagesQuery,
    useSendMessageMutation,
    useCreateNewConversationMutation,
    useShowConversationDetailsQuery,
    useLazyShowConversationDetailsQuery,
 } = messagesSlice;