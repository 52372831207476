// import to from "./../../../public/Assets/Svgs/to.svg";
// import location from "./../../../public/Assets/Svgs/location.svg";
// import clock from "./../../../public/Assets/Svgs/clock-icon.svg";
// import Image from "next/image";
import careerImg from "../../assets/Assets/Images/careerImg.png";
import { motion } from "framer-motion";

const Career = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      key="career"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="flex flex-col  items-center justify-center text-[#333333]">
        <div>
          <h1 className="philosopher text-[48px] text-center font-bold mb-3 max-[767px]:text-[24px]">
            Careers
          </h1>
          <h1 className="text-[16px] mb-5 font-medium tablet:text-[18px] text-center">
            Welcome to Mentra, where we blend AI innovation with human expertise
            to redefine mental and emotional support. Here, every role is
            impactful, and your passion for positive change is our greatest
            strength.
          </h1>
          <h1 className="text-[16px] font-medium tablet:text-[18px] text-center">
            Join our team of innovators and compassionate minds, and be a part
            of a movement that's transforming lives every day. If making a
            meaningful difference excites you, your journey starts here with us
            at Mentra!
          </h1>
        </div>
        <div className="mt-10 w-[80%] tablet:w-[40%]">
          <img alt="missionVideo" src={careerImg} />
        </div>

        <div className="text-center mt-10">
          <h1 className=" text-[16px] tablet:text-[18px]  font-semibold">
            There are no current openings. Please stay in touch for
            future opportunities.
          </h1>
        </div>

        {/* <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[5%] border-[1px] border-[#FEA19D] rounded-[20px] w-[100%] p-[3%] max-[767px]:p-[5%]">
          <div className="flex justify-between items-center">
            <h1 className="max-[767px]:text-[14px]">Design</h1>
            <div className="flex items-center">
              <h1 className="max-[767px]:text-[13px]">View Job</h1>
              <Image src={to} width={20} />
            </div>
          </div>

          <div className="mt-[2%]">
            <h1 className="text-[16px] font-bold max-[767px]:text-[14px]">Product Designer</h1>
            <p className="text-[16px] m-0 max-[767px]:text-[12px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center mt-[2%]">
              <div className="flex items-center mr-[5%]">
                <Image className="max-[767px]:w-[20%]" src={location} />
                <h1 className="ml-[2%] max-[767px]:text-[12px]">Remote</h1>
              </div>

              <div className="flex items-center">
                <Image className="max-[767px]:w-[20%]" src={clock} width={20} />
                <h1 className="ml-[10px] max-[767px]:text-[12px] max-[767px]:ml-[5px]">Full-Time</h1>
              </div>
            </div>
          </div>
        </div>   */}
      </div>
    </motion.div>
  );
};

export default Career;
