import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import searchIcon from "../../App/Images/search.png";
import * as Icon from 'react-feather'
import { messageContainerVariants } from '@/App/pages/Messages';
import { useOnOutsideClick } from '@/App/components/hooks/useOutsideClick';
import { useRef } from 'react';

const MultiSelect = ({ options, selectedItems, setSelectedItems }) => {

    const ref = useRef();
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState(options)
    const [showDropDown, setShowDropDown] = useState(false);

    useOnOutsideClick(ref, () => {
        setShowDropDown(() => false);
    });

    const handleSearchOptions = (event) => {
        setSearch(() => event.target.value)
        const searchWord = event.target.value
        const newFilter = options.filter((value) => {
            return value.toLowerCase().includes(searchWord.toLowerCase());
        });
        if(searchWord === ""){
            setFilteredData((prev) => [...prev, options]);
        }
        setFilteredData(newFilter);
    }

    const handleAddItemArray = (item) => {
        if(selectedItems.includes(item)) return;
        setSelectedItems((prev) => [ ...prev, item ]);
        setFilteredData(() => options)
        setSearch(() => '')
    }

    const removeItemFromArray = (item) => {
        const newItems = selectedItems.filter( option => option !== item );
        setSelectedItems(() => newItems)
    }

    useEffect(() => {
        setFilteredData(() => options)
    }, [options])
    

  return (
    <div ref={ref} className='w-full flex flex-col'>
        <div className='w-full relative '>
            <div className='w-full flex items-center justify-between gap-2 border border-[#E2E4E9] rounded-lg cursor-pointer p-1'>
                {   !selectedItems?.length ? 
                    <span onClick={() => setShowDropDown(prev => !prev)} className='text-[#76787e] text-sm p-2 whitespace-nowrap leading-none'>
                        Select focus items
                    </span>
                    : 
                    <div className='flex-2 flex items-center gap-2 flex-wrap overflow-auto'>
                        {selectedItems?.map((option, index) => (
                            <SelectedPill
                                key={index}
                                option={option}
                                onClick={() => removeItemFromArray(option)}
                            />
                        ))}
                    </div>
                }
                <div onClick={() => setShowDropDown(prev => !prev)} className='w-full flex flex-1 justify-end p-2'>
                    { !showDropDown ? <Icon.ChevronDown color='gray' size={18} /> : <Icon.ChevronUp color='gray' size={18} />}
                </div>
            </div> 
            {
                showDropDown ?
                <motion.section variants={messageContainerVariants} className='w-full flex items-start flex-col gap-4 border border-[#E2E4E9] rounded-xl absolute bottom-100 my-2 bg-white p-3'>
                    <div className="h-[36px] relative w-full">
                        <img
                            src={searchIcon}
                            alt="search"
                            className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
                        />
                        <input
                            style={{
                            outline: "none",
                            border: "1px solid #E2E4E9",
                            boxShadow: "none",
                            }}
                            value={search}
                            onChange={(event) => handleSearchOptions(event)}
                            type="text"
                            placeholder="Search"
                            className="text-[#0A0D14] size-full w-full text-sm rounded-[8px] py-2 pl-9 pr-8"
                        />
                    </div>
                    <section className='flex flex-col w-full gap-4 max-h-[120px] overflow-auto'>
                        {
                            !filteredData?.length ?
                            <span className='text-xs text-[#76787e]'>No focus found!</span>
                            :
                            filteredData?.map((option, index) => (
                                <div onClick={() => handleAddItemArray(option)} className='text-sm w-full cursor-pointer' key={index}>
                                    {option}
                                </div>
                            ))
                        }
                    </section>
                </motion.section>
                :
                null
            }
        </div>
    </div>
  )
}

export default MultiSelect

const SelectedPill = ({ option, onClick }) => {
    return (
        <div className='flex items-center justify-between gap-2 py-2 px-3 bg-[#e8edf3a7] rounded-lg'>
            <span className='text-xs'>{option}</span>
            <Icon.X onClick={onClick} size={15} color='#ff000090' className='cursor-pointer' />
        </div>
    )
}