// import starIcon from "../../assets/Assets/Svgs/starIcon.svg";
import clock from "../../assets/Assets/Svgs/clock.svg";
import person from "../../assets/Assets/Svgs/person.svg";
import lock from "../../assets/Assets/Svgs/lock.svg";
import tag from "../../assets/Assets/Svgs/tag.svg";


function WhatWeOffer() {
  return (
    <div className="px-[5vw] pt-[4rem] pb-[4rem] bg-[#F9F7F3] tablet:pt-[3rem] laptop:pt-[4rem]">


      <div className="w-full text-center gap-[6px] tablet:gap-3 flex items-center flex-col justify-center tablet:items-start tablet:justify-start ">
        {/* <img
          src={starIcon}
          alt="a star"
          className="size-[48px] tablet:size-[60px]"
        /> */}
        <h2 className="capitalize text-[#002C3C] philosopher font-semibold text-[36px] tablet:text-[48px] leading-[40px] tablet:leading-[56px] laptop:mt-2 laptop:leading-[38px] ">
          What we offer
        </h2>
        <p className="font-medium text-[14px] tablet:text-[16px] m-0 leading-[22px] tablet:leading-[24px] laptop:leading-tight">
          Discover our offerings for a healthier, happier you.
        </p>
      </div>
      {/* <div className="mt-[70px] laptop:mt-[40px]">
        <div className="flex flex-col tablet:flex-row  items-center tablet:items-start justify-center gap-12 tablet:gap-[5%] laptop:gap-[95px]">
          <div className=" text-center tablet:w-[45%] laptop:w-[279px] flex items-center flex-col justify-center">
            <img src={clock} alt="a clock" className="h-[60px]" />
            <h2 className="font-bold mt-5 laptop:mt-3 mb-2 text-[18px] leading-[24px]">
              Round the Clock Personalized Support
            </h2>
            <p className="font-normal text-[16px] leading-[24px]">
              Mentra’s personalized interaction goes beyond understanding – it's
              like having a friend who truly gets you and offers support
              uniquely crafted just for you, wherever and whenever you need it
            </p>
          </div>
          <div className=" text-center tablet:w-[45%] laptop:w-[279px] flex items-center flex-col justify-center">
            <img src={tag} alt="a clock" className="h-[60px]" />
            <h2 className="font-bold mt-5 laptop:mt-3 mb-2 text-[18px] leading-[24px]">
              Licensed Therapists on Demand
            </h2>
            <p className="font-normal text-[16px] leading-[24px]">
              Mentra connects you with qualified therapists for personalized
              professional support without the hassle.
            </p>
          </div>
          <div className=" text-center hidden laptop:flex laptop:w-[279px] items-center flex-col justify-center">
            <img src={lock} alt="a clock" className="h-[60px]" />
            <h2 className="font-bold mt-5 laptop:mt-3 mb-2 text-[18px] leading-[24px]">
              Anonymous & Secure
            </h2>
            <p className="font-normal text-[16px] leading-[24px]">
              Mentra is a sanctuary of complete anonymity and privacy,
              shattering the walls of stigma. Your data is not just safe; it's
              yours to own. Your secrets are kept secret.
            </p>
          </div>
        </div>
        <div className="flex  flex-col tablet:flex-row items-center tablet:items-start justify-center mt-12 laptop:mt-2 gap-12 tablet:gap-[5%] laptop:gap-[95px]">
          <div className="tablet:w-[45%] text-center laptop:hidden flex items-center flex-col justify-center">
            <img src={lock} alt="a clock" className="h-[60px]" />
            <h2 className="font-bold mt-5 laptop:mt-3 mb-2 text-[18px] leading-[24px]">
              Anonymous & Secure
            </h2>
            <p className="font-normal text-[16px] leading-[24px]">
              Mentra is a sanctuary of complete anonymity and privacy,
              shattering the walls of stigma. Your data is not just safe; it's
              yours to own. Your secrets are kept secret.
            </p>
          </div>
          <div className=" tablet:w-[45%] text-center  laptop:w-[389px] flex items-center flex-col justify-center">
            <img src={person} alt="a clock" className="h-[60px]" />
            <h2 className="font-bold mt-5 laptop:mt-3 mb-2 text-[18px] leading-[24px]">
              Engaging Wellness Library
            </h2>
            <p className="font-normal text-[16px] leading-[24px]">
              Mentra is a world where you can engage, explore, and nurture your
              mind with a touch of playfulness through interactive tools and
              games.
            </p>
          </div>
        </div>
      </div> */}
      <div className="mt-[70px] laptop:mt-[60px] flex justify-center items-center flex-col gap-6 tablet:gap-[4vw] laptop:gap-2 laptop:justify-between laptop:flex-row">
        <div className="flex justify-center  items-center flex-col tablet:flex-row w-full gap-6 tablet:gap-[5%] laptop:gap-2 laptop:w-[49%] laptop:justify-between">
          <div className=" text-left w-full text-white px-4 tablet:px-5 laptop:px-4 min-[1400px]:px-5 py-10 tablet:w-[45%] laptop:w-[48%] bg-[#002C3C] rounded-[10px] h-[340px] laptop:h-[350px] flex items-start flex-col justify-start">
            <div className="size-[55px] tablet:size-[70px]  flex items-center justify-center bg-[#093340] rounded-[7px]">
              <img src={clock} alt="a clock" className="h-[40px] tablet:h-[55px] " />
            </div>
            <h2 className="font-bold mt-6 laptop:mt-3 mb-3 text-[18px] duo:text-[18px] tablet:h-[48px]  leading-[24px]">
              Round the Clock Personalized Support
            </h2>
            <p className="font-normal text-[14px] leading-[22px]">
              Mentra’s personalized interaction goes beyond understanding – it's
              like having a friend who truly gets you and offers support
              uniquely crafted just for you, wherever and whenever you need it.
            </p>
          </div>
          <div className=" text-left w-full text-white px-4 tablet:px-5 laptop:px-4 min-[1400px]:px-5 py-10 tablet:w-[45%] laptop:w-[48%] bg-[#002C3C] rounded-[10px] h-[340px] laptop:h-[350px] flex items-start flex-col justify-start">
            <div className="size-[55px] tablet:size-[70px] flex items-center justify-center bg-[#093340] rounded-[7px]">
              <img src={tag} alt="licensed" className="h-[40px] tablet:h-[55px]" />
            </div>
            <h2 className="font-bold mt-6 laptop:mt-3 mb-3 text-[18px] duo:text-[18px] tablet:h-[48px]  leading-[24px]">
            Experience Mental Health Coaches on Demand
            </h2>
            <p className="font-normal text-[14px] leading-[22px]">
            Mentra connects you with experienced mental health coaches for personalized professional support without the hassle
            </p>
          </div>
        </div>
        <div className="flex justify-center  items-center flex-col tablet:flex-row w-full gap-6 tablet:gap-[5%] laptop:gap-2 laptop:w-[49%] laptop:justify-between">
          <div className=" text-left w-full text-white px-4 tablet:px-5 laptop:px-4 min-[1400px]:px-5 py-10 tablet:w-[45%] laptop:w-[48%] bg-[#002C3C] rounded-[10px] h-[340px] laptop:h-[350px] flex items-start flex-col justify-start">
            <div className="size-[55px] tablet:size-[70px] flex items-center justify-center bg-[#093340] rounded-[7px]">
              <img src={lock} alt="secure" className="h-[40px] tablet:h-[55px]" />
            </div>
            <h2 className="font-bold mt-6 laptop:mt-3 mb-3 text-[18px] duo:text-[18px] tablet:h-[48px]  leading-[24px]">
              Anonymous & Secure
            </h2>
            <p className="font-normal text-[14px] leading-[22px]">
              Mentra is a sanctuary of complete anonymity and privacy,
              shattering the walls of stigma. Your data is not just safe; it's
              yours to own. Your secrets are kept secret.
            </p>
          </div>
          <div className=" text-left w-full text-white px-4 tablet:px-5 laptop:px-4 min-[1400px]:px-5 py-10 tablet:w-[45%] laptop:w-[48%] bg-[#002C3C] rounded-[10px] h-[340px] laptop:h-[350px] flex items-start flex-col justify-start">
            <div className="size-[55px] tablet:size-[70px] flex items-center justify-center bg-[#093340] rounded-[7px]">
              <img src={person} alt="engage" className="h-[40px] tablet:h-[55px]" />
            </div>
            <h2 className="font-bold mt-6 laptop:mt-3 mb-3 text-[18px] duo:text-[18px] tablet:h-[48px]  leading-[24px]">
              Engaging Wellness Library
            </h2>
            <p className="font-normal text-[14px] leading-[22px]">
              Mentra is a world where you can engage, explore, and nurture your
              mind with a touch of playfulness through interactive tools and
              games.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeOffer;
