import { useSettingsDataContext } from "@/App/pages/Settings";
import { useEditSettingsMutation } from "@/App/services/settingsApiSlice";
import { Checkbox } from "@/Components/ui/checkbox";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

function Notification() {
  const { settingsData: notificationsData, refetch } = useSettingsDataContext();

  const [firstTime, setFirstTime] = useState(true);

  // tempoarystate until the page is refreshed

  const [email, setEmail] = useState(notificationsData.email_notification);
  const [push, setPush] = useState(notificationsData.push_notification);
  const [sms, setSms] = useState(notificationsData.sms_notification);

  const [editSettings] = useEditSettingsMutation();

  function handleCheckChange(value) {
    setFirstTime(false);
    if (value === "email") {
      setEmail(!email);
    } else if (value === "sms") {
      setSms(!sms);
    } else {
      setPush(!push);
    }
  }

  useEffect(() => {
    async function updateNotifications() {
      const toastId = toast.loading("Updating settings...");

      try {
        const updatedSettings = await editSettings({
          sms_notification: sms ? "1" : "0",
          push_notification: push ? "1" : "0",
          email_notification: email ? "1" : "0",
        }).unwrap();
        // console.log(updatedSettings);
        toast.dismiss(toastId);
        toast.success("Updated successfully");
        refetch();
      } catch (error) {
        toast.error("Error updating notification settings");
      }
    }
    if (!firstTime) {
      updateNotifications();
    }
  }, [email, sms, push, editSettings, firstTime, refetch]);

  return (
    <div>
      <div>
        <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
          Notifications Settings
        </h2>
        <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
          Configure how you want to recieve notifications on this account.
        </p>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex flex-col tablet:flex-row gap-8 justify-center items-start tablet:gap-[4%]">
        <div className="flex  w-full tablet:w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Notification Method
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Choose how you prefer to receive notifications.
          </p>
        </div>
        <div className="flex w-full tablet:w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="space-y-6">
            <div className="items-top flex space-x-2">
              <Checkbox
                checked={email}
                onCheckedChange={() => handleCheckChange("email")}
                className=""
                id="email"
              />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="terms1"
                  className="text-sm font-medium text-[#0A0D14] text-[13px] tablet:text-[14px] leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Email Notifications
                </label>
                <p className="text-sm text-[#525866] text-[12px] tablet:text-[13px] leading-4 text-muted-foreground">
                  Receive notifications via email
                </p>
              </div>
            </div>
            <div className="items-top flex space-x-2">
              <Checkbox
                checked={push}
                onCheckedChange={() => handleCheckChange("push")}
                id="push"
              />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="terms1"
                  className="text-sm font-medium text-[#0A0D14] text-[13px] tablet:text-[14px] leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Push Notifications
                </label>
                <p className="text-sm text-[#525866] text-[12px] tablet:text-[13px] leading-4 text-muted-foreground">
                  Get real-time updates and alerts directly on your device
                </p>
              </div>
            </div>
            {/* <div className="items-top flex space-x-2">
              <Checkbox
                checked={sms}
                onCheckedChange={() => handleCheckChange("sms")}
                id="push"
              />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="terms1"
                  className="text-sm font-medium text-[#0A0D14] text-[13px] tablet:text-[14px] leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  SMS Notifications
                </label>
                <p className="text-sm text-[#525866] text-[12px] tablet:text-[13px] leading-4 text-muted-foreground">
                  Receive notifications via SMS
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-5 mb-8" />
    </div>
  );
}

export default Notification;

{
  /* The tremor ui libary is forcing a border on every input thats ehy i have to set the borders using inline styles and add !important to the tailwind styles*/
}
