import { apiSlice } from "../api/apiSlice"

export const appointmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCalendarData: builder.query({
            query: () => ({
                url: `/therapist/calendar/fetch`,
                method: "get",
            })
        }),
        saveCalander: builder.mutation({
            query: calander => ({
                url: `/therapist/calendar/save`,
                method: 'post',
                body: { ...calander }
            }),
        }),
        getAvailability: builder.query({
            query: () => ({
                url: `/therapist/availability/get-availability`,
                method: 'get'
            }),
            providesTags: ['hours']
        }),
        manageAvailability: builder.mutation({
            query: availability => ({
                url: `/therapist/availability/manage-availability`,
                method: 'post',
                body: { ...availability }
            }),
            invalidatesTags: ['hours']
        }),
        getBlockedDates: builder.query({
            query: () => ({
                url: `/therapist/blocked-date/get-blocked-date`,
                method: 'get',
            }),
            providesTags:['blockedDates'],
        }),
        addBlockedDates: builder.mutation({
            query: dates => ({
                url: `/therapist/blocked-date/add-blocked-date`,
                method: 'post',
                body: { ...dates }
            }),
            invalidatesTags:['blockedDates'],
        }),
        deleteBlockedDates: builder.mutation({
            query: id => ({
                url: `/therapist/blocked-date/delete-blocked-date/${id}`,
                method: 'delete',
            }),
            invalidatesTags:['blockedDates'],
        }),
    })
})

export const { 
    useGetCalendarDataQuery,
    useGetAvailabilityQuery,
    useSaveCalanderMutation,
    useManageAvailabilityMutation,
    useGetBlockedDatesQuery,
    useAddBlockedDatesMutation,
    useDeleteBlockedDatesMutation,
} = appointmentsApiSlice