import first from "../../Images/first.png";
import last from "../../Images/last.png";
import next from "../../Images/next.png";
import prev from "../../Images/prev.png";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/Components/ui/pagination";


import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";

function PaginationComponent({currentPage, tabLength, setItemsPerPage, setCurrentPage, handlePageClick}) {
  return (
    <div className=" mt-7 tablet:mt-10 flex flex-col tablet:flex-row w-full justify-between items-center ">
      <h4 className="text-sm hidden tablet:inline-block font-meduim text-[#525866]">
        Page {currentPage} of {tabLength}
      </h4>
      <div className="w-full flex tablet:hidden justify-center gap-12  items-center mb-3">
        <h4 className="text-sm  w-[115px] text-right  font-meduim text-[#525866]">
          Page {currentPage} of {tabLength}
        </h4>
        <Select
          defaultValue="5"
          onValueChange={(value) => {
            setItemsPerPage(+value);
            setCurrentPage(1);
          }}
        >
          <SelectTrigger className=" max-w-[115px] flex rounded-[8px] h-[32px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="5">5 / page</SelectItem>
              <SelectItem value="10">10 / page</SelectItem>
              <SelectItem value="20">20 / page</SelectItem>
              <SelectItem value="30">30 / page</SelectItem>
              <SelectItem value="40">40 / page</SelectItem>
              <SelectItem value="50">50 / page</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <Pagination className="w-fit">
        <PaginationContent className="w-fit">
          <div className="h-full hidden tablet:flex  justify-center  gap-3 items-center *:*:w-5 mr-5 ">
            <PaginationItem>
              <button
                onClick={() => handlePageClick(1)}
                disabled={currentPage === 1}
              >
                <img src={first} className=" cursor-pointer" alt="first page" />
              </button>
            </PaginationItem>
            <PaginationItem>
              <button
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src={prev} className=" cursor-pointer" alt="first page" />
              </button>
            </PaginationItem>
          </div>

          {Array.from({ length: tabLength }).map((_, i) => {
            if (i + 1 >= 5 && i + 1 < tabLength && i + 1 !== currentPage) {
              return;
            }
            if (i + 1 >= 4 && i + 1 < tabLength && i + 1 !== currentPage) {
              return (
                <PaginationItem key={i}>
                  <button
                    className={`size-8 flex rtt items-baseline justify-center text-[#525866] rounded-[6px] border-[#E2E4E9] border font-semibold text-sm `}
                  >
                    <PaginationEllipsis />
                  </button>
                </PaginationItem>
              );
            }
            if (
              i + 1 >= 4 &&
              i + 1 < tabLength &&
              i + 1 === currentPage &&
              i + 1 === 4
            ) {
              return (
                <div key={i} className="flex gap-1">
                  <PaginationItem>
                    <button
                      onClick={() => handlePageClick(i + 1)}
                      className={`size-8 text-center rounded-[6px] border-[#E2E4E9] border font-semibold text-sm ${
                        currentPage === i + 1
                          ? "bg-[#F6F8FA] text-[#0A0D14]"
                          : " text-[#525866]"
                      }`}
                    >
                      {i + 1}
                    </button>
                  </PaginationItem>
                  <PaginationItem>
                    <button
                      className={`size-8 flex items-baseline justify-center text-[#525866] rounded-[6px] border-[#E2E4E9] border font-semibold text-sm `}
                    >
                      <PaginationEllipsis />
                    </button>
                  </PaginationItem>
                </div>
              );
            }
            if (
              i + 1 > 4 &&
              i + 1 < tabLength &&
              i + 1 === currentPage &&
              i + 1 === tabLength - 1
            ) {
              return (
                <div key={i} className="flex gap-1">
                  <PaginationItem>
                    <button
                      className={`size-8 flex items-baseline justify-center text-[#525866] rounded-[6px] border-[#E2E4E9] border font-semibold text-sm `}
                    >
                      <PaginationEllipsis />
                    </button>
                  </PaginationItem>

                  <PaginationItem>
                    <button
                      onClick={() => handlePageClick(i + 1)}
                      className={`size-8 text-center rounded-[6px] border-[#E2E4E9] border font-semibold text-sm ${
                        currentPage === i + 1
                          ? "bg-[#F6F8FA] text-[#0A0D14]"
                          : " text-[#525866]"
                      }`}
                    >
                      {i + 1}
                    </button>
                  </PaginationItem>
                </div>
              );
            }
            if (i + 1 > 4 && i + 1 < tabLength && i + 1 === currentPage) {
              return (
                <div key={i} className="flex gap-1">
                  <PaginationItem>
                    <button
                      onClick={() => handlePageClick(i + 1)}
                      className={`size-8 text-center rounded-[6px] border-[#E2E4E9] border font-semibold text-sm ${
                        currentPage === i + 1
                          ? "bg-[#F6F8FA] text-[#0A0D14]"
                          : " text-[#525866]"
                      }`}
                    >
                      {i + 1}
                    </button>
                  </PaginationItem>
                  <PaginationItem>
                    <button
                      className={`size-8 flex items-baseline justify-center text-[#525866] rounded-[6px] border-[#E2E4E9] border font-semibold text-sm `}
                    >
                      <PaginationEllipsis />
                    </button>
                  </PaginationItem>
                </div>
              );
            } else {
              return (
                <PaginationItem key={i}>
                  <button
                    onClick={() => handlePageClick(i + 1)}
                    className={`size-8 text-center rounded-[6px] border-[#E2E4E9] border font-semibold text-sm ${
                      currentPage === i + 1
                        ? "bg-[#F6F8FA] text-[#0A0D14]"
                        : " text-[#525866]"
                    }`}
                  >
                    {i + 1}
                  </button>
                </PaginationItem>
              );
            }
          })}
          <div className="h-full hidden tablet:flex justify-center  gap-3 items-center *:*:w-5 ml-5 ">
            <PaginationItem>
              <button
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === tabLength}
              >
                <img src={next} className=" cursor-pointer" alt="first page" />
              </button>
            </PaginationItem>
            <PaginationItem>
              <button
                onClick={() => handlePageClick(tabLength)}
                disabled={currentPage === tabLength}
              >
                <img src={last} className=" cursor-pointer" alt="first page" />
              </button>
            </PaginationItem>
          </div>
        </PaginationContent>
      </Pagination>
      <Select
        defaultValue="5"
        onValueChange={(value) => {
          setItemsPerPage(+value);
          setCurrentPage(1);
        }}
      >
        <SelectTrigger className=" w-[115px] hidden tablet:flex rounded-[8px] h-[38px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="5">5 / page</SelectItem>
            <SelectItem value="10">10 / page</SelectItem>
            <SelectItem value="20">20 / page</SelectItem>
            <SelectItem value="30">30 / page</SelectItem>
            <SelectItem value="40">40 / page</SelectItem>
            <SelectItem value="50">50 / page</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="w-full flex justify-center gap-12 items-center tablet:hidden mt-5">
        <div className="flex justify-center  gap-3 items-center *:*:w-5  ">
          <button
            onClick={() => handlePageClick(1)}
            disabled={currentPage === 1}
          >
            <img src={first} className=" cursor-pointer" alt="first page" />
          </button>
          <button
            onClick={() => handlePageClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={prev} className=" cursor-pointer" alt="first page" />
          </button>
        </div>
        <div className=" flex justify-center  gap-3 items-center *:*:w-5  ">
          <button
            onClick={() => handlePageClick(currentPage + 1)}
            disabled={currentPage === tabLength}
          >
            <img src={next} className=" cursor-pointer" alt="first page" />
          </button>

          <button
            onClick={() => handlePageClick(tabLength)}
            disabled={currentPage === tabLength}
          >
            <img src={last} className=" cursor-pointer" alt="first page" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaginationComponent;
