import searchIcon from "../../../Images/search.png";
import avartar from "../../../Images/avatar9.png";
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogFooter,
} from "@/Components/ui/dialog";
import Loader from "@/Components/loader";

function ClientSearch({ openModal, setModal, setSessionsPage, clients, setSearch, setSelectedClient, isLoading  }) {
  
  function handleClick(client) {
    setSelectedClient(() => client);
    setSessionsPage(2);
  }
  
  return (
    <Dialog open={openModal} onOpenChange={setModal}>
      <DialogContent className="w-[90vw] max-w-[566px] max-h-[90vh] min-h-[400px]   bg-transparent p-0 border-none ">
        <div className="w-full relative">
          <div className="h-[40px] mt-[50px]  bg-white rounded-[10px] relative w-full ">
            <img
              src={searchIcon}
              alt="search"
              className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
            />
            <input
              style={{
                outline: "none",
                border: "1px solid #E2E4E9",
                boxShadow: "none",
              }}
              // value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Search"
              className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
            />
          </div>
          <div
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="mt-4 rounded-[16px] mb-[5vh] max-h-[70vh] py-4 px-6 overflow-y-scroll bg-white  w-full"
          >
            <h2 className="font-meduim text-[#868C98] text-xs">CLIENT LIST</h2>
            <div className="space-y-4">
              {
                isLoading ? 
                <Loader />
                :
                clients?.data?.map((client) => (
                  <div key={client.id} className="flex cursor-pointer justify-between items-center mt-4 gap-3">
                    <div className="flex justify-start gap-3 items-center truncate ">
                      <img style={{ backgroundColor: `${client?.avatar_background}` }} className="w-10 rounded-full" src={client?.avatar} alt="avatar" />
                      <div>
                        <h2 className="text-[#0A0D14] capitalize font-medium text-sm ">
                          {client?.name}
                        </h2>
                        <p className="text-[#525866] text-xs pt-[2px] truncate">
                          {client?.email}
                        </p>
                      </div>
                    </div>
                    <button onClick={() => handleClick(client)} className="bg-[#C4E4E9] rounded-[50px] py-[6px] px-3 text-[#1A6A73] font-medium text-xs sm:text-sm">
                      Book session
                    </button>
                  </div>
                ))
              }
             
            </div>
          </div>
          <DialogFooter className="sm:justify-start">
            <DialogClose className="absolute top-0 right-0" asChild>
              <button
                className="bg-white border z-50 rounded-[50px] text-[#525866] font-medium text-xs sm:text-sm border-[#E2E4E9] py-2 px-5"
                type="button"
              >
                Cancel
              </button>
            </DialogClose>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ClientSearch;
