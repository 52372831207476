import { apiSlice } from "../api/apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    dashOverview: builder.query({
      query: (date) => ({
        url: `/therapist/dashboard/overview?date_range=${date}`,
        method: "get",
      }),
    }),
    upcomingSessions: builder.query({
      query: (id) => ({
        url: `/therapist/session/get-sessions?status=Confirmed`,
        method: "get",
      }),
    }),
    unreadMessages: builder.query({
      query: () => ({
        url: `/therapist/messages/get-conversations?filter[]=unread`,
        method: "get",
      }),
      providesTags: ['unread']
    }),
  }),
});

export const { useDashOverviewQuery, useUpcomingSessionsQuery, useUnreadMessagesQuery } = dashboardApiSlice;
