import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/Components/ui/dialog'
import React from 'react'

const ErrorModal = ({ openModal, setModal }) => {
  return (
    <Dialog open={openModal} onOpenChange={setModal}>
        <DialogContent style={{ scrollbarWidth: "none", msOverflowStyle: "none" }} 
            className="w-[90vw] overflow-y-scroll border-none rounded-[16px]">
            <DialogHeader>
                <DialogTitle className="text-left text-[#0A0D14]">
                    Oops!
                </DialogTitle>
            </DialogHeader>
            <hr className="w-full border-none h-[1px] bg-[#E2E4E9]" />
            <div className="">
                <div className="flex flex-col justify-center items-center">
                    <p className="text-sm pt-1 text-[#525866]">
                        You cannot create a session on date older than today.

                        Kindly choose a new Date.
                    </p>
                    <div className="flex justify-center items-center gap-3 mt-4">
                    
                    <button onClick={() => setModal(false)} className="rounded-[50px] border bg-black text-[#FFFFFF] text-sm  py-[10px] font-semibold px-4">
                        Close
                    </button>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>
  )
}

export default ErrorModal