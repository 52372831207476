import { useState, useEffect } from "react";
import chevron from "../Images/chevron-right.png";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";

import { Label } from "@/Components/ui/label";
import { toast } from "sonner";
import {
  useGetListQuery,
  useSendDataMutation,
} from "../services/howToDoMoreApiSlice";
import Loader, { FormLoader } from "@/Components/loader";
import { motion } from "framer-motion";

function HowToDoMore() {
  const [doMoreId, setDoMoreId] = useState("");
  const [openModal, setModal] = useState(false);
  const [doMoreData, setDoMoreData] = useState("");

  const [user_message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  const { data: how_to_do_more, error: do_more_error } = useGetListQuery();
  const [sendData, { isLoading }] = useSendDataMutation();

  const variants = {
    initial: {
      opacity: 0,
      y: 120,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  useEffect(() => {
    try {
      if (do_more_error) {
        toast.error(
          "An error ocurred please check your internet connection and try again"
        );
      }
      if (!do_more_error && how_to_do_more.data !== doMoreData) {
        setDoMoreData(how_to_do_more.data);
      }
    } catch (error) {
      console.error(error?.data?.message);
    }
  }, [how_to_do_more, do_more_error, doMoreData]);

  function handleContribute(value) {
    setModal(!openModal);

    setDoMoreId(value);
  }

  async function submitHandler(event) {
    event.preventDefault();
    try {
      const sendMessage = await sendData({
        message: user_message,
        how_to_do_more_id: doMoreId,
        full_name: fullName,
        email: email,
        phone: phone,
      }).unwrap();
      console.log(sendMessage);
      toast.success("Data sent successfully");
    } catch (error) {
      console.log(error);
      if (error?.data?.errors) {
        const keys = Object.keys(error.data.errors);
        const firstKey = keys[0];
        const firstValue = error.data.errors[firstKey];
        toast.error(firstValue[0]);
      } else {
        toast.error("An error occurred while trying to submit the form. Please try again later.")
      }
    } finally {
      setEmail("");
      setPhone("");
      setMessage("");
      setFullName("");
      setModal(false);
    }
  }

  return doMoreData ? (
    <motion.div
      key="howto"
      variants={variants}
      initial="initial"
      animate="animate"
      className=" "
    >
      <Dialog open={openModal} onOpenChange={setModal}>
        <DialogContent className="w-[90vw] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]">
          <DialogHeader>
            <DialogTitle className="text-left text-[#0A0D14]">
              Get in touch
            </DialogTitle>
            <DialogDescription className="text-left text-[#525866] ">
              Please provide the following information
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={submitHandler}>
            <div>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-8" />
              <div className="space-y-3">
                <div>
                  <Label
                    htmlFor="name"
                    className="text-left font-semibold text-[#0A0D14]"
                  >
                    Full Name
                  </Label>

                  <input
                    style={{
                      outline: "none",
                      border: "1.5px solid #E2E4E9",
                      boxShadow: "none",
                    }}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    id="name"
                    type="text"
                    required
                    placeholder="Enter your name"
                    className="w-full border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1 "
                  />
                </div>

                <div>
                  <Label
                    htmlFor="email"
                    className="text-left font-semibold text-[#0A0D14]"
                  >
                    Email Address
                  </Label>

                  <input
                    style={{
                      outline: "none",
                      border: "1.5px solid #E2E4E9",
                      boxShadow: "none",
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    type="email"
                    required
                    placeholder="Enter your email"
                    className="w-full border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1 "
                  />
                </div>
                <div>
                  <Label
                    htmlFor="name"
                    className="text-left font-semibold text-[#0A0D14]"
                  >
                    Phone Number
                  </Label>

                  <input
                    style={{
                      outline: "none",
                      border: "1.5px solid #E2E4E9",
                      boxShadow: "none",
                    }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    id="name"
                    type="text"
                    required
                    placeholder="Enter your phone number"
                    className="w-full border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1 "
                  />
                </div>
                <div>
                  <Label
                    className="text-left font-semibold text-[#0A0D14]"
                    htmlFor="message"
                  >
                    Your message
                  </Label>
                  <textarea
                    style={{
                      outline: "none",
                      border: "1.5px solid #E2E4E9",
                      boxShadow: "none",
                    }}
                    required
                    value={user_message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1 focus:!border-zinc- max-h-[300px]"
                    placeholder="Type your message here."
                    id="message"
                  />
                </div>
              </div>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-8 mb-4" />

              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="bg-[#1A6A73] capitalize text-white ml-auto h-[40px] w-[105px] flex justify-center items-center leading-[17px] rounded-[50px]   text-[16px]  cursor pointer"
                >
                  {isLoading ? <FormLoader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          How to Do More
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          View and manage your sessions, track performance metrics, and access
          resources to support your practice.
        </p>
      </div>
      <hr className="w-full my-7 border-none h-[1px] bg-[#EAECF0]" />
      <div className="flex justify-center tablet:justify-start gap-[2%] gap-y-3 tablet:gap-y-[1.6vw] mb-6 laptop:mb-[25vh] min-[1405px]:gap-y-[1.4vw] flex-wrap ">
        {doMoreData.map((item, index) => (
          <div
            key={index}
            className="duo:h-[132px] tablet:max-w-[49%] gap-4 duo:gap-0  w-[90vw] bg-white flex items-start justify-between flex-col duo:max-w-[297px]  laptop:max-w-[32%] min-[1405px]:max-w-[23.5%] p-5 rounded-[16px]"
          >
            <h3 className="font-semibold text-[16px]  text-[#0A0D14] leading-7">
              {item.title}
            </h3>
            <button
              onClick={() => handleContribute(item.id)}
              className="flex group items-center cursor-pointer"
            >
              <p className="text-sm tablet:text-[15px] inline-block relative   text-[#1A6A73] font-semibold">
                Get Started
                <span className=" h-[1px] w-0 group-hover:w-full bg-[#1A6A73] rounded-[50px] transition-all duration-300 ease-in-out absolute bottom-0 left-0"></span>
              </p>
              <img src={chevron} className="w-5" alt="chev" />
            </button>
          </div>
        ))}
      </div>
    </motion.div>
  ) : (
    <div className="mt-8">
      <Loader />
    </div>
  );
}

export default HowToDoMore;
