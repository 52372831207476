import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/Components/ui/alert-dialog";

function WorksheetOverview({ openModal, setModal }) {
  const data = [
    {
      question: "Unhelpful Thought",
      answer: "I'm not good enough to succeed in this project.",
    },
    {
      question: "Is there any evidence that contradicts this thought?",
      answer:
        "I'm not good enough to succeed in this project.Yes, there are instances in the past where my initial thoughts or fears did not match the actual outcome. For example, I was worried about an important presentation, but it went smoothly and was well-received.",
    },
    {
      question: "Can you identify any patterns of unhelpful thinking?",
      answer:
        "I often catastrophize situations, assuming the worst possible outcome will occur. This leads to increased anxiety and stress, even when the situation is not as dire as I imagine.",
    },
    {
      question: "What would you say to a friend who had this thought?",
      answer:
        "I would remind them that thoughts are not always facts and encourage them to consider a more balanced perspective. I might suggest thinking about past experiences where things turned out better than expected.",
    },
    {
      question: "What are the benefits of thinking this way?",
      answer:
        "It may prepare me for potential challenges and motivate me to take precautions.",
    },
    {
      question: "What are the costs of thinking this way?",
      answer:
        "It can lead to unnecessary worry and stress, causing me to miss out on opportunities or experiences.",
    },
    {
      question: "How will you feel about this in 6 months time?",
      answer:
        "Looking back, I will likely realize that my current concerns were not as significant as they seemed at the time. I may have moved on to new challenges and experiences.",
    },
    {
      question: "Is there another way of looking at this situation?",
      answer:
        "Yes, I could try to view the situation from a more optimistic or realistic perspective. Instead of assuming the worst, I could consider the potential positive outcomes or the lessons I might learn from the experience.",
    },
  ];
  return (
    <div>
      <AlertDialog open={openModal} onOpenChange={setModal}>
        <AlertDialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="min-w-full bg-[#F6F8FA] p-0 border-none outline-none ring-0 !rounded-none h-screen overflow-y-scroll"
        >
          <div>
            <div className="relative w-full min-h-full">
              <div className="bg-white h-[55px] border-b border-[#ECECED] duo:h-[64px] z-50 flex justify-end tablet:justify-between items-center px-[5vw]  w-full sticky top-0 ">
                <h3 className="text-[#0A0D14] hidden tablet:inline text-base font-semibold">
                  Worksheet Details
                </h3>
                <div className="flex justify-center items-center gap-3">
                  <button
                    onClick={() => setModal(false)}
                    className="border border-[#E2E4E9] text-sm font-medium py-2 duo:py-[10px] text-center px-5 rounded-[50px] text-[#525866]"
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="mb-8 mt-11">
                <div className=" w-[90vw]  max-w-[841px] mx-auto ">
                  <div className=" p-4 duo:p-6 w-full bg-white rounded-[12px] ">
                    <h2 className="font-semibold text-lg leading-[22px] text-[#0A0D14] mb-5">
                      Challenging unhelpful thoughts
                    </h2>
                    <p className="text-sm text-[#525866]">
                      This worksheet helps you identify and challenge unhelpful
                      thoughts. By reframing them, you can improve your outlook
                      and emotional well-being.
                    </p>
                  </div>
                  <div className="p-4 mt-6 duo:p-6 w-full bg-white rounded-[12px]">
                    <h2 className="text-[#0A0D14] font-medium text-base">
                      Worksheet Questions
                    </h2>
                    <div className="mt-5 space-y-4">
                      {data.map((item, index) => (
                        <div
                          key={index}
                          className="border border-[#E2E4E9] rounded-[10px] flex justify-start items-start gap-2 duo:gap-4 p-[14px] "
                        >
                          <div className="h-full flex justify-start items-start">
                            <p className="text-base text-[#0A0D14] font-meduim">
                              {index + 1}.
                            </p>
                          </div>
                          <div>
                            <h2 className="text-[#0A0D14] text-base font-bold pb-2">
                              {item.question}
                            </h2>
                            <p className="text-sm text-[#525866]">
                              {item.answer}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default WorksheetOverview;
