import available from "../../assets/Assets/Svgs/available.svg";
import care from "../../assets/Assets/Svgs/care.svg";
import companion from "../../assets/Assets/Svgs/companion.svg";
import free from "../../assets/Assets/Svgs/free.svg";
import unique from "../../assets/Assets/Svgs/unique.svg";
import hug from "../../assets/Assets/Svgs/hug.svg";
import love from "../../assets/Assets/Svgs/love.svg";
import empower from "../../assets/Assets/Svgs/empower.svg";
import house from "../../assets/Assets/Svgs/house.svg";
import sensitive from "../../assets/Assets/Svgs/sensitive.svg";
import privateIcon from "../../assets/Assets/Svgs/private.svg";


import "../../index.css";
import "./about.css";
import WhatWeOffer from "./WhatWeOffer";
import HowItWorks from "./HowItWorks";

const AboutMentra = () => {
  return (
    <div className="px-[0%] tablet:pt-10  laptop:pt-[2%] bg-transparent tablet:bg-[#FFFCF6]">
      <div className=" text-center pb-[3rem] hidden tablet:block">
        <div className="">
          <p className="philosopher text-[18px] tablet:text-[25px] laptop:text-[34px]  leading-snug font-semibold">
            Designed for all of humanity, no matter who you are or where you
            are.
          </p>
          <p className="text-[18px] philosopher  tablet:text-[21px] laptop:text-[28px] font-semibold mt-[1%] ">
            Mentra is…
          </p>
        </div>

        {/* duplicates are needed for the animation to work well, so are the carefully set width's */}

        <div className=" pill-cont mt-[3%] overflow-clip py-9 flex flex-col gap-4 max-[767px]:hidden">
          <div className="pill-slide justify-center items-center flex flex-nowrap w-fit ">
            <div className="  flex w-[2050px]  justify-center flex-nowrap  gap-4 items-center ">
              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={unique} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Unique to You
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={privateIcon} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Completely Private
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={free} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Judgment Free
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Culturally Sensitive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={love} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Understanding and Kind
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Compassionate and Supportive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={privateIcon} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Easy and Flexible
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Guiding Light
                </p>
              </div>
            </div>
            <div className="  flex w-[2070px]  justify-center flex-nowrap  gap-4 items-center ">
              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={unique} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Unique to You
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={privateIcon} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Completely Private
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={free} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Judgment Free
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Culturally Sensitive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={love} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Understanding and Kind
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Compassionate and Supportive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={privateIcon} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Easy and Flexible
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={sensitive} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Guiding Light
                </p>
              </div>
            </div>
          </div>

          <div className="pill-slide justify-center items-center flex flex-nowrap w-fit">
            <div className=" flex w-[2000px]  justify-center flex-nowrap  gap-4 items-center px-[-10%]">
              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={empower} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Empowering Self-care
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={care} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Unbiased Listening Ear
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={available} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Always Available
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={hug} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Companion
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={house} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Safe Haven
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={love} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Friend
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={companion} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Culturally Inclusive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={unique} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Adaptive to Your Needs
                </p>
              </div>
            </div>
            <div className="  flex w-[1975px]  justify-center flex-nowrap  gap-4 items-center px-[-10%]">
              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={empower} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Empowering Self-Care
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={care} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Unbiased Listening Ear
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={available} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Always Available
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={hug} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Companion
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={house} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Safe Haven
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={love} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Your Friend
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={companion} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Culturally Inclusive
                </p>
              </div>

              <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill ">
                <img alt="" className="size-[48px]" src={unique} />
                <p className="ml-[12px] font-normal text-[16px] max-[767px]:text-[14px]">
                  Adaptive to Your Needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatWeOffer/>
      <HowItWorks/>
      {/* <div className="px-[5%] mt-[5%] py-[5%] max-[767px]:px-[5%]">
        <h1 className="philosopher text-[48px] text-[#002C3C] font-bold max-[767px]:text-[24px] max-[767px]:py-[5%]">
          How it Works
        </h1>
        <div className="flex items-center justify-center max-[900px]:flex-col">
          <div className="max-[900px]:w-[100%] w-[50%] laptop:w-[40%] mr-[4%] ">
            <div className="border-[#FAE19D] border-l-[4px] px-[3%] py-[3%]">
              <img alt="" src={badge1} />
              <div className="mt-[3%]">
                <h1 className="text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold text-[#1A6A73]">
                  Download, Sign Up, Subscribe and Personalize
                </h1>
                <p className="text-[#1A6A73] text-[14px] tablet:text-[16px] nine:text-[13px] laptop:text-[14px] mt-[2%]">
                  Get started in moments. Tell us a little about yourself to
                  personalize your experience.
                </p>
              </div>
            </div>

            <div className="border-[#F9F7F3] border-l-[4px] px-[3%] py-[3%] ">
              <img alt="" src={badge2} />
              <div className="mt-[3%]">
                <h1 className="text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold text-[#002C3C]">
                  Meet Your AI Companion
                </h1>
                <p className="text-[#002C3C] text-[14px] tablet:text-[16px] nine:text-[13px] laptop:text-[14px] mt-[2%]">
                  Chat with your empathetic AI buddy, designed to understand and
                  respond to your emotions.
                </p>
              </div>
            </div>

            <div className="border-[#F9F7F3] border-l-[4px] px-[3%] py-[3%]">
              <img alt="" src={badge3} />
              <div className="mt-[3%]">
                <h1 className="text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold text-[#002C3C]">
                  Need professional support? Get matched with a therapist
                </h1>
                <p className="text-[#002C3C] text-[14px] tablet:text-[16px] nine:text-[13px] laptop:text-[14px] mt-[2%]">
                  Our smart match gets you the right therapist. Book sessions
                  that fit your schedule.
                </p>
              </div>
            </div>

            <div className="border-[#F9F7F3] border-l-[4px] px-[3%] py-[3%]">
              <img alt="" src={badge4} />
              <div className="mt-[3%]">
                <h1 className="text-[18px] tablet:text-[20px] nine:text-[16px] laptop:text-[18px] font-semibold text-[#002C3C]">
                  Evolving with you
                </h1>
                <p className="text-[#002C3C] text-[14px] tablet:text-[16px] nine:text-[13px] laptop:text-[14px] mt-[2%]">
                  Mentra learns and adapts with you. Get tips and activities
                  made just for you.
                </p>
              </div>
            </div>
          </div>

          <div className="max-[900px]:mt-[10%]">
            <img alt="" width={600} src={workSide} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutMentra;
