import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

// import heroImg from "../assets/Assets/Svgs/heroImg.svg";

import hero from "../assets/Assets/Images/hero.png";
// import leaf from "../assets/Assets/Svgs/oxai.png";
import oxbridge from "../assets/Assets/Svgs/oxbridge.svg";
import AboutMentra from "../Components/HomeComponents/AboutMentra";
import JoinMentra from "../Components/JoinMentra";
// import Testimonials from "../Components/Utilities/Testimonials";
import Footer from "../Components/Footer";
import arrowRight from "../assets/Assets/Svgs/arrowRight.svg";
import "../Components/SignupForms/style.css";
import { AnimatePresence } from "framer-motion";
import Title from "../Components/Title";
import Waitlist from "../Components/Waitlist";
import AppStore from "../assets/Assets/apple.png";
import PlayStore from "../assets/Assets/play.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 768);
  //   };

  //   handleResize();

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div>
      <Title title={"Home"} />
      <div className="w-[100%] laptop:min-h-[762px] laptop:h-[100vh]  laptop:grid laptop:place-items-center laptop:max-h-[940px]  bg-gradient-to-b from-[#FBEDB1] to-[#FEF7EC] ">
        <div className="flex laptop:h-full min-h-[768px] flex-col gap-4 laptop:flex-row justify-between items-center laptop:pt-8 min-[1500px]:pt-0 px-[5%]  tablet:pt-[9rem]">
          <div className="  flex justify-center items-center laptop:pl-[10%] laptop:w-[50%] pl-0 min-[375px]:pl-[5%] w-[100%] pt-[6rem] tablet:pt-0">
            <div className=" pt-[3%]">
              {/* <img className="max-[767px]:w-[40%]" width={300} src={logo} /> */}
              <h1 className="philosopher text-[#1A6A73] text-[66px] font-semibold leading-[70px] max-[767px]:text-[42px] max-[767px]:mt-7">
                Mentra
              </h1>
              <h1
                style={{ fontStyle: "italic" }}
                className="philosopher text-[46px] text-[#002C3C] font-semibold max-[1500px]:text-[42px] max-[767px]:text-[28px] leading-[45px] max-[767px]:leading-[40px] italic font-caveat"
              >
           Your Everyday Therapist, Accessible, Affordable and Anonymous
              </h1>
              <div className="mt-[3.5%]">
                <p className="max-[767px]:text-[16px] leading-snug max-[767px]:w-[90%] italic">
                
                </p>

                {/* <div
                  onClick={openModal}
                  className="flex bg-[#1A6A73] rounded-[50px] px-5 h-[45px] pr-[15px] pl-5 w-fit mt-[5%] "
                >
                  <button
                    type="button"
                    className=" text-[white] text-[16px] w-[100%]"
                  >
                    Waitlist here
                  </button>
                  <img
                    className="ml-2 w-[20px] "
                    src={arrowRight}
                    alt="arrow right icon"
                  />
                </div> */}
                <div className="flex flex-col mt-10 items-start sm:flex-row sm:items-center gap-4">
                  <Link
                    to="https://apps.apple.com/ng/app/mentra/id6502996664"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img alt="app-links" src={AppStore}  className="w-48"/>
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img alt="app-links" src={PlayStore} className="w-44"/>
                  </Link>
                </div>
              </div>
              <div className="flex items-center w-[90%] mt-7 border-[2.5px] border-[#CFCFCF] duo:w-[361px] rounded-[20px] h-[96px] tablet:h-[110px] laptop:h-[96px] bg-[#121212]">
                <div className="flex w-[30%] bdrr h-full items-center justify-center">
                  <img
                    className=" w-[80%] duo:w-[90px]"
                    src={oxbridge}
                    alt="Mentra logo"
                  />
                </div>
                <div className="ml-3 w-[70%] ">
                  <h1 className="text-[#FCEFBD] text-[14.5px] pb-[2px] leading-[24px] tablet:leading-[26.5px] laptop:leading-[24px] tablet:text-[1.15rem] laptop:text-[1rem] font-semibold">
                    Semi-finalist
                  </h1>
                  <p className="text-[0.7rem] duo:pr-6 font-normal tablet:text-[0.9rem] leading-[1rem] tablet:leading-[18.5px] laptop:leading-[16px] laptop:text-[0.75rem] m-0 text-white">
                    Oxford and Cambridge Artificial{" "}
                    <br className="hidden min-[360px]:block" /> Intelligence
                    Competition 2023
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:w-[70%] laptop:w-[50%] laptop:pt-0 min-[1400px]:pt-16  w-[100%] max-[767px]:pt-[5%] max-[767px]:flex max-[767px]:justify-center">
            <img
              className="max-[767px]:w-[63%] w-[43%] max-w-[360px] mx-auto pt-5 tablet:pt-14  pb-8 laptop:pt-0 laptop:pb-0"
              width={700}
              src={hero}
              alt="hero"
            />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
      </AnimatePresence>
      <AboutMentra />
      <JoinMentra />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
