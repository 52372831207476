import { useState, useEffect } from "react";
import logo from "../assets/Assets/Svgs/logo.svg";
import { Link, useLocation, NavLink } from "react-router-dom";
import menu from "../assets/Assets/Svgs/menuIcon.svg";
import Waitlist from "../Components/Waitlist";
import { AnimatePresence, motion } from "framer-motion";

const Header = () => {
  const location = useLocation();

  const hiddenPaths = ["/signup", "/login", "/dashboard", `/video-chat`, "/start-call", "/forgotPasscode"];

  const shouldHideHeader = hiddenPaths.includes(location.pathname) || location.pathname.includes("dashboard") || location.pathname.includes("video-chat") || location.pathname.includes("start-call");

  const [activeLink, setActiveLink] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const onStaging = window.location.href.includes("stagingss");
  const onLocal = window.location.href.includes("localhostdd");

  useEffect(() => {
    const currentPageLink = window.location.pathname;
    const linkIndex = getLinkIndexFromPath(currentPageLink);
    setActiveLink(linkIndex);
    localStorage.setItem("activeLink", linkIndex.toString());
  }, []);

  const getLinkIndexFromPath = (path) => {
    switch (path) {
      case "/":
        return 0;
      case "/about":
        return 1;
      case "/mentra-for-work":
        return 2;
      case "/for-therapist":
        return 3;
      case "/wellness-library":
        return 4;
      case "/contact":
        return 5;
      default:
        return 0; // Default to the first link if path is not recognized
    }
  };

  const handleClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
    setIsDropdownVisible(false);
    // Store active link index in local storage
    localStorage.setItem("activeLink", index.toString());
  };
  const variants = {
    initial: {
      x: "70vw",
    },
    animate: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      x: "70vw",
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    !shouldHideHeader && (
      <div className="px-[5%] tablet:py-4 laptop:py-[1%] bg-[#FEFBF0] top-0 fixed w-screen z-10 max-[767px]:py-4">
        <div className="justify-between items-center hidden max-[1200px]:flex">
          <div
            onClick={() => handleLinkClick(0)}
            className="w-[28vw] max-w-[150px] tablet:max-w-[180px]"
          >
            <Link to="/">
              <img alt="logo" src={logo} />
            </Link>
          </div>
          {isDropdownVisible && (
            <div
              onClick={() => setIsDropdownVisible(false)}
              className="bg-transparent z-0 left-0 block w-screen h-screen top-0 absolute laptop:hidden"
            ></div>
          )}
          <div className="flex justify-end w-[30%]">
            <img
              alt="menu"
              onClick={handleClick}
              className="menu cursor-pointer"
              src={menu}
            />
            <AnimatePresence>
              {isDropdownVisible && (
                <motion.div
                  key="mobmenu"
                  variants={variants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="bg-white text-[16px] tablet:text-[18px] px-5 py-5 tablet:px-7 w-fit  mt-[10%] ml-[-32%] flex-col flex absolute shadow-lg rounded-[10px]"
                >
                  <NavLink
                    onClick={() => handleLinkClick(1)}
                    to="/about"
                    activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
                    className={({ isActive }) =>
                      isActive
                        ? " my-[4%] font-bold text-[#1A6A73]"
                        : "my-[4%] font-normal"
                    }
                  >
                    About
                  </NavLink>

                  <NavLink
                    onClick={() => handleLinkClick(2)}
                    to="/mentra-for-work"
                    activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
                    className={({ isActive }) =>
                      isActive
                        ? " my-[4%] font-bold text-[#1A6A73]"
                        : "my-[4%] font-normal"
                    }
                  >
                    Mentra for Work
                  </NavLink>

                  <NavLink
                    onClick={() => handleLinkClick(3)}
                    to="/for-therapist"
                    activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
                    className={({ isActive }) =>
                      isActive
                        ? " my-[4%] font-bold text-[#1A6A73]"
                        : "my-[4%] font-normal"
                    }
                  >
                    For Coaches
                  </NavLink>

                  <NavLink
                    onClick={() => handleLinkClick(4)}
                    to="/wellness-library"
                    activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
                    className={({ isActive }) =>
                      isActive
                        ? " my-[4%] font-bold text-[#1A6A73]"
                        : "my-[4%] font-normal"
                    }
                  >
                    Wellness Library
                  </NavLink>

                  <NavLink
                    onClick={() => handleLinkClick(5)}
                    to="/contact"
                    activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
                    className={({ isActive }) =>
                      isActive
                        ? " my-[4%] font-bold text-[#1A6A73]"
                        : "my-[4%] font-normal"
                    }
                  >
                    Contact
                  </NavLink>
                  <div className="flex flex-col gap-3">
                    {/* {onStaging || onLocal ? ( */}
                      <a href="#footer">
                        <button className="bg-[#20232D] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                          Get the App
                        </button>
                      </a>
                      <Link to="/login">
                        <button className="bg-[#1A6A73] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                          Coach Login
                        </button>
                      </Link>
                    {/* ) : ( */}
                    {/* )} */}
                  </div>
                  {/* )} */}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="flex justify-between items-center max-[1200px]:hidden">
          <div onClick={() => handleLinkClick(0)} className="w-[20%]">
            <Link to="/">
              <img alt="logo" className="w-[28vw] max-w-[180px]" src={logo} />
            </Link>
          </div>
          <div className="flex justify-between gap-2  w-[45%] max-[1119px]:justify-end max-[1119px]:gap-8 max-[1119px]:w-full max-[1290px]:w-[50%] max-[1520px]:w-[48%] max-[1930px]:w-[45%]">
            <NavLink
              onClick={() => handleLinkClick(1)}
              to="/about"
              activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
              className="transition-all text-[#1A6A73] duration-200 ease-in-out hover:scale-110"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "700" : "",
                  transform: isActive ? "scale(1.1)" : "",
                };
              }}
            >
              About
            </NavLink>
            <NavLink
              onClick={() => handleLinkClick(2)}
              to="/mentra-for-work"
              activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
              className="transition-all text-[#1A6A73] duration-200 ease-in-out hover:scale-110"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "700" : "",
                  transform: isActive ? "scale(1.1)" : "",
                };
              }}
            >
              Mentra for Work
            </NavLink>
            <NavLink
              onClick={() => handleLinkClick(3)}
              to="/for-therapist"
              activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
              className="transition-all text-[#1A6A73] duration-200 ease-in-out hover:scale-110"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "700" : "",
                  transform: isActive ? "scale(1.1)" : "",
                };
              }}
            >
              For Coaches
            </NavLink>
            <NavLink
              onClick={() => handleLinkClick(4)}
              to="/wellness-library"
              activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
              className="transition-all text-[#1A6A73] duration-200 ease-in-out hover:scale-110"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "700" : "",
                  transform: isActive ? "scale(1.1)" : "",
                };
              }}
            >
              Wellness Library
            </NavLink>
            <NavLink
              onClick={() => handleLinkClick(5)}
              to="/contact"
              activeStyle={{ fontWeight: "700", color: "#1A6A73" }}
              className="transition-all text-[#1A6A73] duration-200 ease-in-out hover:scale-110"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "700" : "",
                  transform: isActive ? "scale(1.1)" : "",
                };
              }}
            >
              Contact
            </NavLink>
          </div>
          <div className="hidden min-[1240px]:flex justify-end gap-3">
            {/* {onStaging || onLocal ? ( */}
              <a href="#footer">
                <button className="bg-[#20232D] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                  Get the App
                </button>
              </a>
              <Link to="/login">
                <button className="bg-[#1A6A73] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                  Coach Login
                </button>
              </Link>
            {/* ) : ( */}
            {/* )} */}
          </div>
        </div>
        <AnimatePresence>
          {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
        </AnimatePresence>
      </div>
    )
  );
};

export default Header;
