import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
// import SignupFormNine from "./SignupFormNine";
import { useSignUpContext } from "./SignUpContext";

const SignupFormEight = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(9);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.heard_about_us);
  const [other, setOther] = useState("");
  const [filled, setFilled] = useState(true);

  useEffect(() => {
    if (other) {
      setOption("");
    }
  }, [other]);


  const handleClick = () => {
    if (option || other) {
      let message;
      if (option && !other) {
        message = option;
      } else {
        message = other;
      }

      setSignupData((prevData) => ({
        ...prevData,
        heard_about_us: message,
      }));
      setStep(10);
    } else {
      setFilled(false);
    }
  };
  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col shadow-lg pb-8 items-center h-[100%] bg-white tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] rounded-[20px] mt-3 z-1  lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold ">How did you hear about us?</h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className="w-full">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>

          <div className="flex flex-col justify-center items-center gap-4 mb-2 w-full">
            <button
              value="Google"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Google" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Google
            </button>
            <button
              value="Instagram"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Instagram" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Instagram
            </button>
            <button
              value="Linkedin"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Linkedin" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              LinkedIn
            </button>
            <button
              value="Referral from a Friend"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Referral from a Friend"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Referral from a Friend
            </button>
            <button
              value="> Email Newsletter"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "> Email Newsletter"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Email Newsletter
            </button>
            <button
              value="> Newspaper or Magazine"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "> Newspaper or Magazine"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Newspaper or Magazine
            </button>

            <input
              placeholder="Other (please specify)"
              value={other}
              onChange={(e) => setOther(e.target.value)}
              type="text"
              className=" w-[90%] text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]"
            />
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormEight;
