import { apiSlice } from "../api/apiSlice";

export const howToDoMoreApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query({
      query: () => ({
        url: `/therapist/how-to-do-more`,
        method: "get",
      }),
    }),
    sendData: builder.mutation({
      query: (messages) => ({
        url: `/therapist/do-more-response`,
        method: "post",
        body: { ...messages },
      }),
    }),
  }),
});

export const { useGetListQuery, useSendDataMutation } = howToDoMoreApiSlice;
