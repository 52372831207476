import warn from "../../Images/warning.png";
import warnex from "../../Images/warnex.png";
import info from "../../Images/passi.png";
import lock from "../../Images/pass-lock.png";
import eyeclose from "../../Images/eyeclose.png";
import eyeopen from "../../Images/eyeopen.png";
import { Label } from "@/Components/ui/label";
import { useState } from "react";
import toast from "react-hot-toast";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/Components/ui/alert-dialog";
import { useDeleteAccountMutation } from "@/App/services/settingsApiSlice";
import { useNavigate } from "react-router-dom";
import { FormLoader } from "@/Components/loader";

function Delete() {
  const [showCurrent, setShowCurrent] = useState(false);
  const [passCode, setPasscode] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();

  function handleShow() {
    setShowCurrent(!showCurrent);
  }

  async function submitHandler(e) {
    e.preventDefault();
    try {
      const deleteMyAccount = await deleteAccount({
        password: passCode,
      }).unwrap();
      console.log(deleteMyAccount);
      navigate("/");
      toast.success("Your account has been deleted");
    } catch (error) {
      console.log(error);
      toast.error(error.data.message);
    } finally {
      setOpenModal(false);
      setPasscode("")
      
    }
  }
  return (
    <div>
      <div>
        <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
          Delete Account
        </h2>
        <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
          Manage the process of deleting your account.
        </p>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className=" w-full tablet:w-[70%] nine:w-full laptop:w-[70%]">
        <div className="flex gap-2 p-2 bg-[#FDEDF0] rounded-[8px] items-center w-full">
          <img src={warn} className="w-[16px]" alt="warning" />
          <p className="font-meduim text-[13px] leading-4 text-[#0A0D14]">
            This action cannot be undone.
          </p>
        </div>
        <p className="mt-5 text-[#525866] text-sm tablet:text-base">
          All of your data, including your profile, posts, and personal
          information, will be permanently removed. <br /> <br /> By entering
          your password, you confirm that you understand and accept the
          consequences of deleting your account.
        </p>
        <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />
        <AlertDialog open={openModal} onOpenChange={setOpenModal}>
          <AlertDialogTrigger className=" rounded-[50px] cursor-pointer bg-[#DF1C41] text-[13px] tablet:text-[14px] font-meduim leading-5 text-white py-2 tablet:py-[10px] px-3 tablet:px-4">
            Delete Account
          </AlertDialogTrigger>

          <AlertDialogContent
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="w-[90vw] max-h-screen overflow-y-scroll max-w-[440px] duo:p-6 rounded-[16px]"
          >
            <AlertDialogHeader>
              <div className="size-[40px] mx-auto mb-3 grid place-items-center bg bg-[#FDEDF0] rounded-[10px]">
                <img src={warn} className="w-[24px]" alt="warning" />
              </div>
              <AlertDialogTitle className="text-center mb-1 text-[#0A0D14]">
                Confirm Account Deletion
              </AlertDialogTitle>
              <AlertDialogDescription className="text-center text-[#525866] ">
                Are you sure you want to delete your account? This action cannot
                be undone. All your data will be permanently deleted.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className="flex gap-2  p-2 bg-[#FDEDF0] rounded-[8px] items-center w-full">
              <img src={warn} className="w-[16px]" alt="warning" />
              <p className="font-meduim text-[12.5px] leading-4 text-[#0A0D14]">
                This action cannot be undone.
              </p>
            </div>

            <form onSubmit={submitHandler}>
              <div className="space-y-2">
                <div>
                  <Label
                    htmlFor="name"
                    className="text-left flex items-center text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                  >
                    Confirm Deletion
                    <img src={info} className="w-5" alt="notice" />
                  </Label>

                  <div
                    tabIndex="0"
                    className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                  >
                    <img
                      className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                      src={lock}
                      alt="show password"
                    />
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      id="name"
                      value={passCode}
                      onChange={(e) => setPasscode(e.target.value)}
                      type={showCurrent ? "text" : "password"}
                      required
                      placeholder="********"
                      className="w-full   font-medium text-[14px] pl-[42px] py-[6px] focus:outline-red-500!  "
                    />
                    <button
                      type="button"
                      onClick={handleShow}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                    >
                      <img
                        className=" w-[19px]"
                        src={showCurrent ? eyeopen : eyeclose}
                        alt="show password"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-start gap-[2px] justify-start mt-[6px]">
                <img src={warnex} className="w-4" alt="notice" />
                <p className=" text-xs text-[#525866]">
                  Provide your password to proceed with account deletion.
                </p>
              </div>

              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mb-4 mt-5" />
              <div className="flex items-end gap-3 justify-end">
                <AlertDialogCancel className=" rounded-[50px] text-[#525866] cursor-pointer border border-[#E2E4E9] text-[13px] duo:text-[14px] font-meduim leading-5  py-2 tablet:py-[10px] px-3 tablet:px-4">
                  Dismiss
                </AlertDialogCancel>
                <button
                  disabled={isLoading}
                  type="submit"
                  className=" rounded-[50px] w-[120px] h-[36px] tablet:w-[136.5px] tablet:h-[40px] cursor-pointer bg-[#DF1C41] text-[13px] duo:text-[14px] leading-[14px] duo:leading-[15px] font-meduim  text-white flex justify-center items-center"
                >
                  {isLoading ? "...Loading" : "Delete Account"}
                </button>
              </div>
            </form>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
}

export default Delete;
