import empty from "../../Images/noWorksheet.png";
import plus from "../../Images/plus.png";
import remove from "../../Images/removeAval.png";

import { addDays, format } from "date-fns";
// import { DateRange } from 'react-day-picker';

import { Calendar } from "@/Components/ui/calendar";

import { useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/Components/ui/dialog";
import { useAddBlockedDatesMutation, useDeleteBlockedDatesMutation, useGetBlockedDatesQuery } from "@/App/services/appointmentsApiSlice";
import moment from "moment";
import { toast } from "sonner";
import Loader from "@/Components/loader";

function Blocked() {
  const [date, setDate] = useState();
  const [openModal, setModal] = useState(false);

  const { data:dates, isLoading } = useGetBlockedDatesQuery();
  const [ addBlockedDates, { isLoading:blockedLoading } ] = useAddBlockedDatesMutation();
  const [ deleteBlockedDates, { isLoading:deleteLoad } ] = useDeleteBlockedDatesMutation();

  const handleSetBlockeDate = async() => {
    try {
      const dateToBlock = {
        from_date: moment(date?.from).format("YYYY-MM-DD"),
        to_date: moment(date?.to).format("YYYY-MM-DD"),
      };
      const res = await addBlockedDates(dateToBlock).unwrap();
      toast.success("Blockout Date Updated Successfully")
    } catch (err){
      toast.error(err?.data?.message)
    }
    setModal(() => false)
  }

  const handleRemove = async(date) => {
    console.log(date?.id)
    try {
      const res = await  deleteBlockedDates(date?.id).unwrap();
      toast.success("Date Deleted Successfully");
    } catch (err) {
      toast.error(err?.data?.message);
    }
  }

  return (
    <div>
      <div className="w-full ">
        <div className="w-full flex  justify-between items-center">
          <h3 className="text-[#0A0D14] text-[15px] min-[639px]:text-base font-semibold">
            Blockout Dates
          </h3>

          <Dialog open={openModal} onOpenChange={setModal}>
            <DialogTrigger>
              <button className=" text-sm text-white rounded-[50px] font-medium bg-[#1A6A73] py-[8px] tablet:py-[10px] pl-2 pr-4 tablet:pl-3 tablet:pr-4 flex justify-center gap-1 items-center">
                <img className="w-5 " src={plus} alt="add" />
                <p className="hidden tablet:inline">Add blockout date</p>
                <p className="tablet:hidden">Add date</p>
              </button>
            </DialogTrigger>

            <DialogContent
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              className=" w-[90vw] min-[639px]:w-fit max-w-[666px] overflow-y-scroll max-h-[80vh] py-4 min-[639px]:py-5  px-4 tablet:px-5  border-none rounded-[6px]"
            >
              <DialogHeader>
                <DialogTitle className="text-left text-[#0A0D14]">
                  Block Out Date
                </DialogTitle>
                <DialogDescription className="text-left text-[#525866] ">
                  Select the date you want to block out from scheduling
                  sessions.
                </DialogDescription>
              </DialogHeader>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />
              <div className="w-full flex justify-center">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={setDate}
                  numberOfMonths={2}
                />
              </div>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-1" />

              <div className="w-full gap-[2%] min-[639px]:gap-2 flex justify-end">
                <DialogClose className="w-[49%] min-[639px]:w-fit" asChild>
                  <button className="bg-[#20232D]  capitalize text-white  py-[10px] px-5 rounded-[50px]   text-sm  cursor pointer">
                    Dismiss
                  </button>
                </DialogClose>
                <button
                  onClick={handleSetBlockeDate}
                  disabled={blockedLoading}
                  className="bg-[#1A6A73] w-[49%] min-[639px]:w-fit capitalize text-white py-[10px] px-5 rounded-[50px]   text-sm  cursor pointer"
                >
                  { blockedLoading ? 'Blocking...' : 'Block Date' }
                </button>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        {
          isLoading || deleteLoad ?
          <Loader />
          :
          <div className="w-full  flex-col my-auto laptop:min-h-[292px]  mt-6 flex justify-start items-start">
            {dates?.data?.length > 0 ? (
              <div className="w-full tablet:space-y-4 ">
                {dates?.data?.map((date, index) => {
                  const from = date.from_date ? date.from_date : "";
                  const to = date.to_date ? date.to_date : "";

                  if (to) {
                    return (
                      <div
                        key={index}
                        className="w-full flex justify-center items-center tablet:justify-between tablet:border border-[#E2E4E9] tablet:rounded-[12px] py-3 tablet:p-3"
                      >
                        <div className="flex w-[84%] tablet:w-fit flex-col justify-center items-center gap-4 tablet:flex-row">
                          <div className="flex w-full tablet:w-[235px] bg-[#F6F8FA] rounded-[10px] py-[10px] px-4 ">
                            <p className="text-[#525866] text-sm font-medium">
                              {from}
                            </p>
                          </div>
                          <h2 className="text-[#0A0D14] font-semibold text-sm uppercase hidden tablet:inline">
                            To
                          </h2>
                          <div className="flex w-full tablet:w-[235px] bg-[#F6F8FA] rounded-[10px] py-[10px] px-4 ">
                            <p className="text-[#525866] text-sm font-medium">
                              {to}
                            </p>
                          </div>
                        </div>
                        <div className="h-[56px] w-[16%] flex justify-center items-center tablet:hidden border-t-2 border-b-2 border-r-2  border-[#F6F8FA] rounded-r-[10px] ">
                          <button onClick={() => handleRemove(date)}>
                            <img src={remove} alt="remove" className="w-6" />
                          </button>
                        </div>
                        <button
                          onClick={() => handleRemove(date)}
                          className="size-[24px] hidden tablet:flex justify-center items-center border rounded-[6px] border-[#E2E4E9] "
                        >
                          <img src={remove} alt="remove" className="w-5" />
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className="w-full flex justify-center items-center tablet:justify-between tablet:border border-[#E2E4E9] tablet:rounded-[12px] py-3 tablet:p-3"
                      >
                        <div className="flex w-[84%] tablet:w-fit flex-col justify-center items-center gap-4 tablet:flex-row">
                          <div className="flex w-full tablet:w-[521px] bg-[#F6F8FA] rounded-l-[10px] tablet:rounded-[10px] py-[10px] px-4 ">
                            <p className="text-[#525866] text-sm font-medium">
                              {from}
                            </p>
                          </div>
                        </div>
                        <div className="h-[40px] w-[16%] flex justify-center items-center tablet:hidden border-t-2 border-b-2 border-r-2  border-[#F6F8FA] rounded-r-[10px] ">
                          <button onClick={() => handleRemove(date)}>
                            <img src={remove} alt="remove" className="w-6" />
                          </button>
                        </div>
                        <button
                          onClick={() => handleRemove(index)}
                          className="size-[24px] hidden tablet:flex justify-center items-center border rounded-[6px] border-[#E2E4E9] "
                        >
                          <img src={remove} alt="remove" className="w-5" />
                        </button>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <div className="size-full my-auto flex flex-col justify-center items-center">
                <img src={empty} className="w-[108px]" alt="empty" />
                <p className="mt-3 mb-8 text-sm text-[#868C98] text-center">
                  You have not blocked out any dates yet. <br />
                  Use this to mark dates when you are unavailable for sessions.
                </p>
              </div>
            )}
          </div>
        }

      </div>
    </div>
  );
}

export default Blocked;
