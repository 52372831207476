import searchIcon from "../Images/search.png";
import filter from "../Images/filter.png";
import sort from "../Images/sort.png";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";

import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClientTable from "../components/ClientList/ClientTable";
import PaginationComponent from "../components/ClientList/PaginationComponent";
import { useGetClientListQuery } from "../services/clientApiSlice";
import Loader from "@/Components/loader";

function ClientList() {
  const [search, setSearch] = useState("");
  const [sortCol, setSortCol] = useState("");
  const [asc, setAsc] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [tabLength, setTabLength] = useState(1);
  // const [sortedData, setSortedData] = useState(tableData);

  const { data:clients, isLoading } = useGetClientListQuery({ search: search });

  const [sortedData, setSortedData] = useState(clients?.data ?? []);
  const totalPages = Math.ceil(clients?.data?.length / itemsPerPage);

  useEffect(() => {
    setTabLength(totalPages);
  }, [itemsPerPage, totalPages]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // const currentPageData =  sortedData && sortedData.slice(startIndex, endIndex);

  // useEffect(() => {
  //   setSortedData(searchedData);
  // }, [searchedData]);

  function handleSort(value) {
    let sortedDatas;
    if (asc) {
      if (value.toLowerCase() === "last" || value.toLowerCase() === "next") {
        sortedDatas = [clients?.data].sort((a, b) => {
          const dateA = a[value] ? new Date(a[value]) : null; // Convert to Date object if not empty
          const dateB = b[value] ? new Date(b[value]) : null; // Convert to Date object if not empty
          if (!dateA && !dateB) return 0; // Both are empty
          if (!dateA) return 1; // Date A is empty, place it after
          if (!dateB) return -1; // Date B is empty, place it after
          return dateA - dateB;
        });

        // console.log(searchedData);
      } else if (value.toLowerCase() === "age") {
        sortedDatas = [clients?.data].sort((a, b) => {
          return a[value] - b[value];
        });
      } else {
        sortedDatas = [clients?.data].sort((a, b) => {
          return a[value].localeCompare(b[value]);
        });
      }
    } else {
      if (value.toLowerCase() === "last" || value.toLowerCase() === "next") {
        sortedDatas = [clients?.data].sort((a, b) => {
          const dateA = a[value] ? new Date(a[value]) : null; // Convert to Date object if not empty
          const dateB = b[value] ? new Date(b[value]) : null; // Convert to Date object if not empty
          if (!dateA && !dateB) return 0; // Both are empty
          if (!dateA) return -1; // Date A is empty, place it after
          if (!dateB) return 1; // Date B is empty, place it after
          return dateB - dateA;
        });
      } else if (value.toLowerCase() === "age") {
        sortedDatas = [clients?.data].sort((a, b) => {
          return b[value] - a[value];
        });
      } else {
        sortedDatas = [clients?.data].sort((a, b) => {
          return b[value].localeCompare(a[value]);
        });
      }
    }

    setSortedData(sortedDatas);
  }

  function handleTableSort(value) {
    if (value === sortCol) {
      setAsc(!asc);
    } else {
      setAsc(true);
    }
    setSortCol(value);
    handleSort(value);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div>
        <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          Client List
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          Manage your clients and their sessions. View details and schedule
          appointments with ease.
        </p>
      </div>
      <hr className="w-full mt-7 border-none mb-7  h-[1px] bg-[#EAECF0]" />
      <div className="w-full  rounded-[16px] p-4 pb-6  tablet:p-5 min-h-[250px] bg-white">
        <div className="w-full flex flex-wrap gap-3 justify-end items-center">
          <div className="h-[36px] relative w-full sixt:w-[49%] sixt:max-w-[396px]">
            <img
              src={searchIcon}
              alt="search"
              className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
            />
            <input
              style={{
                outline: "none",
                border: "1px solid #E2E4E9",
                boxShadow: "none",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="search"
              placeholder="Search"
              className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
            />
          </div>
          {/* <div className="flex w-full sixt:w-fit justify-between items-center gap-[4%] sixt:gap-3">
            <div className="flex w-[48%] sixt:w-fit bg-[#20232D] justify-center items-center rounded-[60px] cursor-pointer py-[9px] pl-[10px] pr-3 gap-1">
              <img src={filter} className="w-5" alt="filter" />
              <p className="text-white text-sm font-medium ">Filter</p>
            </div>
            <Select
              value={sortCol}
              onValueChange={(value) => handleTableSort(value)}
            >
              <SelectTrigger className=" w-[48%] sixt:w-[140px] rounded-[8px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                <SelectValue
                  placeholder={
                    <div className="flex justify-center items-center gap-2">
                      <img src={sort} alt="sort" className="w-5" />
                      <p className="text-sm text-[#525866] font-medium">
                        Sort by
                      </p>
                    </div>
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="name">Name</SelectItem>
                  <SelectItem value="age">Age</SelectItem>
                  <SelectItem value="gender">Gender</SelectItem>
                  <SelectItem value="last">Last Session</SelectItem>
                  <SelectItem value="next">Next Session</SelectItem>
                  <SelectItem value="status">Status</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div> */}
        </div>
        {
          isLoading ?
          <div className="py-4">
            <Loader />
          </div>
          :
          !clients?.data.length ?
          <div className="w-full">
            <div className="w-full  flex-col my-auto laptop:min-h-[292px] mt-6 flex justify-center items-center">
              <img src={empty} className="w-[108px]" alt="empty" />
              <p className="mt-3 text-sm text-[#868C98] text-center">
                No Worksheets Available. <br />
                Start creating worksheets to tailor therapy sessions to your
                clients' needs
              </p>
            </div>
          </div>
          :
          <>
            <div
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              className="overflow-x-scroll w-full"
            >
              <div className="mt-6 min-w-[1000px]">
                <ClientTable 
                  currentPageData={clients?.data.slice(startIndex, endIndex) ?? []} 
                  handleTableSort={handleTableSort} 
                />
              </div>
            </div>
            <div className="w-full">
              <PaginationComponent 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                setItemsPerPage={setItemsPerPage} 
                handlePageClick={handlePageClick} 
                tabLength={tabLength} 
              />
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default ClientList;
