import { apiSlice } from "@/App/api/apiSlice";
import { logOut } from "@/App/services/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { logOut } from "./authSlice"; 

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logOut());
    navigate("/login")
  };

  return logout;
};

export default useLogout;
