import React, { useEffect, useRef, useState } from 'react'
import { SenderCard } from './sendercard';
import { UserCard } from './usercard';
import { motion } from 'framer-motion';
import { messageVariants } from '@/App/pages/Messages';
import { useGetMessagesQuery, useLazyShowConversationDetailsQuery, useSendMessageMutation } from '@/App/services/messagesSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import Loader, { FormLoader } from '@/Components/loader';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '@/App/services/authSlice';
import ChatHeader from './chatheader';
import empty from "../../Images/noWorksheet.png";
import Pusher from 'pusher-js';
import * as Icon from 'react-feather'
import { handleError } from '@/App/utils/renderErrors';

const ChatBox = ({ currentChat, user, setShowNewChatSearch, refetchConvos, detailsLoading }) => {

    //variables
    // const dispatch = useDispatch();
    const token = useSelector(selectCurrentToken);
    const messagesEndRef = useRef();
    //states
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([]);

    const { data:chatMessages, isLoading, isFetching, isSuccess, refetch, isUninitialized } = useGetMessagesQuery(currentChat?.id ?? skipToken, {
        refetchOnFocus: false
    });
    const [ trigger, { error } ] = useLazyShowConversationDetailsQuery(currentChat?.id);
    const [ sendMessage, {} ] = useSendMessageMutation();

    //functions
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView?.({ behavior: "instant", block: 'end', inline: 'nearest' });
    };
    console.log(currentChat?.id)

    const connectToPusher = () => {
        let pusherChannel; // Declare pusherChannel variable
    
        // Unsubscribe from the channel if it's already subscribed
        if (pusherChannel) {
            pusherChannel.unbind_all();
            pusher.unsubscribe('private-conversation.' + currentChat?.id);
        }
    
        const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
            cluster: "mt1",
            encrypted: true,
            authEndpoint: `${import.meta.env.VITE_BASE_API_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        });
        pusherChannel = pusher.subscribe('private-conversation.' + currentChat?.id); // Assign pusherChannel
        pusherChannel.bind('new-message', (data) => {
            trigger(currentChat?.id)
            setMessages((prev) => [...prev, { ...data?.data }]);
            scrollToBottom();
            refetchConvos();
        });
        return () => {
            pusherChannel.unbind_all();
            pusher.unsubscribe('private-conversation.' + currentChat?.id);
        };
    };
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
      
        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();
      
        if (isToday) return "Today";
        if (isYesterday) return "Yesterday";
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      };
    
      const addDateIndicators = (messages) => {
        let newMessages = [];
        let currentDate = null;
      
        for (let i = 0; i < messages.length; i++) {
          const message = messages[i];
          const messageDate = new Date(message.created_at).toDateString();
          const formattedDate = formatDate(message.created_at);
      
          // Add date indicator if the date has changed
          if (currentDate !== messageDate) {
            newMessages.push({
              id: `date-${messageDate}`,
              message_type: 'date',
              date: formattedDate,
            });
            currentDate = messageDate;
          }
          newMessages.push(message);
        }
        return newMessages;
    };
      
      
    const updatedMessages = addDateIndicators(messages);

    const renderMessages = () => {
        return updatedMessages?.map((message, index) => {
            if (message?.sender_id === user?.id ) {
                return (
                    <UserCard 
                        key={index} 
                        text={message?.message} 
                        time={message?.created_at}
                    />
                );
            } else if(message?.sender_id && message?.sender_id !== user?.id) {
                return (
                    <SenderCard 
                        key={index} 
                        text={message?.message} 
                        time={message?.created_at}
                        senderImage={currentChat?.user_id.avatar}
                        senderName={currentChat?.user_id.name}
                    />
                );
            } else if(message?.message_type === 'date'){
                return (
                    <div className="flex gap-3 items-center justify-center w-full z-40 py-3">
                        <span className='text-black bg-[#0D4044] bg-opacity-15 p-1 px-2 rounded-xl text-[10px]'>{message?.date}</span>
                    </div>
                )
            }
            return null;
        });
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        if(!text || text === '') return;
        try {
            const messageData = {
                message: text,
                sender_id: user?.id,
                receiver_id: (currentChat?.user_id?.id).toString()
            }
    
            // setMessages((prev) => [ { ...messageData }, ...prev ]);
            setText('')
            const res = await sendMessage(messageData).unwrap();
            if(messages.length === 0 || messages.length === 1){
                refetchConvos();
            }

        } catch (error) {
            const errorMessage = handleError(error);
            toast.error(errorMessage);
        }

        // scrollToBottom()
    }

    //Effects
    useEffect(() => {
        messages && scrollToBottom();
    },[messages])

    useEffect(() => {
        if(currentChat)
            connectToPusher();
    },[currentChat])

    useEffect(() => {
        chatMessages && scrollToBottom();
    },[chatMessages]);

    useEffect(() => {
       !isUninitialized && refetch()
        chatMessages && scrollToBottom();
    },[refetch, currentChat])

    useEffect(() => {
        (currentChat && chatMessages ) && setMessages(() => [ ...chatMessages.data ?? [] ].reverse());
        scrollToBottom();
    },[chatMessages])
    

  return (
    <div className='h-full flex flex-col w-full'>
        {
            currentChat ?
            <>
                <ChatHeader
                    currentChat={currentChat}
                />
                <div className="w-full h-full flex-col p-6 overflow-y-auto flex-grow">
                    {
                        isLoading || isFetching ?
                        <div className='flex items-center justify-center flex-col m-auto w-full h-full p-6 overflow-y-auto'>
                            <div>
                                <FormLoader />
                            </div>
                            <p className="mt-3 text-sm text-[#868C98] text-center">
                                Loading Messages...
                            </p>
                        </div>
                        :
                        messages?.length === 0 ? 
                            <div className='flex items-center justify-center m-auto w-full h-full p-6 overflow-y-auto'>
                                <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                                    <img src={empty} className="w-[108px]" alt="empty" />
                                    <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                                        There are no messages available in this chat. <br />
                                    </p>
                                </div>
                            </div>
                        : 
                            <motion.ul variants={messageVariants} className="flex flex-col gap-6">
                                <>
                                    {renderMessages()}
                                </>
                                <div className="p-1" ref={messagesEndRef} />
                            </motion.ul>
                    }
                </div>
                <form id='message' onSubmit={handleSubmit} className='w-full p-3'>
                    <section  className='w-full flex items-end gap-3 p-2 rounded-xl border border-[#E2E4E9]'>
                        <textarea
                            style={{
                                outline: "none",
                                border: "none",
                                boxShadow: "none",
                            }}
                            type="text"
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            onKeyDown={(event) => event.key === 'Enter' && event.key !== 'Shift' ? handleSubmit(event) : null}
                            placeholder="Type a message..."
                            className="placeholder:text-sm focus:outline-none rounded-xl w-full text-xs text-tblack-100"
                        />

                        <button form='message' type='submit' className='text-sm bg-[#1A6A73] text-white py-3 px-4 rounded-full flex items-center gap-2'>
                            Send
                            <Icon.ChevronRight size={15} color='#FFF' />
                        </button>
                    </section>
                </form>
            </>
            :
            detailsLoading ?
            <div className='flex items-center justify-center m-auto relative w-full h-full p-6 overflow-y-auto'>
                <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                    <Loader />
                </div>
            </div>
            :
            <div className='flex items-center justify-center m-auto relative w-full h-full p-6 overflow-y-auto'>
                <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                    <img src={empty} className="w-[108px]" alt="empty" />
                    <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                        Messages sent after connecting with a client will appear here. <br />
                    </p>
                    <button onClick={() => setShowNewChatSearch((prev) => !prev)} className='text-sm bg-[#1A6A73] text-white py-3 px-4 rounded-full flex items-center gap-2'>
                        New Message
                    </button>
                </div>
            </div>
        }
    </div>
  )
}

export default ChatBox