import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetArticleDetailsQuery } from '../services/resourcesApiSlice';
import backIcon from "../Images/back-chevron.png";
import moment from 'moment';

const ArticleDetails = () => {

    const { slug } = useParams();
    const navigate = useNavigate()
    const { data:details, isLoading } = useGetArticleDetailsQuery(slug);

  return (
    <div className=''>
        <button onClick={() => navigate(-1)} className="flex bg-[#20232D] items-center justify-center gap-1 rounded-[50px] text-white py-[10px] pl-2 pr-4 mb-12">
            <img src={backIcon} className="w-5" alt="back" />
            <p className="text-sm font-semibold ">Back</p>
        </button>
        <section className='bg-white p-12 rounded-xl'>
            <div className='w-full flex flex-col items-center justify-center gap-3'>
                <h2 className="text-[#101828] text-center text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
                    {details?.data?.title}
                </h2>
                <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-center tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
                    {details?.data?.description}
                </p>
                <p className="font-medium text-sm text-teal-700">
                   Published {moment(details?.data?.cover?.created_at).format("YYYY-MM-DDD")}
                </p>
            </div>
            <hr className="w-full mt-7 border-none mb-7 h-[1px] bg-[#EAECF0]" />

            <main className='w-full flex flex-col gap-12'>
                <section className="w-full overflow-hidden rounded-md">
                    <img 
                    src={details?.data?.cover?.url}
                    className="w-full h-full"
                    style={{
                        backgroundSize: "100% 100%",
                        objectFit: "cover",
                        width: "100%",
                        height: "500px",
                        borderRadius: "3px",
                        // objectPosition: "top",
                    }}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                        "https://dunlite.com.au/wp-content/uploads/2019/04/placeholder.jpg";
                    }}
                    />
                </section>

                <article>
                    <div 
                        dangerouslySetInnerHTML={{ __html: details?.data?.body }}
                    />

                    {(details?.data?.attachments?.length ?? []) > 0 && (
                        <>
                            <div>
                                <h2 className="laptop:mx-[25%] mx-[5vw] mt-5">
                                    Attachments: Ensure you listen with earphones
                                </h2>
                                {details?.data?.attachments.map((attachment, index) => (
                                <div key={index}>
                                    {attachment.file?.mime_type === "mp3" ? (
                                        <div className="flex flex-col tablet:flex-row items-start tablet:text-[20px] laptop:mx-[25%] mx-[5vw] tablet:items-center justify-start mt-4 gap-2 tablet:gap-4">
                                            <audio controls>
                                                <source src={attachment.file.url} type="audio/mp3" />
                                                Your browser does not support the audio tag.
                                            </audio>
                                        </div>
                                    ) : (
                                    attachment.file.url && (
                                        <section className="w-full overflow-hidden rounded-md">
                                            <img 
                                            src={attachment.file.url}
                                            className="w-full h-full"
                                            style={{
                                                backgroundSize: "100% 100%",
                                                objectFit: "cover",
                                                width: "100%",
                                                height: "500px",
                                                borderRadius: "3px",
                                                // objectPosition: "top",
                                            }}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                "https://dunlite.com.au/wp-content/uploads/2019/04/placeholder.jpg";
                                            }}
                                            />
                                        </section>
                                    )
                                    )}
                                </div>
                                ))}
                            </div>
                        </>
            )}
                </article>
            </main>
        </section>
    </div>
  )
}

export default ArticleDetails