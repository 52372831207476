import { apiSlice } from "../api/apiSlice"

export const resourcesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllArticles: builder.query({
            query: ()=> ({
                url: `/therapist/wellness-library/courses/list`,
                method: "get",
            }),
            providesTags: ['articles']
        }),
        getFavoriteArticles: builder.query({
            query: ()=> ({
                url: `/therapist/wellness-library/courses/favourites`,
                method: "get",
            }),
            providesTags: ['favorites']
        }),
        getArticleDetails: builder.query({
            query: slug => ({
                url: `/therapist/wellness-library/courses/show/${slug}`,
                method: "get",
            }),
        }),
        favoriteToggle: builder.mutation({
            query: course => ({
                url: `/therapist/wellness-library/courses/save-to-favourite`,
                method: 'post',
                body: course
            }),
            invalidatesTags: ['favorites', 'articles']
        })
    })
})

export const {
    useGetAllArticlesQuery,
    useGetFavoriteArticlesQuery,
    useGetArticleDetailsQuery,
    useFavoriteToggleMutation,
} = resourcesApiSlice