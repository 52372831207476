import { useState } from "react";
import profileIcon from "../assets/Assets/Svgs/profileIcon.svg";
// import cancel from "../assets/Assets/Svgs/cancel.svg";
import { Link } from "react-router-dom";
import info from "../assets/Assets/Svgs/info.svg";
import { FormLoader } from "./loader";
import { motion } from "framer-motion";

function Waitlist({ setModalOpen }) {
  const [joined, setJoined] = useState(false);
  const [email, setEmail] = useState("");
  const [progress, setProgress] = useState(false);
  const [added, setAdded] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleJoin = (e) => {
    e.preventDefault();
    setProgress(true);
    if (email.trim() === "") {
      return;
    }
    const requestData = {
      email: email,
    };

    fetch(import.meta.env.VITE_BASE_API_URL + "/waitlist/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          setJoined(true);
          setProgress(false);
        } else {
          setProgress(false);
          if (response.status === 400) {
            setAdded(true);
            setJoined(true);
          } else {
            console.error(
              "Failed to join waitlist:",
              response.status,
              response.statusText
            );
          }
        }
      })
      .catch((error) => {
        // Handle network error if needed
        console.error("Network error while joining waitlist:", error);
      });
  };

  const bgVars = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };

  const modalVars = {
    initial: {
      opacity: 0,
      y: "30vh",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: "30vh",
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <div className="modal-overlay log-input z-50">
      <motion.div
        key="bach"
        variants={bgVars}
        initial="initial"
        animate="animate"
        exit="exit"
        onClick={closeModal}
        className="modal-overlay-color  w-full h-full absolute top-0 z-0 left-0 "
      ></motion.div>
      {joined ? (
        <motion.div
          key="joined"
          variants={modalVars}
          initial="initial"
          animate="animate"
          exit="exit"
          className="modal z-50"
        >
          <div className="w-[100%] flex cursor-pointer justify-end">
            <button onClick={closeModal} className="btn-close">
              <span className="icon-cross"></span>
            </button>
          </div>
          <img alt="modal icon" src={profileIcon} />
          <div className="w-[90%] text-center my-[3%]">
            {added ? (
              <h1 className="text-[#0A090B] font-bold text-[24px] mb-[3%]">
                You’re already on our list. Stay tuned!
              </h1>
            ) : (
              <h1 className="text-[#0A090B] font-bold text-[24px] mb-[3%]">
                Congratulations! 🎉 You're on the Mentra Waitlist!
              </h1>
            )}

            <p className="text-[#7F7D83]">
              You'll be among the first to know when Mentra launches. Get ready
              for an exciting journey to a happier, healthier you!
            </p>
          </div>

          <div className="w-[100%]">
            <button
              onClick={closeModal}
              type="button"
              className="bg-[#1A6A73] cursor-pointer text-center w-full  py-[5%] mt-[5%] text-[white] rounded-[30px] "
            >
              Done
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="joining"
          variants={modalVars}
          initial="initial"
          animate="animate"
          exit="exit"
          className="modal z-50"
        >
          <div className="w-[100%] flex justify-end">
            {/* <img
              alt="modal icon"
              onClick={closeModal}
              width={40}
              src={cancel}
            />*/}
          </div>
          <button onClick={closeModal} className="btn-close">
            <span className="icon-cross"></span>
          </button>
          <img alt="modal icon" src={profileIcon} />
          <div className="w-full text-center my-[3%]">
            <h1 className="text-[#0A090B] font-bold text-[24px] mb-[3%]">
              Join the Waitlist
            </h1>
            <p className="text-[#7F7D83]">
              Get exclusive early access to personalized well-being support,
              transformative conversations, and a community that empowers your
              journey to a happier, healthier you.
            </p>
          </div>
          <form className="max-[767px]:w-full mx-auto" onSubmit={handleJoin}>
            <div className="bg-[#F9F7F3] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
              <p className="text-[15px] font-semibold text-[#191B1E]">Email</p>
              <input
                required
                disabled={progress ? true : false}
                className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                placeholder="email@mail.com"
                value={email}
                onChange={handleEmailChange}
                type="email"
              />
            </div>
            <div className="flex my-4 items-center ">
              <img alt="" src={info} />
              <Link onClick={closeModal} to="/terms-and-conditions">
                <h1 className="text-[12px] hover:text-[#1A6A73] terms">
                  Terms and Conditions Apply
                </h1>
              </Link>
            </div>
            <div className="w-[100%]">
              <button
                disabled={progress ? true : false}
                //   onClick={handleJoin}
                type="submit"
                className="bg-[#1A6A73] py-2 text-center w-[100%] flex justify-center items-center relative h-[50px] tablet:h-[65px] cursor-pointer  mt-[3%] text-[white] rounded-[30px] max-[767px]:w-[100%]"
              >
                {progress ? <FormLoader /> : "Join Waitlist"}
              </button>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
}

export default Waitlist;
