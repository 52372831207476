import moment from 'moment';
import user from '../../../assets/Assets/Images/Avatar.png'
import { motion } from 'framer-motion';
import { messageVariants } from '@/App/pages/Messages';

export const SenderCard = ({ senderName, senderImage, text, time }) => {

    return (
        <motion.li variants={messageVariants} className="flex items-start gap-3">
            {/* <div className= 'rounded-full'>
                <img
                    className="w-8 h-8 rounded-full flex-shrink-0"
                    src={ senderImage ?? user }
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        objectFit: "cover",
                    }}
                    onError={(e) => {
                        e.target.onerror = user;
                        e.target.src =  user;
                    }}
                    alt="profile"
                />
            </div> */}

            <div className="flex justify-start">
                <section className='flex flex-col gap-1.5'>
                    <div className='flex item-center justify-between gap-6'>
                        <span className="text-[10px] text-[#475467] flex items-start justify-start ">{moment(time).format("LT")}</span>
                    </div>
                    <div className="max-w-xl">
                        <div
                            style={{
                                wordWrap: 'break-word'
                            }} 
                            className='max-w-xl text-wrap'
                        >
                            <p className="p-3 text-sm border-[1px] border-[#E2E4E9] rounded-r-xl rounded-b-xl bg-[#F6F8FA]">
                                {text}
                            </p>
                        </div>
                    </div>
                </section>
            </div>
       </motion.li>
    )
}