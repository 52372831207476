import { apiSlice } from "../api/apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: `/auth/therapy-login`,
                method: "post",
                body: {...credentials}
            })
        }),
        forgotPassword: builder.mutation({
            query: credentials => ({
                url: `/auth/password/forgot`,
                method: "post",
                body: {...credentials}
            })
        }),
        resetPassword: builder.mutation({
            query: credentials => ({
                url: `/auth/password/reset-therapy`,
                method: "post",
                body: {...credentials}
            })
        }),
    })
})

export const { 
    useLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = authApiSlice