import sessionBut from "../../Images/session-button.png";
import now from "../../Images/now-clock.png";
import other from "../../Images/pass.png";
import Loader from "@/Components/loader";
import moment from "moment";
import empty from "../../Images/noWorksheet.png";
import { useGetClientSessionsQuery } from "@/App/services/clientApiSlice";

function Completed({ id }) {

  const { data:completed, isSuccess, isLoading } = useGetClientSessionsQuery({
    id: id,
    status: 'completed'
  });

  return (
    <div className="space-y-4">
      {
        isLoading ?
        <Loader />
        :
        !completed?.data.length ?
        <div
          
          className="w-full  flex-col my-auto laptop:min-h-[292px] flex justify-center items-center"
        >
          <img src={empty} className="w-[108px]" alt="empty" />
          <p className="mt-3 text-sm text-[#868C98] text-center">
            No Completed Appointments. <br />
          </p>
        </div>
        :
        completed?.data?.map((data, index) => (
          <div
            key={index}
            className="pt-4 px-2 pb-2 space-y-3 border border-[#E2E4E9] rounded-[12px]"
          >
            <div className="px-2 flex flex-col justify-start items-start">
              <div className="flex justify-between w-full items-center">
                <h4 className="font-medium capitalize text-[14px] leading-5 text-[#0A0D14]">
                  {data.user?.name}
                </h4>
                <div className="flex justify-center items-center gap-[6px] ">
                  <p className="text-[12px] leading-4 font-medium text-[#176448] bg-[#CBF5E5] rounded-[50px] px-2 py-[2px]">
                    {data.status}
                  </p>
                  <img className="w-[24px]" src={sessionBut} alt="view" />
                </div>
              </div>
              <p className="text-[#525866] text-[12px] font-normal leading-4 pt-[2px]">
                {moment(data?.starts_at).format('LT')}
              </p>
            </div>
            <div
              className={`${"bg-[#F6F8FA]"} p-2 rounded-[8px] flex justify-between items-center`}
            >
              <div className="flex justify-center items-center gap-1">
                <img
                  src={other}
                  className="w-4"
                  alt="clock"
                />
                  <p className="text-[12px] leading-4 text-[#525866]">
                    {moment(data?.starts_at).endOf('').fromNow()}
                  </p>
              </div>
                <p className="text-[#525866] text-[12px] leading-4">
                  {moment(data?.starts_at).format('MMMM Do, YYYY')}
                </p>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default Completed;
