import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "../Components/Footer";
import { fetchPrivacyPolicy } from "../Api";
import Title from "../Components/Title";
import { motion } from "framer-motion";
import Loader from "../Components/loader";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const response = await fetchPrivacyPolicy();
        const data = response.data;
        setPrivacyPolicy(data.data.body); // Adjust the property name based on the actual response structure
      } catch (error) {
        console.error("Error fetching terms and conditions:", error.message);
      }
    };

    fetchPolicy();
  }, []);

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <div>
      <Title title={"Privacy"} />
      <div className=" pt-[100px] pb-10 laptop:pt-[9%]  px-[5vw] laptop:px-[5%]">
        <h1 className="text-center text-[32px] font-bold mt-[3%] mb-16">
          Privacy Policy
        </h1>
        {!privacyPolicy ? (
          <Loader />
        ) : (
          <motion.div
            key="policy"
            variants={variants}
            initial="initial"
            animate="animate"
            className="mx-0 laptop:mx-[15%]"
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
