import { Switch } from "@/Components/ui/switch";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import timeImg from "../../Images/time.png";
import add from "../../Images/addAval.png";
import remove from "../../Images/removeAval.png";
import { useState, useEffect, useMemo } from "react";
import {
  useGetAvailabilityQuery,
  useManageAvailabilityMutation,
  useSaveCalanderMutation,
} from "@/App/services/appointmentsApiSlice";
import Loader from "@/Components/loader";
import moment from "moment";
import { toast } from "sonner";

export default function AvailHours() {
  const [allData, setAlldata] = useState([]);

  const { data: availableHours, isLoading, isSuccess } = useGetAvailabilityQuery(null, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [manageAvailability, { isLoading: manageLoading }] = useManageAvailabilityMutation();
  const [saveCalander] = useSaveCalanderMutation();

  // Helper function to convert 24-hour format to 12-hour format with AM/PM
  const convertTo12HourFormat = (time24) => {
    return moment(time24, "HH:mm").format("hh:mm A");
  };

  // Helper function to convert 12-hour format with AM/PM back to 24-hour format
  const convertTo24HourFormat = (time12) => {
    return moment(time12, "hh:mm A").format("HH:mm");
  };

  const handleUpdateAvailability = async () => {
    try {
      if (checkForOverlappingTimes()) {
        toast.error("You have overlapping time slots. Please correct them before updating.");
        return;
      }
      const updateDetails = {
        data: [...allData],
      };
      console.log(updateDetails);
      availabilityCalanderSave(updateDetails);
      const res = await manageAvailability(updateDetails).unwrap();
      toast.success("Availability Updated Successfully!");
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Updated generateTimeSlots to produce times in 12-hour format
  function generateTimeSlots(from, to, interval) {
    const times = [];
    let currentTime = moment(from, "HH:mm");
    const endTime = moment(to, "HH:mm");

    while (currentTime < endTime) {
      let nextTime = moment(currentTime).add(interval, "minutes");
      times.push({
        from: convertTo12HourFormat(currentTime.format("HH:mm")),
        to: convertTo12HourFormat(nextTime.format("HH:mm")),
      });
      currentTime = nextTime;
    }

    return times;
  }

  const availabilityCalanderSave = async (details) => {
    try {
      let totalTimeslots = [];

      details.data.forEach((weekday) => {
        if (weekday.availability) {
          let combinedTimeSlots = [];

          weekday.times.forEach((time) => {
            // Convert times back to 24-hour format before generating slots
            const timeslots = generateTimeSlots(
              convertTo24HourFormat(time.from),
              convertTo24HourFormat(time.to),
              30
            );
            const formattedTimeSlots = timeslots.map(
              (slot) => `${convertTo24HourFormat(slot.from)} - ${convertTo24HourFormat(slot.to)}`
            );

            combinedTimeSlots.push(...formattedTimeSlots);
          });

          totalTimeslots.push({
            interval: 30,
            weekday: weekday.day.charAt(0).toUpperCase() + weekday.day.slice(1),
            timeslots: combinedTimeSlots,
          });
        }
      });

      // Save each timeslot
      for (const slot of totalTimeslots) {
        let res = await saveCalander(slot).unwrap();
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleAvailability(index) {
    setAlldata((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        availability: !newData[index].availability,
      };
      return newData;
    });
  }

  const checkForOverlappingTimes = () => {
    for (let dayData of allData) {
      const times = dayData.times;
      for (let i = 0; i < times.length - 1; i++) {
        for (let j = i + 1; j < times.length; j++) {
          const fromTime1 = moment(times[i].from, "hh:mm A");
          const toTime1 = moment(times[i].to, "hh:mm A");
          const fromTime2 = moment(times[j].from, "hh:mm A");
          const toTime2 = moment(times[j].to, "hh:mm A");

          // Check if time ranges overlap
          if (fromTime1.isBefore(toTime2) && fromTime2.isBefore(toTime1)) {
            return true; // Found an overlap
          }
        }
      }
    }
    return false; // No overlaps
  };

  useEffect(() => {
    console.log("availableHours", availableHours); // Check the structure here

    if (isSuccess && !isLoading) {
      availableHours?.data?.forEach((time) => {
        setAlldata((prev) => [
          ...prev,
          {
            day: time?.day,
            times: time?.times.map((item) => ({
              from: convertTo12HourFormat(item?.from),
              to: convertTo12HourFormat(item?.to),
            })),
            availability: time?.availability,
          },
        ]);
      });
    }
    return () => setAlldata([]);
  }, [isSuccess, isLoading]);

  return (
    <div>
      <header className="w-full flex flex-col items-start md:flex-row md:items-center justify-between gap-2">
        <h2 className="font-semibold text-base text-[#0A0D14]">Availability Hours</h2>
        <button
          disabled={isLoading || manageLoading}
          onClick={handleUpdateAvailability}
          className="flex gap-1 justify-center items-center rounded-[50px] border bg-black py-2 px-3"
        >
          <p className="font-semibold text-sm text-white">Update Details</p>
        </button>
      </header>
      {isLoading || manageLoading ? (
        <Loader />
      ) : (
        <div className="mt-2 max-[767px]:divide-y max-[767px]:divide-[#E2E4E9] tablet:div tablet:space-y-4 tablet:mt-4">
          {allData?.map((item, index) => (
            <DayBox
              key={index}
              data={item}
              index={index}
              setAlldata={setAlldata}
              allData={allData}
              setAvailability={() => handleAvailability(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function DayBox({ data, index, setAlldata, allData, setAvailability }) {
  const [toggle, setToggle] = useState(data.availability);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked && data.times.length <= 0) {
      const newObject = { from: "", to: "" };
      setAlldata((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          times: [...newData[index].times, newObject],
        };
        return newData;
      });
    }
  }, [buttonClicked, index, setAlldata, data]);

  const handleFistTime = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
    } else if (data?.times?.length <= 0) {
      setButtonClicked(false);
    }
  };

  const handleToggle = () => {
    setToggle((prev) => !prev);
    setAvailability();
    handleFistTime();
  };

  function handleRemove(itemIndex) {
    if (data.times.length <= 1) {
      setToggle(false);
      setAvailability();
    }
    setAlldata((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].times.splice(itemIndex, 1); // Remove one item at the specified index
      return newItems;
    });
  }

  function handleAddTime() {
    const newObject = { from: "", to: "" };
    setAlldata((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        times: [...newData[index].times, newObject],
      };
      return newData;
    });
  }

  return (
    <div
      className={`flex tablet:border border-[#E2E4E9] tablet:rounded-[12px] py-4 tablet:p-4 gap-4 tablet:flex-row ${
        toggle ? "justify-center flex-col" : "justify-between flex-row"
      } tablet:justify-between items-center tablet:items-start`}
    >
      <div
        className={`flex ${
          toggle ? "w-full" : "w-fit"
        } tablet:w-fit justify-start gap-4 items-center`}
      >
        <Switch className="" checked={toggle} onCheckedChange={handleToggle} />
        <p className="capitalize text-base text-[#0A0D14] font-bold ">{data?.day}</p>
      </div>
      <div className={`${toggle ? "w-full" : "w-fit"}  tablet:w-fit`}>
        {toggle ? (
          <div>
            <div className="space-y-4">
              {data.times.map((item, idx) => (
                <SelectDates
                  allData={allData}
                  setAlldata={setAlldata}
                  key={idx}
                  data={data}
                  date={item}
                  timeIndex={idx}
                  dataIndex={index}
                  handleRemove={() => handleRemove(idx)}
                />
              ))}
            </div>
            <div className="w-full flex mt-4 justify-end">
              <button
                onClick={handleAddTime}
                className="flex gap-1 justify-center items-center rounded-[50px] border border-[#1A6A73] py-2 px-3"
              >
                <img src={add} className="w-5" alt="add" />
                <p className="font-semibold text-sm text-[#1A6A73]">Add</p>
              </button>
            </div>
          </div>
        ) : (
          <h2 className="font-semibold text-sm text-[#0A0D14]">Unavailable</h2>
        )}
      </div>
    </div>
  );
}


function SelectDates({
  allData,
  setAlldata,
  data,
  date,
  timeIndex,
  dataIndex,
  handleRemove,
}) {
  // Helper function to convert 24-hour format to 12-hour format with AM/PM
  const convertTo12HourFormat = (time24) => {
    return moment(time24, "HH:mm").format("hh:mm A");
  };

  // Helper function to convert 12-hour format with AM/PM back to 24-hour format
  const convertTo24HourFormat = (time12) => {
    return moment(time12, "hh:mm A").format("HH:mm");
  };

  const setFromTimeHandler = (value) => {
    const newFromTime = convertTo24HourFormat(value);
    const newToTime = convertTo24HourFormat(date.to);

    // Check if new "from" time is less than "to" time and not equal to it
    if (newToTime && moment(newFromTime, "HH:mm").isSameOrAfter(moment(newToTime, "HH:mm"))) {
      toast.error("From time must be less than To time and cannot be the same.");
      return;
    }

    setAlldata((prev) => {
      const currentData = [...prev];
      const editedTime = currentData[dataIndex];

      const updatedTimes = editedTime.times.map((time, idx) => {
        if (idx !== timeIndex) return { ...time };
        return {
          ...time,
          from: value,
        };
      });

      currentData[dataIndex] = {
        ...editedTime,
        times: updatedTimes,
      };
      return currentData;
    });
  };

  const setToTimeHandler = (value) => {
    const newToTime = convertTo24HourFormat(value);
    const newFromTime = convertTo24HourFormat(date.from);

    // Check if new "to" time is greater than "from" time and not equal to it
    if (newFromTime && moment(newToTime, "HH:mm").isSameOrBefore(moment(newFromTime, "HH:mm"))) {
      toast.error("To time must be greater than From time and cannot be the same.");
      return;
    }

    setAlldata((prev) => {
      const currentData = [...prev];
      const editedTime = currentData[dataIndex];

      const updatedTimes = editedTime.times.map((time, idx) => {
        if (idx !== timeIndex) return { ...time };
        return {
          ...time,
          to: value,
        };
      });

      currentData[dataIndex] = {
        ...editedTime,
        times: updatedTimes,
      };
      return currentData;
    });
  };

  // Generate time slots in 12-hour format
  const timeSlots = useMemo(
    () =>
      Array.from({ length: 24 }, (_, i) => moment({ hour: i, minute: 0 }).format("hh:mm A")).reduce(
        (acc, time) => {
          acc.push(time);
          acc.push(moment(time, "hh:mm A").add(30, "minutes").format("hh:mm A"));
          return acc;
        },
        []
      ),
    []
  );

  return (
    <div className="w-full tablet:w-fit flex justify-center items-center">
      <div className="flex w-[84%] tablet:w-fit flex-col justify-center items-center gap-4 tablet:flex-row">
        {/* From Time Selector */}
        <div className="flex w-full tablet:w-[185px]">
          <Select
            onValueChange={(value) => setFromTimeHandler(value)}
            value={date?.from?.length > 0 ? date.from : ""}
          >
            <SelectTrigger className="text-[#525866] w-[63%] lg:w-full font-medium text-sm rounded-l-[10px] rounded-r-[0px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
              <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                <img className="w-5" src={timeImg} alt="time" />
                <SelectValue placeholder="Select time" />
              </div>
            </SelectTrigger>
            <SelectContent className="max-h-[165px]">
              {timeSlots?.map((time) => (
                <SelectItem key={time} value={time}>
                  {time}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* To Time Selector */}
        <h2 className="text-[#0A0D14] font-semibold text-sm uppercase hidden tablet:inline">
          To
        </h2>
        <div className="flex w-full tablet:w-[185px]">
          <Select
            onValueChange={(value) => setToTimeHandler(value)}
            value={date?.to?.length > 0 ? date.to : ""}
          >
            <SelectTrigger className="text-[#525866] w-[63%] lg:w-full font-medium text-sm rounded-l-[10px] rounded-r-[0px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
              <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                <img className="w-5" src={timeImg} alt="time" />
                <SelectValue placeholder="Select time" />
              </div>
            </SelectTrigger>
            <SelectContent className="max-h-[165px]">
              {timeSlots?.map((time) => (
                <SelectItem key={time} value={time}>
                  {time}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Remove Button for Tablet */}
        <button
          onClick={() => handleRemove()}
          className="size-[24px] hidden tablet:flex justify-center items-center border rounded-[6px] border-[#E2E4E9]"
        >
          <img src={remove} alt="remove" className="w-5" />
        </button>
      </div>

      {/* Remove Button for Mobile */}
      <div className="h-[56px] w-[16%] flex justify-center items-center tablet:hidden border-t-2 border-b-2 border-r-2 border-[#E2E4E9] rounded-r-[10px] ">
        <button onClick={handleRemove}>
          <img src={remove} alt="remove" className="w-6" />
        </button>
      </div>
    </div>
  );
}
