import React, { useState, useEffect } from 'react'
import { ConversationCard } from '../components/Messages/conversationcard'
import { motion } from "framer-motion";
import { useGetConversationsQuery, useShowConversationDetailsQuery } from '../services/messagesSlice';
import { FormLoader } from '@/Components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { useMediaQuery } from '../components/hooks/useMediaQuery';
import { apiSlice } from '../api/apiSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { toast } from 'sonner';
import CreateMessageIcon from '../../assets/Assets/Svgs/createmessage.svg'
import empty from "../Images/noWorksheet.png";
import searchIcon from "..//Images/search.png";
import ChatBox from '../components/Messages/chatbox';
import NewMessageSearch from '../components/Messages/newmessagesearch';

export const messageContainerVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };

export const messageVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.2,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.2,
      },
    },
  };

  
  const Messages = () => {

    const isMobile = useMediaQuery("(max-width: 1024px)");
    // const isLaptop = useMediaQuery("(max-width: 1024px)");

    const { state:convoId } = useLocation(); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser);
    const [searchConversation, setSearchConversation] = useState('')
    
    const prefetchClientList = () => dispatch(apiSlice.endpoints.getClientList.initiate());
    const { data:conversations, isLoading, isFetching, refetch:refetchConvos } = useGetConversationsQuery({
      search: searchConversation
    });
    const { data:convoDetails, isLoading:detailsLoading, isError, error } = useShowConversationDetailsQuery(convoId ?? skipToken);
    const sortedConversations = conversations && conversations?.data?.slice().sort((a, b) => {
      return new Date(b.last_message.updated_at).getTime() - new Date(a.last_message.updated_at).getTime()
    });

    const [currentChat, setCurrentChat] = useState(convoDetails && (convoDetails?.data ?? null));
    const [showNewChatSearch, setShowNewChatSearch] = useState(false);

    let switchView = (currentChat !== null && currentChat !== "undefined"&& currentChat !== undefined) && isMobile === true;

    useEffect(() => {
      convoDetails && setCurrentChat(() => convoDetails?.data);
    },[convoDetails, convoId])

    useEffect(() => {
      const handleKeyDown = (e) => {
        if(e.key === 'Escape'){
          setCurrentChat(() => null) 
        }
      }
      document.addEventListener("keydown", handleKeyDown);
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    },[]);

    useEffect(() => {
      if(isError && error !== "undefined") {
        toast.error("You cannot message this client");
        navigate('/dashboard/messages', { replace: true })
      }
    },[isError]);
    console.log(currentChat)

  return (
    <motion.div
      key="chatbox"
      variants={messageContainerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      className="w-full overflow-x-scroll"
    >
        <main className='w-full flex items-center justify-center bg-white border-[1px] border-[#E2E4E9] rounded-xl h-[650px] max-h-[700px] divide-x divide-gray-200 overflow-hidden'>

            <section className={`w-full h-full md:w-1/3 flex items-start flex-col gap-3 overflow-auto no-scrollbar ${ !switchView && isMobile ? 'md:w-full' : ''  } ${ switchView ? 'hidden' : 'block' }`}>
                <header className='w-full flex items-start flex-col gap-3 sticky top-0 bg-white z-10 p-6'>
                    <div onMouseEnter={() => prefetchClientList()} className='w-full flex items-center justify-between relative'>
                        <p className='text-3xl text-[#101828] font-semibold'>Messages</p>
                        <img onClick={() => setShowNewChatSearch((prev) => !prev)} className='cursor-pointer' src={CreateMessageIcon} alt='createmessage_icon' />
                        {
                          showNewChatSearch ?
                          <div className='w-full text-white h-60 absolute top-0 left-0 z-40'>
                              <NewMessageSearch 
                                user={user} 
                                closeModal={() => setShowNewChatSearch((prev) => !prev)}
                                setCurrentChat={setCurrentChat}
                              />
                          </div>
                          :
                          null
                        }
                    </div>
                    <div className="h-[36px] relative w-full">
                        <img
                            src={searchIcon}
                            alt="search"
                            className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
                        />
                        <input
                            style={{
                            outline: "none",
                            border: "1px solid #E2E4E9",
                            boxShadow: "none",
                            }}
                            value={searchConversation}
                            onChange={(e) => setSearchConversation(e.target.value)}
                            type="search"
                            placeholder="Search"
                            className="size-full w-full text-sm rounded-[8px] py-2 pl-9 pr-8"
                        />
                    </div>
                </header>
                
                {/* conversation card list here */}
                <section className='w-full flex flex-col gap-1 px-6 overflow-auto'>
                    {
                        isLoading || (isFetching && searchConversation?.length > 0) ?
                        <div className='flex items-center justify-center flex-col m-auto w-full h-full p-6 overflow-y-auto'>
                            <div>
                                <FormLoader />
                            </div>
                            <p className="mt-3 text-sm text-[#868C98] text-center">
                                Loading your chats...
                            </p>
                        </div>
                        :
                        !conversations?.data.length ?
                        <div className='flex items-center justify-center m-auto w-full h-full p-6 overflow-y-auto'>
                            <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                                <img src={empty} className="w-[108px]" alt="empty" />
                                <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                                    No chats. <br />
                                </p>
                            </div>
                        </div>
                        :
                        sortedConversations?.map((convo) => (
                          <ConversationCard
                            chatId={convo?.last_message?.conversation_id} 
                            key={convo.last_message?.conversation_id}
                            read={convo?.last_message?.read}
                            lastMessage={convo.last_message?.message}
                            name={convo.user_id?.name}
                            onClick={() => setCurrentChat(() => convo)}
                            activeConvo={currentChat && currentChat?.last_message?.conversation_id === convo?.last_message?.conversation_id}
                            avatar={convo?.user_id?.avatar}
                            time={convo?.last_message?.updated_at}
                          />
                        ))
                    }
                </section>
            </section>

            {/* ChatBox here */}
            <section className={`w-full h-full md:w-2/3 ${ switchView && 'w-full md:w-full block' } ${ !switchView && isMobile ? "hidden" : "block" }`}>
                <ChatBox
                  user={user}
                  currentChat={currentChat}
                  setShowNewChatSearch={setShowNewChatSearch}
                  refetchConvos={refetchConvos}
                  detailsLoading={detailsLoading}
                />
            </section>
        </main>
    </motion.div>
  )
}

export default Messages