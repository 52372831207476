import { FormLoader } from '@/Components/loader'
import { AlertDialog, AlertDialogContent } from '@/Components/ui/alert-dialog'
import React from 'react'

const ConnectionScreen = () => {
  return (
    <div>
      <AlertDialog open={true}>
        <AlertDialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="min-w-full bg-[#000000] bg-opacity-10 backdrop-blur-sm p-0 !rounded-none h-screen overflow-y-scroll"
        >
          <main className='flex items-center justify-center m-auto p-12 text-[#FFFFFF]'>
            <section className='w-full flex flex-col items-center justify-center gap-3'>
                <p className='text-center'>It seems there might be an issue with your network,{" "} hold on while we try to get you back in</p>
                <div className='flex flex-col items-center justify-center gap-1'>
                    <div className='flex items-center justify-center m-auto relative w-full h-full p-6 overflow-y-auto'>
                        <div className="w-full  flex-col my-auto flex justify-center items-center">
                            <FormLoader />
                        </div>
                    </div>
                    <span>Reconnecting...</span>
                </div>
            </section>
          </main>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default ConnectionScreen