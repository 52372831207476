import { Link } from "react-router-dom";
import backIcon from "../Images/back-chevron.png";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import active from "../Images/active_setting.png";
import availImg from "../Images/time-aval.png";
import blockImg from "../Images/calendar-block.png";


import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";

function Availability() {
  const navigate = useNavigate();
  const [select, setSelect] = useState("");
  useEffect(() => {
    const path = window.location.pathname;
    const value = path.split("/");
    setSelect(value[value.length - 1]);
  }, []);

  // const navigate = useNavigate();
  function handleChange(value) {
    setSelect(value);
    navigate(value);
  }

  return (
    <div>
      <div className="w-full flex justify-start">
        <Link to="/dashboard/appointments">
          <button className="flex bg-[#20232D] items-center justify-center gap-1 rounded-[50px] text-white py-[10px] pl-2 pr-4">
            <img src={backIcon} className="w-5" alt="back" />
            <p className="text-sm font-semibold ">Back</p>
          </button>
        </Link>
      </div>
      <div className="mt-5 mb-12">
        <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          Manage Availability
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[580px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          Update your availability to schedule sessions with clients. Set your
          working hours, days off, and any exceptions to your regular schedule.
        </p>
      </div>
      <Select
        onValueChange={(value) => handleChange(value)}
        defaultValue={select}
        value={select}
      >
        <SelectTrigger className=" mb-3 px-[10px] laptop:hidden w-[230px] focus:ring-offset-0 focus:ring-0 h-[45px] font-semibold text-[#525866] ">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className=" ">
          <SelectGroup className=" *:py-3 ">
            <SelectItem value="availability_hours">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={availImg} alt="availImg" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-meduim">
                  Availability hours
                  </p>
                </div>
              </div>
            </SelectItem>

            <SelectItem value="blocked_dates">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={blockImg} alt="blocked" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-meduim">
                    Blocked out dates
                  </p>
                </div>
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="w-full flex justify-center items-start gap-[3%] ">
        <div className="w-[30%] laptop:w-[22%] bg-white px-[10px] pt-[10px] pb-3 rounded-[16px] border hidden laptop:flex flex-col justify-start items-start border-[#E2E4E9]">
          <h2 className="text-[#868C98] px-2 pt-2 font-medium text-[12px] leading-4 uppercase">
            select menu
          </h2>
          <div className="mt-3 w-full space-y-2">
            <NavLink
              onClick={() => setSelect("availability_hours")}
              to="availability_hours"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={availImg} alt="availImg" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Availability Hours
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
            <NavLink
              onClick={() => setSelect("blocked_dates")}
              to="blocked_dates"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={blockImg} alt="blocked" className="w-[18px]" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Blocked Out Dates
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
          </div>
        </div>
          <div className=" w-full  laptop:w-[75%] bg-white border border-[#E2E4E9] p-4 tablet:px-6  laptop:px-5 rounded-[10px] laptop:rounded-[16px]">
            <Outlet />
          </div>
      </div>
    </div>
  );
}

export default Availability;
