import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
// import SignupFormSeven from "./SignupFormSeven";
import { useSignUpContext } from "./SignUpContext";

const SignupFormSix = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(7);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.interest_in_mentra);
  const [filled, setFilled] = useState(true);

  const handleClick = () => {
    if (option) {
      setSignupData((prevData) => ({
        ...prevData,
        interest_in_mentra: option,
      }));
      setStep(8);
    } else {
      setFilled(false);
    }
  };

  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col shadow-lg pb-8 items-center h-[100%] bg-white tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] rounded-[20px] mt-3 z-1   lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
            What makes you interested in Mentra?
          </h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className="w-full">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>
          <div className="flex flex-col justify-center items-center gap-4 mb-2 w-full">
            <button
              value="Build my online practice"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Build my online practice"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Build my online practice
            </button>
            <button
              value="Supplement my full-time employment"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Supplement my full-time employment"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Supplement my full-time employment
            </button>
            <button
              value="Supplement my freelance employment"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Supplement my freelance employment"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Supplement my freelance employment
            </button>
            <button
              value="Supplement my part-time job"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Supplement my part-time job"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Supplement my part-time job
            </button>
            <button
              value="Other"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Other" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Other
            </button>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormSix;
