import pass from "../../../Images/pass.png";
import fail from "../../../Images/fail.png";
import updn from "../../../Images/updn.png";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/Components/ui/table";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function ClientWorksheetTable({ currentPageData, handleTableSort, handleProfile }) {

  const navigate = useNavigate();
  console.log(currentPageData)
  return (
    <Table className="">
      {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
      <TableHeader className="bg-[#F6F8FA] hover:bg-[#F6F8FA]">
        <TableRow className="*:text-[#525866]">
          <TableHead className=" w-[30%] tablet:w-[30%]">
            <div
              onClick={() => handleTableSort("name")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">
                Worksheet name
              </p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left">
              <div
                onClick={() => handleTableSort("client")}
                className="flex cursor-pointer items-center justify-start gap-1"
              >
                <p className="text-sm capitalize text-[#525866]">Type</p>
                <img src={updn} alt="sort" className="h-5" />
              </div>
            </TableHead>

          <TableHead className="text-left">
            <div
              onClick={() => handleTableSort("client")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Assigned to</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left">
            <div
              onClick={() => handleTableSort("date")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Date Added</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left w-[100px]">
              <div
                onClick={() => handleTableSort("status")}
                className="flex cursor-pointer items-center justify-start gap-1"
              >
                <p className="text-sm capitalize text-[#525866]">Progress</p>
                <img src={updn} alt="sort" className="h-5" />
              </div>
            </TableHead>
          <TableHead className="text-left w-[100px]">
            <div
              onClick={() => handleTableSort("status")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">status</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {!currentPageData.length <= 0 ? (
          currentPageData.map((data) => (
            <TableRow
              onClick={() => navigate(`worksheet`, { state: data } )}
              className="*:min-w-[150px] cursor-pointer"
              key={data.id}
            >
              <TableCell className="font-semibold text-[#0A0D14]  capitalize">
                {data.title}
              </TableCell>
              <TableCell className="font-semibold text-[#0A0D14]  capitalize">
                  {data.type === 'registration-form' ? "Questionnaire" : data.type}
              </TableCell>

              <TableCell className="text-left text-[#0A0D14] font-medium">
                <div className="">
                  {data.user ? (
                    <div className="flex justify-start capitalize items-center gap-3">
                      <img src={data.user.avatar} className="w-10" alt="avatar" />
                      <p>{data.user.name}</p>
                    </div>
                  ) : (
                    <button className="bg-[#C4E4E9] rounded-[50px] py-[6px] z-30 px-3 text-[#1A6A73] font-medium text-sm">
                      Assign
                    </button>
                  )}
                </div>
              </TableCell>
              <TableCell className="text-left text-[#0A0D14] font-medium">
                {data.created_at ? moment(data.created_at).format("ddd, MMMM Do YYYY, LT") : "----------"}
              </TableCell>
              <TableCell className="font-semibold text-[#0A0D14]  capitalize">
                  {`${data.stats.answered}/${data.stats.total}`}
                </TableCell>
              <TableCell className="text-left text-[#0A0D14] font-medium">
                <div className="flex justify-center items-center w-fit  gap-1 capitalize border boder-[#E2E4E9] rounded-[6px] py-1 pr-2 pl-1">
                  <img
                    src={
                      data?.status?.toLowerCase() === "active" ? pass : data?.status?.toLowerCase() === "completed" ? pass : fail
                    }
                    className="w-4"
                    alt="status"
                  />
                  <p>{data.status}</p>
                </div>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow className="w-full grid place-items">
            <p className="text-[#0A0D14]  text-center text-xl font-semibold capitalize mt-8">
              No Worksheets found!
            </p>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default ClientWorksheetTable;
