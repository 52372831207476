import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetClientDetailsQuery } from '../services/clientApiSlice';
import { useGetWorksheetDetailsQuery } from '../services/worksheetApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { messageVariants } from './Messages';
import { motion } from 'framer-motion';
import Loader from '@/Components/loader';
import backIcon from "../Images/back-chevron.png";
import gradient from "../Images/gradient.png";
import pass from "../Images/pass.png";
import moment from 'moment';
import empty from "../Images/noWorksheet.png";
import * as Icon from 'react-feather'
import ProfileNewSession from '../components/modals/NewSessions/profileNewSession';
import GreenTick from '../../../src/assets/Assets/Svgs/greencheck.svg'
import CancelIcon from '../../../src/assets/Assets/Svgs/redx.svg'


const WorksheetDetails = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();

    const [newSession, setNewSession] = useState(false)
    
    const { data:details, isSuccess } =  useGetClientDetailsQuery(id);
    const { data:sheetDetails, isLoading } = useGetWorksheetDetailsQuery(state?.id ?? skipToken);

  return (
    <div>
        <ProfileNewSession
            openModal={newSession} 
            setModal={setNewSession} 
            client={details?.data}
        />
        <div className="w-full flex justify-start">
        <div onClick={() => navigate(-1)}>
          <button className="flex bg-[#20232D] items-center justify-center gap-1 rounded-[50px] text-white py-[10px] pl-2 pr-4">
            <img src={backIcon} className="w-5" alt="back" />
            <p className="text-sm font-semibold ">Back</p>
          </button>
        </div>
      </div>
      <div className="mt-9 flex flex-col justify-center items-start gap-6 laptop:flex-row laptop:min-h-[500px] laptop:gap-[3%]">
        <div className="bg-white  border rounded-[16px] max-w-[500px] laptop:min-h-[478px] tablet:max-w-full laptop:w-[28%] border-[#E2E4E9] pb-6 tablet:pb-7 laptop:pb-7 h-fit w-full">
          <div className="h-[142px] relative  w-full ">
            <div className="h-[50%] rounded-tr-[16px] overflow-hidden bg-transparent rounded-tl-[16px] ">
              <img src={gradient} className="w-full" alt="fg" />
            </div>
            {
              isSuccess ?
              <img
                // style={{ backgroundColor: `${details?.data?.user?.avatar_background_color}` }}
                src={details?.data?.user?.avatar}
                alt="avatar"
                className="w-[87px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
              />
              :
              null
            }
          </div>
          <div className="w-full text-center">
            <div className="flex justify-center items-center gap-3 mt-[-15px] w-full">
              <h2 className="text-2xl font-bold capitalize text-[#0A0D14]">
                {details?.data?.user?.name ?? '--'}
              </h2>
              <span className="size-[10px] bg-[#38C793] rounded-[100%]"></span>
            </div>
            <p className="font-medium text-base text-[#525866] pt-1">{details?.data?.user?.birth_year ? moment().diff(moment(`${details?.data?.user?.birth_year +'-01-01'}`, "YYYYMMDD"), 'years') : '--'}</p>
            <div className="flex justify-center items-center gap-3 mt-4">
              <button
                onClick={() => setNewSession(true)}
                className="rounded-[50px] bg-[#1A6A73] text-sm text-white py-[10px] font-semibold px-4"
              >
                Book Session
              </button>
              <button className="rounded-[50px] border border-[#E2E4E9] text-[#525866] text-sm  py-[10px] font-semibold px-4">
                Send Message
              </button>
            </div>
          </div>
          <div className="mt-6 w-[86%] laptop:w-[86%] tablet:w-full px-0 laptop:px-0 tablet:px-6 flex flex-col items-center tablet:gap-[3%] laptop:flex-col justify-center tablet:flex-row mx-auto space-y-4 tablet:space-y-0 laptop:space-y-4">
            <div className="w-full tablet:w-[48%] laptop:w-full rounded-[8px] py-3 px-3 bg-[#F6F8FA]">
              <div className="flex justify-between items-center gap-1">
                <div className="flex justify-center items-center gap-1">
                  <img src={pass} className="w-5" alt="check" />
                  <p className="leading-[18px] text-[13px] text-[#868C98]">
                    Next session
                  </p>
                </div>
                <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#FBDFB1] rounded-[50px] px-2 py-[2px]">
                  {details?.data?.next_session?.status}
                </p>
              </div>
              <h3 className="text-[15px] leading-6 font-medium pt-[5px] pl-[23px] text-[#0A0D14]">
               { details?.data?.next_session ? moment(details?.data?.next_session?.starts_at).format("MMMM Do YYYY") : '--'}
              </h3>
            </div>
            <div className="w-full tablet:w-[48%] laptop:w-full  rounded-[8px] py-3 px-3 bg-[#F6F8FA]">
              <div className="flex justify-between items-center gap-1">
                <div className="flex justify-center items-center gap-1">
                  <img src={pass} className="w-5" alt="check" />
                  <p className="leading-[18px] text-[13px] text-[#868C98]">
                    Last session
                  </p>
                </div>
                <p className="text-[12px] leading-4 font-medium text-[#176448] bg-[#CBF5E5] rounded-[50px] px-2 py-[2px]">
                  {details?.data?.last_session?.status}
                </p>
              </div>
              <h3 className="text-[15px] leading-6 font-medium pt-[5px] pl-[23px] text-[#0A0D14]">
                {moment(details?.data?.last_session?.starts_at).format("MMMM Do YYYY") ?? '--'}
              </h3>
            </div>
          </div>
        </div>
        {/* Worksheet questions */}
        <div className="w-full bg-transparent laptop:w-[69%]  rounded-xl">
            <div
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                className="min-w-full p-0 border-none outline-none ring-0 rounded-xl overflow-auto space-y-6"
            >
                <div className="relative w-full min-h-full">
                    <div className=" p-4 duo:p-6 w-full bg-white rounded-[12px] ">
                        <h2 className="font-semibold text-lg leading-[22px] text-[#0A0D14] mb-5">
                            {sheetDetails?.data?.title ?? "No title"}
                        </h2>
                        <p className="text-sm text-[#525866]">
                            {sheetDetails?.data?.description ?? "No description"}
                        </p>
                    </div>
                </div>
                <div className={`p-4 duo:p-6 w-full bg-white rounded-[12px] no-scrollbar ${ sheetDetails?.data && !Object.keys?.(sheetDetails?.data).includes('questions') ? 'hidden' : 'block'}`}>
                    <h2 className="text-[#0A0D14] font-medium text-base">
                      Worksheet Questions
                    </h2>

                    {
                        isLoading ?
                        <Loader />
                        :
                        !Object.keys(sheetDetails?.data).includes('questions') || !sheetDetails?.data?.questions?.length ?
                        <div className="w-full">
                            <div className="w-full  flex-col my-auto laptop:min-h-[292px] mt-6 flex justify-center items-center">
                            <img src={empty} className="w-[108px]" alt="empty" />
                            <p className="mt-3 text-sm text-[#868C98] text-center">
                                No Worksheets Questions Available. <br />
                                Start creating worksheets to tailor therapy sessions to your
                                client's needs
                            </p>
                            </div>
                        </div>
                        :
                        <div className="mt-2 space-y-4 max-h-[500px] overflow-auto">
                            { sheetDetails?.data?.questions && sheetDetails?.data?.questions?.map((item, index) => (
                                <div
                                    key={index}
                                    className="border border-[#E2E4E9] rounded-[10px] flex justify-start items-start gap-2 duo:gap-4 p-[14px] "
                                >
                                    <div className="h-full flex justify-start items-start">
                                        <p className="text-base text-[#0A0D14] font-meduim">
                                        {index + 1}.
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className="text-[#0A0D14] text-base font-bold pb-2">
                                        {item.question}
                                        </h2>
                                        <p className="text-sm text-[#525866]">
                                        {item.answer ?? 'No answer yet...'}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>

                {/* Weekly tasks and questions */}
                <div className={`p-4 duo:p-6 w-full bg-white rounded-[12px] no-scrollbar ${ sheetDetails?.data && !Object.keys(sheetDetails?.data).includes('weekly_tasks') ? 'hidden' : 'block'}`}>
                    <h2 className="text-[#0A0D14] font-medium text-base mb-8">
                      Weekly Tasks
                    </h2>

                    {
                        isLoading ?
                        <Loader />
                        :
                        !Object.keys(sheetDetails?.data).includes('weekly_tasks') || !sheetDetails?.data?.weekly_tasks?.length ?
                        <div className="w-full">
                            <div className="w-full  flex-col my-auto laptop:min-h-[292px] mt-6 flex justify-center items-center">
                            <img src={empty} className="w-[108px]" alt="empty" />
                            <p className="mt-3 text-sm text-[#868C98] text-center">
                                No Worksheets Questions Available. <br />
                                Start creating worksheets to tailor therapy sessions to your
                                client's needs
                            </p>
                            </div>
                        </div>
                        :
                        <div className="w-full mt-2 space-y-8 max-h-[500px] overflow-auto no-scrollbar">
                            { sheetDetails?.data?.weekly_tasks && sheetDetails?.data?.weekly_tasks?.map((item, index) => (
                                <WeeklyCard
                                  key={index}
                                  item={item} 
                                  index={index} 
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
        {/* end of worksheet questions */}
      </div>
    </div>
  )
}

export default WorksheetDetails

const WeeklyCard = ({ item, index }) => {

    const [showTask, setShowTask] = useState(false);
    
    const morningTasks = item?.tasks?.filter((time) => time.time === 'morning')
    const afternoonTasks = item?.tasks?.filter((time) => time.time === 'afternoon')
    const eveningTasks = item?.tasks?.filter((time) => time.time === 'evening')

    return (
        <div key={index} className='flex flex-col items-start cursor-pointer gap-2'>
            <header onClick={() => setShowTask((prev) => !prev)} className='w-full flex items-center gap-2'>
                <Icon.ChevronDown color='gray' size={15} />
                <p className='text-md uppercase font-medium'>{item?.day} <span className='test-xs text-[#999191]'>({item.tasks?.length})</span></p>
            </header>
            {
              showTask ? 
              <motion.div
                key="tasks"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={messageVariants} 
                className='flex items-start justify-between w-full gap-3'
              >
                <TimeCard time="Morning" tasks={morningTasks} />
                <TimeCard time="Afternoon" tasks={afternoonTasks}  />
                <TimeCard time="Evening" tasks={eveningTasks}  />
              </motion.div> 
              :
              null
            }       
        </div>
    )
}

const TimeCard = ({ time, tasks }) => {
  return (
    <div className='w-full flex flex-col items-start gap-6 rounded-lg px-2 py-4 bg-[#F6F8FA] min-w-[200px] flex-1'>
      <span className=''>{time}</span>
      <section className='w-full flex flex-col gap-3'>
        {
          !tasks?.length ?
          <div className='w-full flex items-center gap-2 p-2 bg-white rounded-lg border boreder-[#0F0F0F0D]'>
            <span className='text-[#ada5a5]'>No task</span>
          </div>
          :
          tasks?.map((task) => (
            <div className='w-full flex items-center gap-2 p-2 bg-white rounded-lg border boreder-[#0F0F0F0D]'>
              { task.task && task?.completed ? <img src={GreenTick} /> : <img src={CancelIcon} /> }
              <span className='whitespace-nowrap'>{task?.task ?? "No task"}</span>
            </div>
          ))
        }
      </section>
    </div>
  )
}