import React from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../services/authSlice";

const Protected = ({ path="/login", children }) => {

    const token = useSelector(selectCurrentToken);
    const location = useLocation();

    return (
        token ? 
        <Outlet/>: 
        <Navigate to = { path } state= {{ from: location }} replace />
    )
}
export default Protected;