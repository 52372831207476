import { apiSlice } from "../api/apiSlice"

export const clientApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientList: builder.query({
            query: ({search=''}) => ({
                url: `/therapist/client/get-clients?search=${search}`,
                method: "get",
            }),
            providesTags: ['clients']
        }),
        getClientDetails: builder.query({
            query: id => ({
                url: `/therapist/client/get-clients-details/${id}`,
                method: "get",
            })
        }),
        getClientSessions: builder.query({
            query: ({id, status}) => ({
                url: `therapist/client/get-clients-sessions/${id}?status=${status}` ,
                method: "get",
            })
        }),
        getClientWorksheets: builder.query({
            query: id => ({
                url: `/therapist/client/get-clients-worksheet/${id}` ,
                method: "get",
            })
        }),
    })
})

export const {
    useGetClientListQuery,
    useGetClientDetailsQuery,
    useGetClientSessionsQuery,
    useGetClientWorksheetsQuery,
} = clientApiSlice