import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { fetchTermsAndConditions } from "../Api";
import Title from "../Components/Title";
import { motion } from "framer-motion";
import Loader from "../Components/loader";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fetchTAndC = async () => {
      try {
        const response = await fetchTermsAndConditions();
        const data = response.data;
        setTermsAndConditions(data.data.body); // Adjust the property name based on the actual response structure
      } catch (error) {
        console.error("Error fetching terms and conditions:", error.message);
      }
    };

    fetchTAndC();
  }, []);

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <div>
      <Title title={"T&Cs"} />
      <div className="pt-[100px] pb-10 laptop:pt-[9%]  px-[5vw] laptop:px-[5%]">
        <h1 className="text-center text-[32px] font-bold mt-[3%] mb-16">
          Terms and Conditions
        </h1>
        {!termsAndConditions ? (
          <Loader />
        ) : (
          <motion.div
            key="terms"
            variants={variants}
            initial="initial"
            animate="animate"
            className="mx-0 laptop:mx-[15%]"
            dangerouslySetInnerHTML={{ __html: termsAndConditions }}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
