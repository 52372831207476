import { BlobServiceClient } from '@azure/storage-blob';


const sasToken = import.meta.env.VITE_STORAGE_SAS_TOKEN;
const containerName = import.meta.env.VITE_STORAGE_CONTAINER_NAME;
const storageAccountName = import.meta.env.VITE_STORAGE_ACCOUNT_NAME;

let recording_metadata = [];

const getBlobsInContainer = async (containerClient) => {
    try {
        const returnedBlobUrls = [];

        // eslint-disable-next-line
        for await (const blob of containerClient.listBlobsFlat()) {
            // if image is public, just construct URL
            returnedBlobUrls.push(
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
            );
        }
        recording_metadata.push(returnedBlobUrls)
        console.log(recording_metadata)
        // TODO Write code for storing data in db
        return returnedBlobUrls;
    } catch (error) {
        console.log("ERROR while get blob", error)
        return error
    }
};


const createBlobInContainer = async (containerClient, file) => {
    try {


        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(file.name);


        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };

        // upload file
        await blobClient.uploadBrowserData(file, options);
        console.log(file)
        await blobClient.setMetadata({ Therapist: String(file.therapist_id) , Patient:String(file.patient_id) });
        recording_metadata.push(Number(file.name))
        recording_metadata.push(file.therapist_id)
        recording_metadata.push(file.patient_id)
    } catch (error) {
        console.log("ERROR WHILE CREATE BLOB IN CTR", error)
        return error
    }
};

const uploadFileToBlob = async (file) => {
    try {
        console.log("file", file)
        if (!file) return [];

        // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
        const blobService = new BlobServiceClient(
            `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
        );
        // get Container - full public read access
        const containerClient = blobService.getContainerClient(containerName);

        // upload file
        await createBlobInContainer(containerClient, file);

        // get list of blobs in container
        return getBlobsInContainer(containerClient);
    } catch (error) {
        console.log("Error while uploading", error)
        return error
    }
};



export default uploadFileToBlob;
