import { motion } from "framer-motion";
import empty from "../../Images/noWorksheet.png";
import { useState, useMemo, useEffect } from "react";
import WorkSheetWrapper from "../modals/WorkSheetWrapper";
import { Link, useNavigate } from "react-router-dom";
import searchIcon from "../../Images/search.png";
import filter from "../../Images/filter.png";
import sort from "../../Images/sort.png";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import WorksheetTable from "./WorksheetComponents/WorksheetTable";
import PaginationComponent from "../ClientList/PaginationComponent";
import WorksheetOverview from "../ClientProfile/WorksheetOverview";
import { useGetWorksheetsQuery } from "@/App/services/worksheetApiSlice";
import Loader from "@/Components/loader";

function Worksheets() {
  const [openModal, setModal] = useState(false);
  const [openView, setView] = useState(false);
  const [search, setSearch] = useState("");
  const [sortCol, setSortCol] = useState("");
  const [asc, setAsc] = useState(true);
  // const [info, setInfo] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [tabLength, setTabLength] = useState(1);

  const { data: worksheets, isLoading } = useGetWorksheetsQuery({ search: search });

  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };

  const [sortedData, setSortedData] = useState(worksheets?.data);
  const totalPages = Math.ceil(worksheets?.data.length / itemsPerPage);

  useEffect(() => {
    setTabLength(totalPages);
  }, [ itemsPerPage, totalPages]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // const navigate = useNavigate();

  // const currentPageData = sortedData.slice(startIndex, endIndex);

  // useEffect(() => {
  //   setSortedData(searchedData);
  // }, [searchedData]);

  function handleSort(value) {
    let sortedDatas;
    if (asc) {
      if (value.toLowerCase() === "date") {
        sortedDatas = [...worksheets?.data].sort((a, b) => {
          const dateA = a[value] ? new Date(a[value]) : null; // Convert to Date object if not empty
          const dateB = b[value] ? new Date(b[value]) : null; // Convert to Date object if not empty
          if (!dateA && !dateB) return 0; // Both are empty
          if (!dateA) return 1; // Date A is empty, place it after
          if (!dateB) return -1; // Date B is empty, place it after
          return dateA - dateB;
        });

        // console.log(searchedData);
      } else {
        sortedDatas = [worksheets?.data].sort((a, b) => {
          // Check if both names are empty
          if (!a[value] && !b[value]) {
            return 0; // No change in order
          }
          // Check if name of 'a' is empty
          else if (!a[value]) {
            return 1; // 'a' goes after 'b'
          }
          // Check if name of 'b' is empty
          else if (!b[value]) {
            return -1; // 'a' goes before 'b'
          }
          // Both names are not empty, perform normal comparison
          else {
            return a[value].localeCompare(b[value]);
          }
        });
      }
    } else {
      if (value.toLowerCase() === "date") {
        sortedDatas = [worksheets?.data].sort((a, b) => {
          const dateA = a[value] ? new Date(a[value]) : null; // Convert to Date object if not empty
          const dateB = b[value] ? new Date(b[value]) : null; // Convert to Date object if not empty
          if (!dateA && !dateB) return 0; // Both are empty
          if (!dateA) return -1; // Date A is empty, place it after
          if (!dateB) return 1; // Date B is empty, place it after
          return dateB - dateA;
        });
      } else {
        sortedDatas = [worksheets?.data].sort((a, b) => {
          // Check if both names are empty
          if (!a[value] && !b[value]) {
            return 0; // No change in order
          }
          // Check if name of 'a' is empty
          else if (!a[value]) {
            return -1; // 'a' goes before 'b'
          }
          // Check if name of 'b' is empty
          else if (!b[value]) {
            return 1; // 'a' goes after 'b'
          }
          // Both names are not empty, perform normal comparison
          else {
            return b[value].localeCompare(a[value]);
          }
        });
      }
    }

    console.log(sortedDatas);

    setSortedData(sortedDatas);
  }

  function handleTableSort(value) {
    if (value === sortCol) {
      setAsc(!asc);
    } else {
      setAsc(true);
    }
    setSortCol(value);
    handleSort(value);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  function handleProfile(data) {
    setView((prev) => !prev);
  }
  // console.log(info.length);

  return (
    <motion.div
      key="hello"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <WorkSheetWrapper openModal={openModal} setModal={setModal} />
      <WorksheetOverview openModal={openView} setModal={setView} />

      <div className="w-full bg-white border p-4 tablet:p-5 py-5 rounded-[16px] border-[#E2E4E9]">
        <div className="w-full flex  justify-between items-center">
          <h3 className="text-[#0A0D14] text-base font-semibold">Worksheets</h3>
          <button
            onClick={() => setModal(!openModal)}
            className="bg-[#1A6A73] font-medium text-white text-sm capitalize rounded-[50px] px-4 py-2"
          >
            new worksheet
          </button>
        </div>
          <div className="mt-8">
            <div className="w-full flex flex-wrap gap-3 justify-end items-center">
              <div className="h-[36px] relative w-full sixt:w-[49%] sixt:max-w-[396px]">
                <img
                  src={searchIcon}
                  alt="search"
                  className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
                />
                <input
                  style={{
                    outline: "none",
                    border: "1px solid #E2E4E9",
                    boxShadow: "none",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
                />
              </div>
              {/* <div className="flex w-full sixt:w-fit justify-between items-center gap-[4%] sixt:gap-3">
                <div className="flex w-[48%] sixt:w-fit bg-[#20232D] justify-center items-center rounded-[60px] cursor-pointer py-[9px] pl-[10px] pr-3 gap-1">
                  <img src={filter} className="w-5" alt="filter" />
                  <p className="text-white text-sm font-medium ">Filter</p>
                </div>
                <Select
                  value={sortCol}
                  onValueChange={(value) => handleTableSort(value)}
                >
                  <SelectTrigger className=" w-[48%] sixt:w-[140px] rounded-[8px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                    <SelectValue
                      placeholder={
                        <div className="flex justify-center items-center gap-2">
                          <img src={sort} alt="sort" className="w-5" />
                          <p className="text-sm text-[#525866] font-medium">
                            Sort by
                          </p>
                        </div>
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="name">Name</SelectItem>
                      <SelectItem value="client">Assigned To</SelectItem>
                      <SelectItem value="date">Date</SelectItem>
                      <SelectItem value="status">Status</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div> */}
            </div>
            {
              isLoading ?
              <div className="py-4">
                <Loader />
              </div>
              :
              !worksheets?.data.length ?
              <div className="w-full">
                <div className="w-full  flex-col my-auto laptop:min-h-[292px] mt-6 flex justify-center items-center">
                  <img src={empty} className="w-[108px]" alt="empty" />
                  <p className="mt-3 text-sm text-[#868C98] text-center">
                    No Worksheets Available. <br />
                    Start creating worksheets to tailor therapy sessions to your
                    clients' needs
                  </p>
                </div>
              </div>
              :
              <>
                <div
                  style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                  className="overflow-x-scroll w-full"
                >
                  <div className="mt-6 min-w-[900px]">
                    <WorksheetTable
                      currentPageData={worksheets?.data.slice(startIndex, endIndex) ?? []}
                      handleTableSort={handleTableSort}
                      handleProfile={handleProfile}
                    />
                  </div>
                </div>
              </>
            }
            <div className="w-full">
              <PaginationComponent
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                handlePageClick={handlePageClick}
                tabLength={tabLength}
              />
            </div>
          </div>
      </div>
    </motion.div>
  );
}

export default Worksheets;
