import logo from "../assets/Assets/Svgs/logoF.svg";
import AppStore from "../assets/Assets/apple.png";
import PlayStore from "../assets/Assets/play.png";
import facebook from "../assets/Assets/Svgs/facebook.svg";
import tiktok from "../assets/Assets/Svgs/tiktok.svg";
import instagram from "../assets/Assets/Svgs/instagram.svg";
import globe from "../assets/Assets/Svgs/globe.svg";
// import arrowDown from "../../public/Assets/Svgs/arrowDown.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Waitlist from "../Components/Waitlist";
import { AnimatePresence } from "framer-motion";
import FooterBG from '../assets/Assets/Images/footerBg.png'

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  return (
    <div id="footer" className="bg-[#FFFDF2]">
      <div
        className=" pb-6 tablet:pb-14 bg-cover bg-no-repeat m-0 pt-[40px] flex flex-col justify-center items-center tablet:px-[5vw] laptop:px-[15%]  max-[767px]:px-[5%] "
        style={{ backgroundImage: `url(${FooterBG})` }}
      >
        <div className="flex max-w-full flex-col justify-center items-center ">
          <h1 className="philosopher text-center laptop:text-[48px] duo:text-[34px] font-bold text-[24px]">
          Join the Mentra Community
          </h1>
          <p className="text-[16px] max-w-[80vw] tablet:max-w-[850px] laptop:max-w-fit text-center mt-[2%] mb-5 max-[767px]:text-[14px]">
          Ready to experience the transformative power of Mentra? Download our app now and gain instant access to personalized conversations, mental health coaches, guided journaling, and a wealth of self-care resources.Download our app now and gain instant access to personalized conversations, mental health coaches, guided journaling, and a wealth of self-care resources.
          </p>

          {/* <button
            onClick={openModal}
            className="bg-[#1A6A73]  text-white w-[160px] h-[45px] text-[16px] rounded-[30px] shadow-lg mx-auto tablet:text-[16px] tablet:w-[170px] laptop:w-[170px] font-normal laptop:text-[16px] laptop:h-[45px] tablet:h-[50px] mt-1 cursor pointer"
          >
            Waitlist here
          </button> */}

          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-4">
            <Link to="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={AppStore} className="w-48" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={PlayStore}   className="w-44"/>
            </Link>
          </div>
        </div>

        <div className="bg-[#002C3C]  thousand:rounded-[40px] w-[100%] thousand:mt-[5%] text-[#FAFAFA] thousand:px-[3%] thousand:py-[3%] flex thousand:flex-row flex-col mt-[10%] rounded-[20px] py-[5%] px-[5%]">
          <div className="thousand:w-[50%] w-[100%]">
            <Link to="/">
              <img alt="logo" width={90} src={logo} />
            </Link>
            <p className="thousand:mt-4 thousand:text-[15px] font-light text-[14px] tablet:text-[16px] mx-0 px-0 mt-[2%]">
              Your path to mental and emotional wellbeing.
            </p>

            <div className="mt-[2%]">
              <h1 className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light w-[80%]">
                MENTRA FZ-LLC is a duly incorporated entity under Registration
                No. 104169, as a Free Zone Company with limited liability issued
                by the Dubai Development Authority, Dubai, UAE.
              </h1>
              {/* <h1 className="text-[16px] font-medium">Mentra FZ-LLC</h1> */}
              <h1 className="text-[14px] tablet:text-[16px] thousand:text-[14px] mt-[2%] ">
                Location: In5 Tech, Dubai Internet City, Dubai, UAE
              </h1>
            </div>
          </div>

          <div className="thousand:w-[20%] flex items-start flex-col mt-[7%] thousand:mt-0 w-[100%]">
            <h1 className="font-bold thousand:mb-[12%] tablet:text-[18px] thousand:text-[16px] mb-[3%]">
              Solutions
            </h1>
            <div className="flex relative flex-col justify-start tablet:justify-between h-[100%]">
              <div>
                <Link to="/mentra-for-work">
                  <p className="text-[14px] tablet:text-[16px]  thousand:text-[14px] font-light thousand:mb-[4%] ">
                    Mentra for Work
                  </p>
                </Link>
                <Link to="/wellness-library">
                  <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light thousand:my-[4%] my-3">
                    Wellness Library
                  </p>
                </Link>
                <Link to="/for-therapist">
                  <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light thousand:my-[4%] my-3">
                    For coaches
                  </p>
                </Link>
              </div>

              <div
                className={`flex flex-col z-[2]  items-start it  thousand:items-start laptop:flex-row laptop:absolute laptop:bottom-0  laptop:items-center w-full gap-0  laptop:gap-[20px] mt-0 thousand:mt-0 tablet:mt-[8%]`}
              >
                <Link to="/privacy-policy">
                  <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] text-nowrap   font-light thousand:my-[4%] my-0">
                    Privacy Policy
                  </p>
                </Link>

                <Link to="/terms-and-conditions">
                  <p className="text-[14px]   tablet:text-[16px] thousand:text-[14px] text-nowrap  font-light thousand:my-[4%] my-3">
                    Term of Use
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="thousand:w-[20%] relative z-0 flex items-start flex-col mt-[7%] thousand:mt-0 w-[100%]">
            <h1 className="font-bold tablet:text-[18px] thousand:text-[16px] thousand:mb-[12%] mb-[3%]">
              Company
            </h1>
            <Link to="/about">
              <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light thousand:mb-[4%] ">
                About Us
              </p>
            </Link>

            <Link to="/contact">
              <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light thousand:my-[4%] my-3">
                Contact Us
              </p>
            </Link>
            <Link to="/faq">
              <p className="text-[14px] tablet:text-[16px]  thousand:text-[14px] cursor-pointer  font-light thousand:my-[4%] my-3">
                FAQs
              </p>
            </Link>
          </div>

          <div className="thousand:w-[20%] flex items-start flex-col mt-[7%] thousand:mt-0 w-[100%]">
            <div className="flex flex-col thousand:justify-between justify-start thousand:h-full w-full">
              <div>
                <h1 className="font-bold tablet:text-[18px] thousand:text-[16px] thousand:mb-[12%] mb-[3%]">
                  Follow Us
                </h1>
                <a
                  className="flex thousand:mb-[4%] mb-[1.5%] gap-2 thousand:gap-0 items-center"
                  href="https://www.facebook.com/yourmentra/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="twitter" className="size-[16px]" src={facebook} />
                  <p className="text-[14px]  tablet:text-[16px] thousand:text-[14px]  font-light thousand:ml-[7%]">
                    Facebook
                  </p>
                </a>

                <a
                  className="flex thousand:my-[4%] gap-2 thousand:gap-0 my-[1.5%] items-center"
                  href="https://www.instagram.com/heymentra/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="instagram" src={instagram} />
                  <p className="text-[14px] tablet:text-[16px] thousand:text-[14px]  font-light thousand:ml-[7%]">
                    Instagram
                  </p>
                </a>

                <a
                  className="flex thousand:my-[4%] gap-2 thousand:gap-0 my-[1.5%] items-center"
                  href="https://www.tiktok.com/@heymentra?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="tiktok" src={tiktok} />
                  <p className="text-[14px] tablet:text-[16px] thousand:text-[14px] font-light thousand:ml-[7%]">
                    TikTok
                  </p>
                </a>
              </div>
              <div className=" w-[65%]">
                <div className="border-[1px] border-[white] rounded-[30px] min-w-[100px] px-[3%] py-1 thousand:w-full flex item-center justify-center w-[150px] tablet:w-[40%] thousand:mt-0 mt-[7%]">
                  <img
                    alt="globe"
                    className="w-[20%] min-w-[27px] mr-[4px] "
                    width={30}
                    src={globe}
                  />
                  <button className=" text-[14px] thousand:text-[14px] tablet:text-[15px] ">
                    English
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Footer;
