import chevron from "../../../Images/work-side.png";
import Cookies from 'js-cookie'
import { useEffect, useState } from "react";
import { useCreateWorksheetMutation } from "@/App/services/worksheetApiSlice";
import { BiLoaderAlt } from "react-icons/bi";
import { toast } from "react-toastify";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/Components/ui/accordion";

function Summary({ handleCancel, nextStep }) {
  const [worksheet, setWorkSheet] = useState(null)
  const [client, setClient] = useState(null)
  const [ createWorksheet, { isLoading } ] = useCreateWorksheetMutation();
  
  async function submit () {
    try {
      const data = {
        ...worksheet, 
        user_id: client?.id 
      }
      const saveWorksheet = await createWorksheet(data).unwrap();
      console.log(saveWorksheet);
      if (saveWorksheet?.success) {
        toast.success(saveWorksheet.message);
        handleCancel()
      }
    } catch (error) {
      console.log(error)
    } 
  }
  
  function handleEditClient() {
    nextStep(2);
  }
  function handleEditQuestions() {
    nextStep(1);
  }

  useEffect(() => {
    setWorkSheet(JSON.parse(Cookies.get('worksheet')))
    setClient(JSON.parse(Cookies.get('client')))
  }, [])

  return (
    <div className="relative w-full min-h-full">
      <div className="bg-white h-[55px] border-b border-[#ECECED] duo:h-[64px] z-10 flex justify-end tablet:justify-between items-center px-[5vw]  w-full sticky top-0">
        <h3 className="text-[#0A0D14] hidden tablet:inline text-base font-semibold">
          Create Worksheet
        </h3>
        <div className="flex justify-center items-center gap-3">
          <button
            onClick={handleCancel}
            className="border border-[#E2E4E9] text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-[#525866]"
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoading}
            onClick={submit}
            className="flex bg-[#1A6A73] justify-center items-center gap-3 text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-white"
          >
            <p>Create Worksheet</p>
            {isLoading ? <BiLoaderAlt className="text-[24px] animate-spin" /> : 
            <img src={chevron} alt="chevron" />}
          </button>
        </div>
      </div>
      <div className="mb-8 mt-11">
        <div className=" w-[90vw]  max-w-[638px] mx-auto ">
          <div className="w-full space-y-4 border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
            <div className="flex  justify-between items-center ">
              <div className="flex justify-start gap-3 items-center ">
                <img className="w-10" src={client?.avatar} alt="avatar" />
                <div>
                  <h2 className="text-[#0A0D14] capitalize font-medium text-base ">
                    {client?.name}
                  </h2>
                  <p className="text-[#525866] text-sm  ">{client?.email}</p>
                </div>
              </div>
              <button
                onClick={handleEditClient}
                className=" text-[#1A6A73] font-medium text-sm"
              >
                Edit
              </button>
            </div>
          </div>
          <div className="w-full space-y-4 mt-6 border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
            <div className="space-y-3">
              <div className="w-full flex justify-end">
                <button
                  onClick={handleEditQuestions}
                  className=" text-[#1A6A73] font-medium text-sm"
                >
                  Edit
                </button>
              </div>
              <div className="space-y-4">
                <h2 className="text-[#0A0D14] font-semibold text-lg leading-[22px]">
                  {worksheet?.title}
                </h2>
                <p className="text-sm text-[#525866]">
                  {worksheet?.description}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full space-y-4 mt-6 border-[#E2E4E9] border p-4 duo:p-6 bg-white rounded-[16px]">
            <div className="">
              {worksheet?.type === 'weekly-task' && <><div className="w-full flex justify-between">
                <h2 className="text-base font-medium text-[#0A0D14] ">
                  Worksheet Tasks
                </h2>
                <button
                  onClick={handleEditQuestions}
                  className=" text-[#1A6A73] font-medium text-sm"
                >
                  Edit
                </button>
              </div>
              <div className="space-y-4 mt-4">
                <Accordion type="multiple" collapsible="true" className="w-full">
                  {worksheet?.week_days.map((data, index) => (
                    <AccordionItem
                      className="border-none"
                      value={data.day}
                      key={index}
                    >
                      <AccordionTrigger className="hover:no-underline">
                        <p className="text-[#0A0D14] capitalize  font-semibold text-sm">
                          {data.day} &nbsp;
                          <span className="font-normal text-[#525866] text-sm">
                            ({data.tasks.length})
                          </span>
                        </p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="flex flex-col gap-3 flex-wrap min-[550px]:gap-[2%] min-[840px]:gap-[1.5%] min-[550px]:flex-row min-[550px]:gap-y-[1.7vw] ">
                          <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[550px]:w-[49%] min-[840px]:w-[32.33%]">
                            <p className="text-sm font-medium text-[#0A0D14] mb-[7px]">
                              Morning 
                            </p>
                            <div className="pt-1 flex flex-col gap-2">
                              {data.tasks.filter(item => item.time === 'morning').map(
                                (item) => (
                                  <div
                                    key={item?._id}
                                    className="bg-white relative overflow-hidden rounded-[4px] p-1 w-full"
                                  >
                                    {item.task}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[550px]:w-[49%] min-[840px]:w-[32.33%]">
                            <p className="text-sm font-medium text-[#0A0D14] mb-[7px]">
                              Afternoon 
                            </p>
                            <div className="pt-1 flex flex-col gap-2">
                              {data.tasks.filter(item => item.time === 'afternoon').map(
                                (item) => (
                                  <div
                                    key={item?._id}
                                    className="bg-white relative overflow-hidden rounded-[4px] p-1 w-full"
                                  >
                                    {item.task}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="bg-[#F6F8FA] px-3 pt-2 pb-3  rounded-[8px] w-full min-[550px]:w-[49%] min-[840px]:w-[32.33%]">
                            <p className="text-sm font-medium text-[#0A0D14] mb-[7px]">
                              Evening 
                            </p>
                            <div className="pt-1 flex flex-col gap-2">
                              {data.tasks.filter(item => item.time === 'evening').map(
                                (item) => (
                                  <div
                                    key={item?._id}
                                    className="bg-white relative overflow-hidden rounded-[4px] p-1 w-full"
                                  >
                                    {item.task}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
