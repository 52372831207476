import React, { useEffect, useState } from 'react'
import { Storage } from '@/App/api/storage';
import { useCreateSessionNoteMutation } from '@/App/services/sessionSlice';
import { FormLoader } from '@/Components/loader';
import { Textarea } from '@/Components/ui/textarea';
import { toast } from 'sonner';
import { handleError } from '@/App/utils/renderErrors';

const noteKeys = [ 
    'assessment_interventions', 'assessment_progress', 'assessment_observations', 
    'subjective_history', 'subjective_psychological', 'subjective_relevant', 
    'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary' 
]

const SessionNote = ({ sessionId }) => {

    const [note, setNote] = useState({
        assessment_interventions: '',
        assessment_progress: '',
        assessment_observations: '',
        subjective_history: '',
        subjective_psychological: '',
        subjective_relevant: '',
        objective_appearance: '',
        objective_behavior: '',
        objective_symptoms: '',
        objective_risk: '',
        summary: '',
    });

    const [ createSessionNote, { isLoading } ] = useCreateSessionNoteMutation();

    const handleCreateSessionNote = async (event) => {
        event.preventDefault()
        try {
            const finalNote = {
                session_id: sessionId,
                ...note
            }
            const res = await createSessionNote({...finalNote}).unwrap();

            toast.success("Session Note saved successfully")

        } catch(error){
            const errorMessage = handleError(error);
            toast.error(errorMessage);
        }
    }

    const  handleInputChange = (e) => {
        const value = e.target.value;
        Storage.setItem([e.target.name], value)
        setNote({
            ...note,
            [e.target.name]: value
        });
      };

      useEffect(() => {
        
        const assessment_interventions = Storage.getItem('assessment_interventions');
        const assessment_progress = Storage.getItem('assessment_progress');
        const assessment_observations = Storage.getItem('assessment_observations');
        const subjective_history = Storage.getItem('subjective_history');
        const subjective_psychological = Storage.getItem('subjective_psychological');
        const subjective_relevant = Storage.getItem('subjective_relevant');
        const objective_appearance = Storage.getItem('objective_appearance');
        const objective_behavior = Storage.getItem('objective_behavior');
        const objective_symptoms = Storage.getItem('objective_symptoms');
        const objective_risk = Storage.getItem('objective_risk');
        const summary= Storage.getItem('summary');

        setNote((prev) => {
            return {
                ...prev,
                assessment_interventions,
                assessment_progress,
                assessment_observations,
                subjective_history,
                subjective_psychological,
                subjective_relevant,
                objective_appearance,
                objective_behavior,
                objective_symptoms,
                objective_risk,
                summary
            }
        })
      }, [])

    //   console.log(note)

  return (
    <form onSubmit={handleCreateSessionNote} className='w-full flex items-start flex-col'>
        <header className='w-full flex items-center justify-between sticky border-b border-[#E2E4E9] top-0 bg-white p-5'>
            <p className='text-2xl font-extrabold'>
                Sessions Note
            </p>

            <button type='submit' className="rounded-[50px] border bg-[#242628] text-[#ffffff] text-sm  py-[10px] font-semibold px-4">
                {isLoading ? <FormLoader /> : 'Save Note'}
            </button>
        </header>

        <main className='w-full p-5 flex flex-col gap-8'>
            <section className='w-full flex items-start flex-col gap-6'>
                <header className='text-2xl font-semibold'>Summary</header>
                <SessionTextArea 
                    label="Summary"
                    name='summary'
                    placeholder="Summary goes here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.summary}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
            </section>

            <section className='w-full flex items-start flex-col gap-6'>
                <header className='text-2xl font-semibold'>Assessment</header>
                <SessionTextArea 
                    label="Interventions"
                    name='assessment_interventions'
                    placeholder="Note your interventions here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.assessment_interventions}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />

                <SessionTextArea 
                    label="Progress and Response"
                    name='assessment_progress'
                    placeholder="Note the responses here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.assessment_progress}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
                <SessionTextArea 
                    label="Observations"
                    name='assessment_observations'
                    placeholder="Note your observations here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.assessment_observations}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
            </section>

            <section className='w-full flex items-start flex-col gap-3'>
                <header className='text-2xl font-semibold'>Subjective</header>
                <SessionTextArea 
                    label="History of Presenting complaint"
                    name='subjective_history'
                    placeholder="History of complaint here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.subjective_history}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />

                <SessionTextArea 
                    label="Psychological Factors"
                    name='subjective_psychological'
                    placeholder="Note psychological factors here..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.subjective_psychological}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
                <SessionTextArea 
                    label="Relevant Personal and Social History"
                    name='subjective_relevant'
                    placeholder="Social history..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.subjective_relevant}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
            </section>

            <section className='w-full flex items-start flex-col gap-3'>
                <header className='text-2xl font-semibold'>Objective</header>
                <SessionTextArea 
                    label="Appearance"
                    name='objective_appearance'
                    placeholder="Note appearance..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.objective_appearance}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />

                <SessionTextArea 
                    label="Behavior"
                    name='objective_behavior'
                    placeholder="Behavior..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.objective_behavior}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
                <SessionTextArea 
                    label="Symptoms Observed"
                    name='objective_symptoms'
                    placeholder="Symptoms observed..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.objective_symptoms}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
                <SessionTextArea 
                    label="Risk Assessment"
                    name='objective_risk'
                    placeholder="Risk assessment..."
                    onChange={handleInputChange}
                    rows={5}
                    value={note.objective_risk}
                    onBlur={(e) => handleCreateSessionNote(e)}
                />
            </section>
        </main>
    </form>
  )
}

export default SessionNote

const SessionTextArea = ({ label, name, placeholder, rows, value, onChange, onBlur }) => {
    return(
        <label className='w-full flex flex-col gap-2'>
            <label className='font-medium text-sm'>{label}</label>
            <Textarea
                style={{
                    outline: "none",
                    border: "1.5px solid #E2E4E9",
                    boxShadow: "none",
                }}
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                rows={rows}
                className="placeholder:text-sm focus:outline-none rounded-xl w-full text-xs text-tblack-100"
                placeholder={placeholder}
            />
        </label>
    )
}