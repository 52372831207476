import { Helmet } from "react-helmet";

function Title({title}) {
  return (
    <Helmet>
      <title>{title} - Mentra</title>
      <meta
        name="description"
        content="Your path to mental and emotional wellbeing."
      />
      <meta name="keywords" content="HTML, SEO" />
      <meta property="og:title" content="European Travel Destinations" />
      <meta property="og:type" content="article" />
      <meta
        property="og:description"
        content="Offering tour packages for individuals or groups."
      />
      <meta
        property="og:image"
        content="http://euro-travel-example.com/thumbnail.jpg"
      />
      <meta
        property="og:url"
        content="http://euro-travel-example.com/index.htm"
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default Title;
