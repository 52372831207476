import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Assets/Svgs/logo.svg";

const Header = ({ setStep, step }) => {
  const [loginText, setLoginText] = useState(false);
  const handleBack = () => {
    if (loginText) {
      window.history.back();
    } else {
      if (step === 1) {
        window.history.back();
      } else {
        setStep((prev) => prev - 1);
      }
    }
  };
  // if path is login then set login text to true
  useEffect(() => {
    if (window.location.pathname === "/login") {
      setLoginText(true);
    }
  }, []);

  return (
    <div className="">
      <div className="flex justify-between items-center w-[100%]">
        <div
          className="w-[20%] tablet:w-[35%] cursor-pointer"
          onClick={handleBack}
        >
          <h1>{loginText ? "Cancel" : step === 1 ? "Cancel" : "Back"}</h1>
        </div>
        <div className="flex justify-center items-center w-[28vw] max-w-[170px] lg:max-w-[200px] mt-[1%]">
          <img alt="logo" src={logo} />
        </div>
        {loginText ? (
          <div className="flex w-[20%] tablet:w-[35%] justify-end max-[767px]:w-[20%] max-[767px]:justify-end">
            <h1 className="hidden laptop:flex">Don't have an account?</h1>
            <Link to="/signup">
              <h1 className="font-bold text-[#1A6A73] underline ml-[12px]">
                Register
              </h1>
            </Link>
          </div>
        ) : (
          <div className="flex w-[20%] tablet:w-[35%] justify-end max-[767px]:w-[20%] max-[767px]:justify-end">
            <h1 className="hidden laptop:flex">
              Already have a therapist account?
            </h1>
            <Link to="/login">
              <h1 className="font-bold text-[#1A6A73] underline ml-[12px]">
                Login
              </h1>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
