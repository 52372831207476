import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import JoinMentra from "../Components/JoinMentra";
import { Link } from "react-router-dom";
import email from "../assets/Assets/Svgs/email.svg";
import info from "../assets/Assets/Svgs/info.svg";
import { toast } from "react-toastify";
import "../index.css";
import Title from "../Components/Title";
import { ContactLoader } from "../Components/loader";
import "./pages.css";
import "./signup.css"

const Contact = () => {
  const [progress, setProgress] = useState(false);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    window.scrollTo(0,0);
  }, []);

  const [formState, setFormState] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    // message: "",
  });
  const notify = () => toast("Sent successfully");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);

    const formObject = {
      full_name: formState.full_name,
      email: formState.email,
      phone_number: formState.phone_number,
      // message: formState.message,
    };

    try {
      const response = await fetch(
        import.meta.env.VITE_BASE_API_URL + "/web/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formObject),
        }
      );

      if (response.ok) {
        notify();
        setFormState({
          full_name: "",
          email: "",
          phone_number: "",
          // message: "",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setProgress(false);
    }
  };
  return (
    <div>
      <Title title={"Contact"} />
      {/* <button onClick={notify}>Notify!</button> */}
      <div className="flex px-[5vw] laptop:px-[15%] items-center justify-between pb-3 tablet:pb-14 pt-[140px] bg-[#FFFDF2] max-[767px]:pt-[25%] max-[767px]:flex-col max-[767px]:px-[5%]">
        <div className="w-[49%] max-[767px]:w-[100%]">
          <h1 className="philosopher text-[56px] font-bold text-[#1D1E20] max-[767px]:text-[32px] leading-[65px] max-[767px]:leading-[40px]">
            We’d Love <br /> to Hear from You
          </h1>
          <h1 className="text-[18px] font-semibold text-[#616161] mt-[2%] w-[80%] max-[767px]:text-[14px]">
            Feel free to reach out to us if you have any questions or feedback
            for us.
          </h1>
          <div className="mt-[5%]">
            <h1 className="font-medium">You can reach us at:</h1>

            <div className="flex items-center my-[3%]">
              <img alt="email" src={email} />
              <div className="ml-[3%] max-[767px]:text-[14px]">
                <h1>Email</h1>
                <a href="mailto:support@yourmentra.com">hello@yourmentra.com</a>
              </div>
            </div>

            {/* <div className="flex items-center my-[3%]">
              <Image src={email} />
              <div className="ml-[3%] max-[767px]:text-[14px]">
                <h1>Phone</h1>
                <a href="tel:02 1234 5503">Give us a call on (02) 1234 5503</a>
              </div>
            </div>

            <div className="flex items-center my-[3%]">
              <Image src={email} />
              <div className="ml-[3%] max-[767px]:text-[14px]">
                <h1>Location</h1>
                <h1>Visit us at In5 Tech, Dubai Internet City, Dubai, UAE</h1>
              </div>
            </div> */}
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="rounded-[48px] log-input bg-gradient-to-b from-[#FBEDB1] to-[#FEF7EC] py-[3%] px-[5%] w-[49%] max-[767px]:w-[100%] max-[767px]:mt-[5%] max-[767px]:px-[5%] max-[767px]:rounded-[20px]"
        >
          <div className="w-[100%] bg-white rounded-[16px] p-[3%] mt-[5%]">
            <h1 className="max-[767px]:text-[14px] font-semibold">Full Name</h1>
            <input
              disabled={progress ? true : false}
              onChange={(e) =>
                setFormState({ ...formState, full_name: e.target.value })
              }
              value={formState.full_name}
              required
              name="fullName"
              className="w-[100%]  text-[#1A6A73] max-[767px]:text-[14px]"
            />
          </div>

          <div className="w-[100%] bg-white rounded-[16px] p-[3%] mt-[5%]">
            <h1 className="max-[767px]:text-[14px] font-semibold">
              Email Address
            </h1>
            <input
              disabled={progress ? true : false}
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
              value={formState.email}
              required
              type="email"
              name="email"
              className="w-[100%] text-[#1A6A73] max-[767px]:text-[14px]"
            />
          </div>

          <div className="w-[100%] bg-white rounded-[16px] p-[3%] mt-[5%]">
            <h1 className="max-[767px]:text-[14px] font-semibold">
              Phone Number
            </h1>
            <input
              disabled={progress ? true : false}
              onChange={(e) =>
                setFormState({ ...formState, phone_number: e.target.value })
              }
              value={formState.phone_number}
              required
              // type="tel"
              name="phone_number"
              className="w-[100%] text-[#1A6A73] max-[767px]:text-[14px]"
            />
          </div>

          <div className="w-[100%] bg-white rounded-[16px] p-[3%] mt-[5%]">
            <h1 className="max-[767px]:text-[14px] font-semibold">
              Message (optional)
            </h1>
            <textarea
              disabled={progress ? true : false}
              onChange={(e) =>
                setFormState({ ...formState, message: e.target.value })
              }
              value={formState.message}
              name="message"
              className="w-[100%] text-[#1A6A73] border-none outline-none max-[767px]:text-[14px]"
            />
          </div>

          <div className="flex mt-[3%] items-center ">
            <img alt="" src={info} />
            <Link to="/terms-and-conditions">
              <h1 className="text-[12px] hover:text-[#1A6A73] terms">
                Terms and Conditions Apply
              </h1>
            </Link>
          </div>

          <div className="w-[100%] mt-[5%] text-center">
            <button
              disabled={progress ? true : false}
              className="bg-[#1A6A73] w-[100%] flex items-center justify-center py-2 text-[white] h-[50px] relative rounded-[50px] px-[3%] mt-[5%]"
            >
              {progress ? <ContactLoader /> : "Join Waitlist"}
            </button>
          </div>
        </form>
      </div>
      <JoinMentra />
      <Footer />
    </div>
  );
};

export default Contact;
