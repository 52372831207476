import React,{ useEffect, useState, useMemo } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import arrDown from "../../assets/Assets/Images/arrow-down.png";
import search from "../../assets/Assets/Images/search.png";
// import SignupFormThree from "./SignupFormThree";
import { useSignUpContext } from "./SignUpContext";
import { getCountries } from "../../Api";
import Loader from "../loader";
const SignupFormTwo = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(3);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [filled, setFilled] = useState(true);

  const [countries, setCountries] = useState([]);
  const [national, setNational] = useState([]);

  const [livedIn, setLivedIn] = useState([]);
  const [address, setAddress] = useState(signupData.address);

  const [residenceModal, setResidenceModal] = useState(false);
  const [livedInModal, setLivedInModal] = useState(false);
  const [residence, setResidence] = useState(signupData.country);
  const [countries_lived_in, setCountries_lived_in] = useState(  
    signupData.countries_lived_in ? signupData.countries_lived_in.join(",") : ""
);
  const [residenceSearch, setResidenceSearch] = useState("");
  const [livedInSearch, setLivedInSearch] = useState("");
  const [bio, setBio] = useState(signupData.bio);

  function handleResidenceModal() {
    setResidenceModal(!residenceModal);
  }

  
  function handleLivedInModal() {
    setLivedInModal(!livedInModal);
  }


  function handeleResidence(value) {
    setResidence(value);
    setResidenceModal(false);
  }

  function handleLivedIn(value) {
    setCountries_lived_in(value);
    setLivedInModal(false);
  }


  

  useEffect(() => {
    if (residenceModal) {
      const input = document.getElementById("residence");
      input.focus();
    }
  }, [residenceModal]);

  useEffect(() => {
    if (livedInModal) {
      const input = document.getElementById("livedin");
      input.focus();
    }
  }, [livedInModal]);

  const filteredResidence = useMemo(() => {
    return residenceSearch.trim() !== ""
      ? countries.filter((item) => {
          const nameMatch =
            item.name?.common
              ?.toLowerCase()
              ?.startsWith(residenceSearch.toLowerCase()) ?? false;

          let alpha2CodeMatch;
          let alpha3CodeMatch;
          if (residenceSearch.length >= 2) {
            alpha2CodeMatch = item.cca2
              .toLowerCase()
              .startsWith(residenceSearch.toLowerCase());
            alpha3CodeMatch = item.cca3
              .toLowerCase()
              .startsWith(residenceSearch.toLowerCase());
          }

          let altSpellingsMatch = false;
          if (item.altSpellings && residenceSearch.length >= 2) {
            altSpellingsMatch = item.altSpellings.some((spell) =>
              spell.toLowerCase().startsWith(residenceSearch.toLowerCase())
            );
          }

          return (
            nameMatch || alpha2CodeMatch || alpha3CodeMatch || altSpellingsMatch
          );
        })
      : countries;
  }, [residenceSearch, countries]);
  
  const filteredLivedIn = useMemo(() => {
    return livedInSearch.trim() !== ""
      ? livedIn.filter((item) => {
          const nameMatch =
            item.name?.common
              ?.toLowerCase()
              ?.startsWith(livedInSearch.toLowerCase()) ?? false;

          let alpha2CodeMatch;
          let alpha3CodeMatch;
          if (livedInSearch.length >= 2) {
            alpha2CodeMatch = item.cca2
              .toLowerCase()
              .startsWith(livedInSearch.toLowerCase());
            alpha3CodeMatch = item.cca3
              .toLowerCase()
              .startsWith(livedInSearch.toLowerCase());
          }

          let altSpellingsMatch = false;
          if (item.altSpellings && livedInSearch.length >= 2) {
            altSpellingsMatch = item.altSpellings.some((spell) =>
              spell.toLowerCase().startsWith(livedInSearch.toLowerCase())
            );
          }

          return (
            nameMatch || alpha2CodeMatch || alpha3CodeMatch || altSpellingsMatch
          );
        })
      : livedIn;
  }, [livedInSearch, livedIn]);

  const [nationalityModal, setNationalityModal] = useState(false);
  const [nationality, setNationality] = useState(signupData.nationality);
  const [nationalitySearch, setNationalitySearch] = useState("");

  function handleNationalityModal() {
    setNationalityModal(!nationalityModal);
  }

  function handleNationality(value) {
    setNationality(value);
    setNationalityModal(false);
  }

  useEffect(() => {
    if (nationalityModal) {
      const input = document.getElementById("nationality");
      input.focus();
    }
  }, [nationalityModal]);

  const filteredNationality = useMemo(() => {
    return nationalitySearch.trim() !== ""
      ? national.filter((item) => {
          const nameMatch =
            item.demonyms?.eng?.f
              ?.toLowerCase()
              ?.startsWith(nationalitySearch.toLowerCase()) ?? false;

          let alpha2CodeMatch;
          let alpha3CodeMatch;
          if (nationalitySearch.length >= 2) {
            alpha2CodeMatch = item.cca2
              .toLowerCase()
              .startsWith(nationalitySearch.toLowerCase());
            alpha3CodeMatch = item.cca3
              .toLowerCase()
              .startsWith(nationalitySearch.toLowerCase());
          }

          let altSpellingsMatch = false;
          if (item.altSpellings && nationalitySearch.length >= 2) {
            altSpellingsMatch = item.altSpellings.some((spell) =>
              spell.toLowerCase().startsWith(nationalitySearch.toLowerCase())
            );
          }

          return (
            nameMatch || alpha2CodeMatch || alpha3CodeMatch || altSpellingsMatch
          );
        })
      : national;
  }, [nationalitySearch, national]);

  const [emiratesModal, setEmiratesModal] = useState(false);
  const [emirates, setEmirates] = useState(signupData.emirate_of_licensure);

  function handleEmirateModal() {
    setEmiratesModal(!emiratesModal);
  }

  function handleEmirate(e) {
    const value = e.target.value;
    setEmirates(value);
    setEmiratesModal(false);
  }

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        const data = response.data;
        // console.log(data.filter((data) => data.name.common.toLowerCase() === 'nigeria'));

        // Sort the data by name and set it to the 'countries' state
        const sortedByName = [...data].sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });
        // console.log(sortedByName[5]);
        setCountries(sortedByName);
        setLivedIn(sortedByName);

        // Sort the data by 'eng.f' and set it to the 'national' state
        const sortedByEngF = [...data].sort((a, b) => {
          // Check if "eng" key exists in both objects
          const aHasEng = a.demonyms && a.demonyms.eng;
          const bHasEng = b.demonyms && b.demonyms.eng;

          // If only one of them has the "eng" key, prioritize the one that does
          if (aHasEng && !bHasEng) return -1;
          if (!aHasEng && bHasEng) return 1;

          // If both have the "eng" key or neither has it, sort based on "eng.f"
          if (aHasEng && bHasEng) {
            if (a.demonyms.eng.f < b.demonyms.eng.f) return -1;
            if (a.demonyms.eng.f > b.demonyms.eng.f) return 1;
          }

          // If both have the "eng" key or neither has it, or "eng" key doesn't have "f" key, maintain the original order
          return 0;
        });
        setNational(sortedByEngF);
      } catch (error) {
        console.error("Error fetching countries:", error.message);
      }
    };

    fetchCountries();
  }, []);

  const handleClick = () => {

    if (nationality && residence && address && countries_lived_in && emirates && bio) {

        const countriesLivedIn = countries_lived_in.split(",");
  
      // console.log(nationality, residence, emirates, countriesLivedIn, address);

      setSignupData((prevData) => ({
        ...prevData,
        nationality: nationality,
        country: residence,
        address: address,
        countries_lived_in:  countriesLivedIn,
        emirate_of_licensure: emirates,
        bio: bio,
      }));
      setStep(4);
    } else {
      setFilled(false);
    }
  };

  return countries.length > 0 ? (
    <form onSubmit={(e) => e.preventDefault()} className="z-10">
      <form
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col mt-3 items-center shadow-lg h-[100%] bg-white  pb-8  rounded-[20px] z-10 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px]  max-[767px]:w-[100%]  lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] max-[767px]:w-[100%]">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px]  font-bold max-[767px]:text-[20px]">
            Sign up as a Therapist
          </h1>
          <p>Enter your details to proceed.</p>
        </div>

        <div className=" max-[767px]:w-[90%]">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            All fields must be filled correctly!
          </p>
          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
              Nationality
            </p>
            <div className="w-full relative text-[15px]  cursor-pointer font-semibold  h-[30px] max-[767px]:text-[14px]">
              <div
                onClick={handleNationalityModal}
                className="flex justify-between items-center h-full w-full"
              >
                <p className="">
                  {nationality ? (
                    nationality
                  ) : (
                    <p className="text-zinc-400">Select an option</p>
                  )}
                </p>
                <img src={arrDown} className="h-[7px] pr-[6px]" alt="menu" />
              </div>
              {nationalityModal ? (
                <div
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                  className="w-full  overflow-y-scroll z-10 overscroll-none over flex flex-col justify-start items-start absolute top-[100%]  rounded-md h-[250px] bg-[#ffffff] border-2 "
                >
                  <div className=" border-b-2 gap-3 flex w-full px-3 py-2 items-center">
                    <img src={search} alt="search" className="w-[20px]" />
                    <input
                      id="nationality"
                      value={nationalitySearch}
                      onChange={(e) => setNationalitySearch(e.target.value)}
                      className="border-l-2 pl-3 font-medium text-zinc-500"
                      type="text"
                    />
                  </div>
                  {filteredNationality.map(
                    (item, index) =>
                      item.demonyms?.eng.f && (
                        <div
                          className="block  w-full"
                          key={index}
                          onClick={() => handleNationality(item.demonyms.eng.f)}
                        >
                          <div
                            className={`flex justify-start px-3 ${
                              nationality === item.demonyms.eng.f
                                ? "bg-[#1A6A73] text-white"
                                : ""
                            } py-2 size-full hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                          >
                            <img
                              src={item.flags.png}
                              alt=""
                              className="w-8 rounded-[4px] overflow-hidden"
                            />
                            <p>{item.demonyms.eng.f}</p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              ) : (
                ""
              )}
              <div
                onClick={handleNationalityModal}
                className={`w-screen top-0 left-0 ${
                  nationalityModal ? "" : "hidden"
                }  h-screen fixed z-1`}
              ></div>
            </div>
          </div>

          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
              Countries lived in
            </p>
            <div className="w-full relative text-[15px]  cursor-pointer font-semibold  h-[30px] max-[767px]:text-[14px]">
              <div
                onClick={handleLivedInModal}
                className="flex justify-between items-center h-full w-full"
              >
                <p className="">
                  {countries_lived_in ? (
                    countries_lived_in
                  ) : (
                    <p className="text-zinc-400">Select an option</p>
                  )}
                </p>
                <img src={arrDown} className="h-[7px] pr-[6px]" alt="menu" />
              </div>
              {livedInModal ? (
                <div
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                  className="w-full  overflow-y-scroll z-10 overscroll-none over flex flex-col justify-start items-start absolute bottom-[100%]  rounded-md h-[250px] bg-[#ffffff] border-2 "
                >
                  <div className=" border-b-2 gap-3 flex w-full px-3 py-2 items-center">
                    <img src={search} alt="search" className="w-[20px]" />
                    <input
                      id="livedin"
                      value={livedInSearch}
                      onChange={(e) => setLivedInSearch(e.target.value)}
                      className="border-l-2 pl-3 font-medium text-zinc-500"
                      type="text"
                    />
                  </div>
                   {filteredLivedIn.map((item, index) => (
                    <div
                      className="block  w-full"
                      key={index}
                      onClick={() => handleLivedIn(item.name.common)}
                    >
                      <div
                        className={`flex justify-start px-3 ${
                          countries_lived_in === item.name.common
                            ? "bg-[#1A6A73] text-white"
                            : ""
                        } py-2 size-full hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                      >
                        <img
                          src={item.flags.png}
                          alt=""
                          className="w-8 rounded-[4px] overflow-hidden"
                        />
                        <p>{item.name.common}</p>
                      </div>
                    </div>
                  ))}
                  </div>
                ) : (
                  ""
                )}
                <div
                  onClick={handleLivedInModal}
                  className={`w-screen top-0 left-0 ${
                    livedInModal ? "" : "hidden"
                  }  h-screen fixed z-1`}
                ></div>

            </div>
          </div>

          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
              Country of residence
            </p>
            <div className="w-full relative text-[15px]  cursor-pointer font-semibold  h-[30px] max-[767px]:text-[14px]">
              <div
                onClick={handleResidenceModal}
                className="flex justify-between items-center h-full w-full"
              >
                <p className="">
                  {residence ? (
                    residence
                  ) : (
                    <p className="text-zinc-400">Select an option</p>
                  )}
                </p>
                <img src={arrDown} className="h-[7px] pr-[6px]" alt="menu" />
              </div>
              {residenceModal ? (
                <div
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                  className="w-full  overflow-y-scroll z-10 overscroll-none over flex flex-col justify-start items-start absolute bottom-[100%]  rounded-md h-[250px] bg-[#ffffff] border-2 "
                >
                  <div className=" border-b-2 gap-3 flex w-full px-3 py-2 items-center">
                    <img src={search} alt="search" className="w-[20px]" />
                    <input
                      id="residence"
                      value={residenceSearch}
                      onChange={(e) => setResidenceSearch(e.target.value)}
                      className="border-l-2 pl-3 font-medium text-zinc-500"
                      type="text"
                    />
                  </div>
                  {filteredResidence.map((item, index) => (
                    <div
                      className="block  w-full"
                      key={index}
                      onClick={() => handeleResidence(item.name.common)}
                    >
                      <div
                        className={`flex justify-start px-3 ${
                          residence === item.name.common
                            ? "bg-[#1A6A73] text-white"
                            : ""
                        } py-2 size-full hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                      >
                        <img
                          src={item.flags.png}
                          alt=""
                          className="w-8 rounded-[4px] overflow-hidden"
                        />
                        <p>{item.name.common}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              <div
                onClick={handleResidenceModal}
                className={`w-screen top-0 left-0 ${
                  residenceModal ? "" : "hidden"
                }  h-screen fixed z-1`}
              ></div>
            </div>
          </div>

          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
              Current Address
            </p>
            <div className="flex items-center">
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px]"
                placeholder="Enter address"
              />
            </div>
          </div>
          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
                  Bio
            </p>
            <div className="flex items-center">
              <input
                type="text"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px]"
                placeholder="Enter bio"
              />
            </div>
          </div>

          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <label
              htmlFor="emirate"
              className="block text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]"
            >
              Emirate of Licensure
            </label>
            <select
              id="emirate"
              name="emirate"
              value={emirates}
              onChange={handleEmirate}
              className="mt-1 block w-full h-[30px] pl-2 px-0 text-[15px] font-semibold py-0  bg-transparent border-none focus:border-none focus:outline-none max-[767px]:text-[14px]  sm:text-sm rounded-md"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Dubai">Dubai</option>
              <option value="Abu Dhabi">Abu Dhabi</option>
              <option value="Sharjah">Sharjah</option>
              <option value="Umm Al Qaiwain">Umm Al Qaiwain</option>
              <option value="Fujairah">Fujairah</option>
              <option value="Ajman">Ajman</option>
              <option value="Ras Al Khaimah">Ras Al Khaimah</option>
            </select>

            {/* <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
                Emirate of Licensure
              </p>
              <div className="w-full relative text-[15px]  cursor-pointer font-semibold  h-[30px] max-[767px]:text-[14px]">
                <div
                  onClick={handleEmirateModal}
                  className="flex justify-between items-center h-full w-full"
                >
                  <p className="">
                    {emirates ? (
                      emirates
                    ) : (
                      <p className="text-zinc-400">Select an option</p>
                    )}
                  </p>
                  <img src={arrDown} className="h-[7px] pr-[6px]" alt="menu" />
                </div>
                {emiratesModal ? (
                  <div
                    style={{
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    className="w-full  overflow-y-scroll  z-10 overscroll-none over *:text-left flex flex-col justify-start items-start absolute bottom-[100%]  rounded-md h-fit max-h-[250px] bg-[#ffffff] border-2 "
                  >
                    <div
                      onClick={() => handleEmirate("Dubai")}
                      className={` px-3 ${
                        emirates === "Dubai" ? "bg-[#1A6A73] text-white" : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white   text-[#191B1E] `}
                    >
                      Dubai
                    </div>
                    <div
                      onClick={() => handleEmirate("Abu Dhabi")}
                      className={` px-3 ${
                        emirates === "Abu Dhabi"
                          ? "bg-[#1A6A73] text-white"
                          : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Abu Dhabi
                    </div>

                    <div
                      onClick={() => handleEmirate("Sharjah")}
                      className={` px-3 ${
                        emirates === "Sharjah" ? "bg-[#1A6A73] text-white" : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Sharjah
                    </div>
                    <div
                      onClick={() => handleEmirate("Umm Al Qaiwain")}
                      className={` px-3 ${
                        emirates === "Umm Al Qaiwain"
                          ? "bg-[#1A6A73] text-white"
                          : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Umm Al Qaiwain
                    </div>
                    <div
                      onClick={() => handleEmirate("Fujairah")}
                      className={` px-3 ${
                        emirates === "Fujairah" ? "bg-[#1A6A73] text-white" : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Fujairah
                    </div>
                    <div
                      onClick={() => handleEmirate("Ajman")}
                      className={` px-3 ${
                        emirates === "Ajman" ? "bg-[#1A6A73] text-white" : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Ajman
                    </div>
                    <div
                      onClick={() => handleEmirate("Ras Al Khaimah")}
                      className={` px-3 ${
                        emirates === "Ras Al Khaimah"
                          ? "bg-[#1A6A73] text-white"
                          : ""
                      } py-1 size-full hover:bg-[#1A6A73] hover:text-white  gap-4 text-[#191B1E] `}
                    >
                      Ras Al Khaimah
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  onClick={handleEmirateModal}
                  className={`w-screen top-0 left-0 ${
                    emiratesModal ? "" : "hidden"
                  }  h-screen fixed z-1`}
                ></div>
              </div> */}
          </div>
          <div>
            <button
              type="button"
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[100%] py-[4%] mt-[5%] text-[white] rounded-[30px] max-[1130px]:rounded-[20px]"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </form>
  ) : (
    <div className="mt-[15%]">
      <Loader />
    </div>
  );
};

export default SignupFormTwo;
