import { motion } from "framer-motion";
import ourStory from "../../assets/Assets/Images/ourStory.jpg";

const OurStory = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      key="story"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="flex flex-col items-center justify-center text-[#333333]"
    >
      {" "}
      <h1 className="philosopher text-[48px] text-center font-bold mb-3 max-[767px]:text-[24px]">
        Our Story
      </h1>
      <div className="w-full max-h-[330px] overflow-hidden rounded-[10px] border-[10px] border-[#f0f0f0]">
        <img
          className="w-full object-cover object-center max-h-[330px]  mx-auto"
          src={ourStory}
          alt="passion led us here"
        />
      </div>
      <div className="mt-5">
        <h1 className="text-[16px] mb-1 font-medium tablet:text-[18px] text-center">
          Welcome to Mentra! Born with a vision to transform mental and
          emotional well-being, we're here to shatter barriers and open doors to
          accessible, affordable care for everyone. At Mentra, we don't just
          believe in healthcare as a right; we see it as
        </h1>
        <h1 className="text-[22px] font-bold mb-4 w-[100%] max-[767px]:text-[18px] text-center">
          a fundamental pillar of a vibrant life.
        </h1>
        <h1 className="text-[16px] mb-4 font-medium tablet:text-[18px] text-center">
          Harnessing the transformative power of artificial intelligence fused
          with the warmth and understanding of human expertise, we bring you
          care that's not only affordable but deeply personalized. Imagine a
          world where mental health support adapts to your needs, where help is
          not just professional but also profoundly personal. That's the world
          Mentra is building.
        </h1>
        <h1 className="text-[16px] font-medium tablet:text-[18px] text-center">
          We're here to guide, listen, and empower, making mental wellness an
          attainable reality. Mentra isn't just our name; it's our commitment to
          you – a promise of unwavering support on your path to wellbeing.
        </h1>
        <h1 className="text-[22px] font-bold mt-4  w-[100%] max-[767px]:text-[18px] text-center font-caveat">
          Welcome to Mentra -{" "}
          <span className="italic">
            Your Everyday Coach
          </span>
        </h1>
      </div>
      {/* <div className="mt-[3%]">
        <h1 className="text-[18px] mb-[3%] max-[767px]:text-[16px] text-center">
          Our platform is more than just a service; it's a
          sanctuary—user-friendly and intuitive. Mentra is accessible anytime,
          anywhere. We meet you in your moment of need, guiding you gently
          toward the future you desire and deserve.
        </h1>
        <h1 className="text-[16px] mb-[3%] max-[767px]:text-[16px] text-center">
          Mentra isn't just our name; it's our promise to you. A promise of
          unwavering support, a companion in your journey towards wellbeing.
          We're here to hold space for you, listen, guide, and empower.{" "}
        </h1>
        <h1 className="text-[16px] max-[767px]:text-[16px] text-center">
          With Mentra, embrace a life where mental and emotional wellness is not
          just a dream, but your everyday reality. Welcome to Mentra - your
          mantra for mental and emotional wellness.
        </h1>
      </div> */}
    </motion.div>
  );
};

export default OurStory;
