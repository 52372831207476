import chevron from "../../Images/chevron-right.png";
import png from "../../Images/png-icon.png";
import pdf from "../../Images/pdf-icon.png";
import jpg from "../../Images/jpg-icon.png";
import doc from "../../Images/doc-icon.png";
import docx from "../../Images/docx-icon.png";
import toast from "react-hot-toast";

import { Label } from "@/Components/ui/label";
import { useSettingsDataContext } from "@/App/pages/Settings";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import { useState, useEffect } from "react";
import {
  useEditSettingsMutation,
  useGetDocumentsQuery,
} from "@/App/services/settingsApiSlice";
import { ContactLoader } from "@/Components/loader";
import { Skeleton } from "@/Components/ui/skeleton";

function Professional() {
  const [openModal, setModal] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [fieldToChange, setField] = useState("");
  const [editModalTitle, setModalTitle] = useState("");
  const [editModalDesc, setModalDesc] = useState("");

  const {
    data: usersDocuments,
    error: documentError,
    isLoading: documentIsloading,
  } = useGetDocumentsQuery();

  const { settingsData: professionalData, refetch } = useSettingsDataContext();
  const [editSettings, { isLoading }] = useEditSettingsMutation();

  function handleEdit(title, desc, field) {
    setModalTitle(title);
    setModalDesc(desc);
    setField(field);
    setModal(!openModal);
  }

  useEffect(() => {
    if (documentError) {
      // console.log(documentError);

      toast.error("Error fetching your credentials");
    }
  }, [documentError]);

  async function submitHandler(e) {
    e.preventDefault();
    try {
      const updatedSettings = await editSettings({
        [fieldToChange]: newValue,
      }).unwrap();
      // console.log(updatedSettings);
      toast.success("Updated successfully");
      refetch();
    } catch (error) {
      toast.error(`Error updating ${editModalTitle}`);
    } finally {
      setModalTitle("");
      setModalDesc("");
      setField("");
      setNewValue("");
      setModal(!openModal);
    }
  }

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setModal}>
        <DialogContent className=" w-[90vw] px-4 py-5 sm:p-6 rounded-lg sm:max-w-[465px]">
          <DialogHeader className="*:text-left">
            <DialogTitle>Edit {editModalTitle}</DialogTitle>
            <DialogDescription>{editModalDesc}</DialogDescription>
          </DialogHeader>
          <form onSubmit={submitHandler}>
            <div className="mt-3">
              <Label
                htmlFor="name"
                className="text-left font-semibold text-[#0A0D14]"
              >
                New {editModalTitle}
              </Label>

              <input
                style={{
                  outline: "none",
                  border: "1.5px solid #E2E4E9",
                  boxShadow: "none",
                }}
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                id="name"
                type={
                  editModalTitle.toLowerCase() === "email address"
                    ? "email"
                    : "text"
                }
                required
                placeholder="Enter new info"
                className="w-full border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1 "
              />
            </div>
            <div className="w-full mt-5 flex justify-end">
              <button
                type="submit"
                className="bg-[#1A6A73] capitalize text-white ml-auto h-[40px] w-[105px] flex justify-center items-center leading-[17px] rounded-[6px]   text-[16px]  cursor pointer"
              >
                {isLoading ? <ContactLoader /> : "Save"}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
          Professional Info
        </h2>
        <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
          Personalize your privacy settings and enhance the security of your
          account.
        </p>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Legal Name
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            The official legal name of your company.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
              {professionalData.legal_name}
            </h3>
            <div
              onClick={() =>
                handleEdit(
                  "Legal Name",
                  "The official legal name of your company.",
                  "legal_name"
                )
              }
              className="flex cursor-pointer justify-start items-center gap-[2px] pt-1"
            >
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Tax ID Number
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Registered in the official jurisdiction of your company.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left  text-[14px] tablet:text-[15px] leading-5">
              {professionalData.tax_id}
            </h3>
            <div
              onClick={() =>
                handleEdit(
                  "Tax ID",
                  "Registered in the official jurisdiction of your company.",
                  "tax_id"
                )
              }
              className="flex cursor-pointer justify-start items-center gap-[2px] pt-1"
            >
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Email Address
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Your official email address for billings and contact requests.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left  text-[14px] tablet:text-[15px] leading-5">
              {professionalData.legal_address}
            </h3>
            <div
              onClick={() =>
                handleEdit(
                  "Email Address",
                  " Your official email address for billings and contact requests.",
                  "legal_address"
                )
              }
              className="flex cursor-pointer justify-start items-center gap-[2px] pt-1"
            >
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Phone Number
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            The official phone number for billing and contact requests.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
              {professionalData.phone_number}
            </h3>
            <div
              onClick={() =>
                handleEdit(
                  "Phone Number",
                  " The official phone number for billing and contact requests.",
                  "phone_number"
                )
              }
              className="flex cursor-pointer justify-start items-center gap-[2px] pt-1"
            >
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div>
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Option that describes you
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Here is the option you chose to describe yourself
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
              {professionalData.title}
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Where do you currently work?
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            This is where you work at the moment.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
              {professionalData.current_workplace}
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            How much time do you spend on Admin related tasks per week?
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Links for your company's website and social media accounts.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <h3 className="text-[#0A0D14] font-medium text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
              {professionalData.intended_weekly_time} Hours
            </h3>
            {/* <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full gap-4  flex flex-col laptop:flex-row justify-center items-center nine:items-start laptop:gap-[4%]">
        <div className="flex w-full laptop:w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Credentials
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Your valid credentials and licenses.
          </p>
        </div>

        <div className=" w-full laptop:w-[48%] justify-start">
          {documentIsloading ? (
            <div className="flex w-full  h-fit  gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-end  tablet:items-start">
              <Skeleton className=" w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]" />
              <Skeleton className=" w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]" />
            </div>
          ) : (
            <div className="flex w-full  h-fit  gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-end  tablet:items-start">
                {usersDocuments.data.length > 0 &&
                  usersDocuments.data.map((item, index) => (
                  <div
                    key={index}
                    className=" w-full tablet:w-[48.5%] laptop:w-[190px] py-4 pl-4 pr-0 rounded-[12px] border border-[#E2E4E9] flex justify-between items-center"
                  >
                    <div className="flex justify-start items-center max-w-[90%] gap-3">
                      <img
                        src={
                          item.file.name.includes(".png")
                            ? png
                            : item.file.name.includes(".pdf")
                            ? pdf
                            : item.file.name.includes(".doc")
                            ? doc
                            : item.file.name.includes(".docx")
                            ? docx
                            : jpg
                        }
                        alt=""
                        className="w-[40px]"
                      />
                      <div className="space-y-[2px] *:text-nowrap overflow-hidden">
                        <p className="font-medium text-[14px]">
                          {item.file.name}
                        </p>
                        <p className="font-normal text-[12px] text-[#333333]">
                          {item.file.size / 1024 > 1024
                            ? `${(item.file.size / (1024 * 1024)).toFixed(
                                2
                              )} MB`
                            : `${Math.ceil(item.file.size / 1024)} KB`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {/* <div className="flex cursor-pointer justify-start mt-3 ml-auto laptop:ml-[0] nine:pl-2 w-fit items-center gap-[2px] pt-1">
            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
              Edit
            </p>
            <img src={chevron} className="w-5" alt="edit" />
          </div> */}
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full gap-6  flex flex-col laptop:flex-row justify-center items-center nine:items-start laptop:gap-[4%]">
        <div className="flex w-full laptop:w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            What techniques are you an expert in?
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Links for your company's website and social media accounts.
          </p>
        </div>
        <div className="flex w-full laptop:w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
              {professionalData.techniques_of_expertise?.map((item, index) => (
                <p
                  key={index}
                  className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] px-2 py-[2px] rounded-[50px]"
                >
                  {item}
                </p>
              ))}
            </div>
            {/* <div className="flex cursor-pointer mt-3 ml-auto laptop:ml-[0] nine:pl-2 w-fit justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>

      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />

      <div className="w-full gap-6  flex flex-col laptop:flex-row justify-center items-center nine:items-start laptop:gap-[4%]">
        <div className="flex w-full laptop:w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            What conditions do you treat?
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Links for your company's website and social media accounts.
          </p>
        </div>
        <div className="flex w-full laptop:w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
          <div className="w-fit flex flex-col  items-end tablet:items-start">
            <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
              {professionalData.treatable_conditions?.map((item, index) => (
                <p
                  key={index}
                  className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] px-2 py-[2px] rounded-[50px]"
                >
                  {item}
                </p>
              ))}
            </div>
            {/* <div className="flex cursor-pointer mt-3 ml-auto laptop:ml-[0] nine:pl-2 w-fit justify-start items-center gap-[2px] pt-1">
              <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                Edit
              </p>
              <img src={chevron} className="w-5" alt="edit" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professional;

{
  /* The tremor ui libary is forcing a border on every input thats ehy i have to set the borders using inline styles and add !important to the tailwind styles*/
}
