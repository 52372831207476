import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
// import SignupFormThree from "./SignupFormThree";
import toast from "react-hot-toast";
import Loader from "../loader";
import { checkOtp } from "@/Api";
const SignupFormOtp = ({ setProgress, setStep , otp_check}) => {
  useEffect(() => {
    setProgress(2);
  }, [setProgress]);
  // const [nextModal, setNextModal] = useState(false);
  const [otp, setOtp] = useState();
  useEffect(() => {
    setProgress(2);
  }, [setProgress]);

  let maxLength = 4;
  function handleMaxPinLength(event) {
    const newValue = event.target.value;
    if (newValue.length <= maxLength) {
      setOtp(newValue);
    }
  }

  const handleClick = async() => {
    console.log("otp code" , otp_check.data.data.code);
    console.log("otp" , otp);
    if(otp_check.data.data.code== otp){
      setStep(3);
    }
    else{
      toast.error("Invalid OTP");
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="z-10">
           <form
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col mt-3 items-center shadow-lg h-[100%] bg-white  pb-8  rounded-[20px] z-10 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px]  max-[767px]:w-[100%]  lg:overflow-y-scroll lg:overscroll-none"
      >
     
        <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] max-[767px]:w-[100%]">
          {/* <img alt="" src={profileIcon} /> */}
          <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
            Sign up as a Therapist
          </h1>
          <p>Enter your OTP to proceed.</p>
        </div>

        <div className="max-[767px]:w-[90%]">
          <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
            <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
              OTP
            </p>
            <div className="flex items-center">
              <input
                type="number"
                required
                
                value={otp}
                onChange={ handleMaxPinLength}
                className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px]"
                placeholder="Enter Your OTP"
              />
            </div>
          </div>
          <div>
          <button
            type="button"
            onClick={handleClick}
            className="bg-[#1A6A73] text-center w-[100%] py-[4%] mt-[5%] text-white rounded-[30px] max-[1130px]:rounded-[20px]"
          >
            Continue
          </button>
        </div>
        </div>

     
      
      </form>
    </form>
  );
};

export default SignupFormOtp;
