import { useNavigate } from 'react-router-dom'

const ChatHeader = ({ currentChat }) => {

  const navigate = useNavigate();
  // const { data:details, isSuccess } =  useGetClientDetailsQuery(id);

  return (
    <div className={`p-6 flex items-center justify-between space-x-3 w-full border-b border-[#E2E4E9]`}>
      <img
        className="w-10 h-10 rounded-full flex-shrink-0"
        src={currentChat?.user_id?.avatar}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
        }}
        onError={(e) => {
          e.target.onerror = Empty;
          e.target.src = Empty
        }}
        alt="profile"
      />
      <div className={`flex-1 flex items-center space-x-3 truncate`}>
        <span className="flex-1 inline-flex flex-col text-sm truncate">
          <span className={`font-semibold truncate text-lg`}>
            {currentChat?.user_id?.name ?? '-- --'}
          </span>
          <span className={`text-sm text-[#475467]`}>{currentChat?.user_id?.email ?? '--'}</span>
        </span>
      </div>
      <button onClick={() => navigate(`/dashboard/client_list/${currentChat?.user_id?.id}`)} className='text-sm bg-[#20232D] text-white py-3 px-4 rounded-full'>
        View Profile
      </button>
    </div>
  )
}

export default ChatHeader