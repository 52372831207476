import { useNavigate } from "react-router-dom";
import AvatarFallback from "../Images/avatar2.png";
import React, { useState, useEffect } from "react";
import { PDFDocument, rgb } from "pdf-lib";

// Utility function to format the date/time
const formatDateTime = (date) => {
    const optionsDate = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    
    return {
      date: new Intl.DateTimeFormat('en-US', optionsDate).format(date),
      time: new Intl.DateTimeFormat('en-US', optionsTime).format(date),
    };
};

export const WaitingRoom = ({ client, setIsActiveCall }) => {
    const navigate = useNavigate();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState("");
    const now = new Date();
    const { date, time } = formatDateTime(now);
    const [originalPdfUrl, setOriginalPdfUrl] = useState("../../src/assets/Assets/Pdfs/consent_form.pdf");

    useEffect(() => {
        const date = new Date();
        const formattedDate = date.toLocaleString();
        setCurrentDateTime(formattedDate);
    }, []);

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };

    // const handleSubmitClick = () => {
    //     if (isCheckboxChecked) {
    //         navigate("/x");
    //     } else {
    //         alert("Please select the checkbox to proceed further.");
    //     }
    // };

    const modifyPdf = async () => {
        const existingPdfBytes = await fetch(originalPdfUrl).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        const text = `User: ${client?.name || client?.user?.name || 'Client'}\nDate: ${date}, ${time}`;

        firstPage.drawText(text, {
            x: 50,
            y: 700, // Adjust position as needed
            size: 12,
            color: rgb(0, 0, 0),
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const modifiedPdfUrl = URL.createObjectURL(blob);

        window.open(modifiedPdfUrl);
    };

    return (
        <div className="no-scrollbar absolute top-0 bottom-0 left-0 right-0 wrapper h-dvh w-full flex items-center justify-center m-auto flex-col px-4 md:px-12">
            <div className="flex items-center flex-col gap-4 shadow-lg rounded-xl p-5 md:py-16 md:px-48 bg-white">
                <div className="flex flex-col justify-center items-center gap-4">
                    <img src={(client?.avatar || client?.user?.avatar) ?? AvatarFallback} alt="avatar" className="w-[85px]" />
                    <h2 className="border-b border-[#E2E4E9] pb-4 text-2xl font-bold capitalize pt-3 text-[#0A0D14]">Join Session With {(client?.name || client?.user?.name) ?? 'Client'}</h2>

                    {/* <div className="flex items-center gap-3">
                        {client?.name || client?.user?.name || 'Client'}
                    </div>
                    <div className="flex items-center gap-3">
                        {date}, {time}
                    </div>

                    <div className="flex items-center gap-4">
                        <input
                            type="checkbox"
                            id="confirmationCheckbox"
                            onChange={handleCheckboxChange}
                            className="mr-2"
                        />
                        <label htmlFor="confirmationCheckbox">
                        <small>I hereby agree to the
                            <button
                                onClick={modifyPdf}
                                className="rounded-[12px] text-black py-[12px] font-semibold px-2 underline"
                            >
                                consent policies
                            </button>
                            and acknowledge that my digital signature confirms my acceptance of these terms.
                        </small>
                        </label>
                    </div> */}

                    <div className="flex items-center gap-3">
                        <button onClick={() => navigate("/dashboard/appointments")} className="rounded-[50px] border border-[#E2E4E9] text-black text-sm  py-[10px] font-semibold px-4">
                            Go back to home
                        </button>

                        {/* <button
                            onClick={() => {
                                if (isCheckboxChecked) {
                                    setIsActiveCall(true);
                                } else {
                                    alert("Please select the checkbox to proceed further.");
                                }
                            }}
                            disabled={!isCheckboxChecked}
                            className={`rounded-[50px] text-white text-sm py-[10px] font-semibold px-4 ${
                                isCheckboxChecked ? "bg-[#1A6A73] border border-[#E2E4E9]" : "bg-gray-300 cursor-not-allowed"
                            }`}
                        >
                            Start Session
                        </button> */}
                        <button onClick={() => setIsActiveCall(true)} className="bg-[#1A6A73] rounded-[50px] border border-[#E2E4E9] text-white text-sm  py-[10px] font-semibold px-4">
                        Start Session
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
