import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    useClearAllNotificationsMutation, 
    useGetAllNotificationsQuery, 
    useLazyReadNotificationQuery, 
    useMarkAllNotificationsAsReadMutation 
} from '@/App/services/notificationSlice';
import { toast } from 'sonner';
import moment from 'moment';
import Loader, { FormLoader } from '@/Components/loader';
import empty from "../../Images/noWorksheet.png";
import * as Icon from 'react-feather'

const NotificationPopOver = ({ setOpenModal, setSessionDetails, close }) => {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { data:notifications, isLoading, refetch } = useGetAllNotificationsQuery();
    const [ markAllNotificationsAsRead, { isLoading:markAllLoading } ] = useMarkAllNotificationsAsReadMutation();
    const [ clearAllNotifications, { isLoading:clearingLoading } ] = useClearAllNotificationsMutation();
    const [ trigger, {} ] = useLazyReadNotificationQuery()

    const handleMarkAll = async() => {
        try {
            const res = await markAllNotificationsAsRead();
            toast.success(res?.data?.message)
        } catch(err){
            toast.error(err?.data?.message)
        }
    }

    const handleClearAll = async() => {
        try {
            const res = await clearAllNotifications();
            console.log(res)
            toast.success(res?.data?.message)
        } catch(err){
            toast.error(err?.data?.message)
        }
    }

  return (
    <div className='w-full flex flex-col bg-white border-[1px] border-[#E2E4E9] z-40 rounded-xl gap-4 max-h-[400px] min-h-[400px] min-w-[350px] overflow-auto no-scrollbar'>
        <header className='w-full flex items-center justify-between border-b border-[#E2E4E9] p-4'>
            <p className='text-base font-medium text-[#0A0D14]'>Notifications</p>
            <Icon.X className='cursor-pointer' color='#0A0D14' onClick={() => close()} size={12} />
        </header>

        <main className='flex flex-col w-full gap-2'>
            <div className="w-full flex items-center justify-end px-4 gap-3">
              <button onClick={handleMarkAll} className={`rounded-[50px] border-[1px] border-[#E2E4E9] bg-none text-[#525866] text-sm  py-[10px] px-4 whitespace-nowrap ${ !notifications?.data?.length ? 'hidden' : 'block' }`}>
                  { markAllLoading ? <FormLoader/>: 'Mark all as Read' }
              </button>

              <button onClick={handleClearAll} className={`rounded-[50px] border-[1px] border-[#ff0000] bg-none text-[#ff0000] text-sm  py-[10px] px-4 whitespace-nowrap ${ !notifications?.data?.length ? 'hidden' : 'block' }`}>
                {clearingLoading ? <FormLoader /> : ' Clear all' }
              </button>
            </div>
                {
                    isLoading ?
                    <Loader />
                    :
                    !notifications?.data?.length?
                    <div className='flex items-center justify-center m-auto relative w-full h-full p-6 overflow-y-auto'>
                        <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                            <img src={empty} className="w-[108px]" alt="empty" />
                            <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                               Notifications will appear here. <br />
                            </p>
                        </div>
                    </div>
                    :
                    <section className='w-full flex items-start flex-col divide-y divide-[#E2E4E9]'>
                        {
                            notifications?.data?.map((item) => (
                                <NotifyItem
                                    key={item.id}
                                    notifyId={item.id}
                                    title={item?.title}
                                    message={item.message}
                                    date={moment(item?.created_at).calendar()}
                                    status={!item?.read_at ? "unread" : "read"}
                                    read={item?.read_at}
                                    type={item?.type}
                                    dataId={item?.data_id}
                                    pathname={pathname}
                                    navigate={navigate}
                                    close={close}
                                    setOpenModal={setOpenModal}
                                    setSessionDetails={setSessionDetails}
                                    trigger={trigger}
                                    refetch={refetch}
                                />
                            ))
                        }
                    </section>
                }
        </main>
    </div>
  )
}

export default NotificationPopOver

const NotifyItem = ({ notifyId, status, title, date, message, read, type, dataId, pathname, navigate, close, setOpenModal, setSessionDetails, trigger, refetch }) => {

    // ["session", "client", "conversation", "worksheet"]

    const destinationMap = {
        'session': '/dashboard/appointments',
        'client': `/dashboard/client_list/${dataId}`,
        'conversation': `/dashboard/messages`,
        'worksheet': `/dashboard/resources`,
        'welness_course': `/dashboard/resources/article/${dataId}`
    }

    const statusMap = {
        "read": '#979292',
        "unread": '#04AF70',
    }

    return (
        <div className='w-full p-4 flex items-start gap-4 bg-[#e2e4e949] cursor-pointer'>
            {/* <SessionInfo
                openModal={openModal}
                setModal={setOpenModal}
                sessionId={sessionDetails}
            /> */}
            <span className={`p-3 rounded-full ${ status === 'read' ? 'bg-[#97929239]' : 'bg-[#aeefae43]' } `}>
                <Icon.Bell stroke={statusMap[status] ?? '#979292'} size={15} />
            </span>
            <article className='flex flex-col gap-1'>
                <p className='text-xs leading-5'><b>{title ?? 'No title'}:</b> {message} 
                    {
                        type === 'session' ?
                        <span onClick={ () => { setSessionDetails(dataId); setOpenModal((prev) => !prev); trigger(notifyId); refetch()} } style={{ color: `${statusMap[status]}` }} className='pl-1 underline'>
                            View {type}.
                        </span>
                        :
                        <span onClick={ () => {navigate(`${ type ? destinationMap[type] : pathname }`, { state: dataId }); trigger(notifyId); refetch(); close();} } style={{ color: `${statusMap[status]}` }} className='pl-1 underline'>
                            { type === 'welness_course' ? 'View Course' : `View ${type}` }
                        </span>
                    }
                </p>
                <span className='text-xs text-[#979292]'>{date}</span>
            </article>
        </div>
    )
}