import { apiSlice } from "../api/apiSlice";

export const settingsApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadProfileImg: builder.mutation({
      query: ({ formData }) => ({
        url: `/therapist/profile/upload-image`,
        method: "post",
        body: formData,
      }),
    }),
    settingsData: builder.query({
      query: () => ({
        url: `/therapist/profile/get-profile`,
        method: "get",
      }),
    }),
    getDocuments: builder.query({
      query: () => ({
        url: `/therapist/documents/list`,
        method: "get",
      }),
    }),
    updatePassword: builder.mutation({
      query: (passcodes) => ({
        url: `/therapist/profile/update-password`,
        method: "post",
        body: { ...passcodes },
      }),
    }),
    editSettings: builder.mutation({
      query: (preferences) => ({
        url: `/therapist/preferences`,
        method: "post",
        body: { ...preferences },
      }),
    }),
    deleteAccount: builder.mutation({
      query: (password) => ({
        url: `/therapist/profile/delete-account`,
        method: "DELETE",
        body: { ...password },
      }),
    }),
  }),
});

export const {
  useUploadProfileImgMutation,
  useSettingsDataQuery,
  useUpdatePasswordMutation,
  useEditSettingsMutation,
  useDeleteAccountMutation,
  useGetDocumentsQuery,
} = settingsApiSplice;
