import { useState } from "react";
import Footer from "../Components/Footer";
import JoinMentra from "../Components/JoinMentra";
import creator4 from "../assets/Assets/Images/creator4.png";
import creator5 from "../assets/Assets/Images/creator5.png";
import creator6 from "../assets/Assets/Images/creator6.png";
import starIcon from "../assets/Assets/Svgs/starIcon.svg";
import AppStore from "../assets/Assets/apple.png";
import PlayStore from "../assets/Assets/play.png";
import { Link } from "react-router-dom";

import arrowRight from "../assets/Assets/Svgs/arrowRight.svg";
import "../Components/SignupForms/style.css";
import Title from "../Components/Title";
import Waitlist from "../Components/Waitlist";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const MentraForWork = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  return (
    <div className="bg-[#FFFDF2]">
      <Title title={"Work"} />
      <div className="bg-[#FFFDF2] pt-[100px] max-[767px]:pt-16">
        <div
          style={{ backgroundImage: "url(/Assets/Images/CreatorBg.png)" }}
          className="text-center  w-[100%] bg-cover mt-14 tablet:mt-[2%]  bg-no-repeat max-[767px]:h-[100%] tablet:pb-[80px]"
        >
          <button className="philosopher bg-[none]  text-[#1A6A73] border-[1px] border-[#1A6A73] rounded-[50px] py-[8px] px-4  font-semibold max-[767px]:p-3 max-[767px]:text-[14px] ">
            Mentra For Work
          </button>
          <h1 className="philosopher text-[56px] mt-[2%] font-bold max-[767px]:text-[32px] max-[767px]:mt-[5%] leading-[65px] max-[767px]:leading-[40px]">
            Elevate Workplace <br />
            Well-Being with <br />
            Mentra for Work.
          </h1>
          <h1 className="text-[18px] px-[7vw] mt-5 tablet:mt-[2%] max-[767px]:text-[16px]">
            Explore how Mentra for Work brings a holistic <br /> approach to
            employee well-being, creating a positive{" "}
            <br className="hidden tablet:block" /> and thriving workplace
            environment.
          </h1>
        </div>

        <div className="flex justify-center px-[6vw] laptop:px-[20%] mt-[2%] pb-[5%] items-center max-[767px]:px-[5%] max-[767px]:flex-col-reverse max-[767px]:mt-[10%] max-[1920px]:mt-[2%]">
          <div className="w-[49%] flex justify-start max-[767px]:max-w-[430px] max-[767px]:w-[80%] max-[767px]:mt-10">
            <img alt="creator" width={500} src={creator4} />
          </div>
          <div className="w-[49%] tablet:ml-12 max-[767px]:w-[100%]">
            <img
              alt="star"
              className="mb-[7%] max-[767px]:mb-[3%] max-[767px]:w-[10%]"
              src={starIcon}
            />
            <h1 className="text-[24px] leading-snug font-semibold mb-[3%]  max-[767px]:text-[24px] max-[767px]:w-[100%]">
              Employee Wellbeing Meets Workplace Innovation
            </h1>
            <h1 className="text-[16px] text-[#555562] mb-[3%]  max-[767px]:text-[16px] max-[767px]:w-[100%]">
              Elevate your workplace with Mentra. Our platform is designed to
              enhance employee well-being, contributing to higher satisfaction,
              increased engagement, and a happier, more resilient workforce.
            </h1>

            {/* <div
              onClick={openModal}
              className="flex bg-[#20232D] text-[16px] duo:text-[16px] rounded-[50px] pr-[15px] pl-5 h-[45px] w-fit mt-[5%] "
            > */}
              {/* <button type="button" className=" text-[white] text-[16px] w-fit">
                <p className="text-nowrap">Waitlist here</p>
              </button> */}
                <div className="flex flex-col items-start sm:flex-row sm:items-center gap-4">
            <Link to="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={AppStore} className="w-48" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={PlayStore}   className="w-44"/>
            </Link>
          </div>
              {/* <img alt="arrow" width={20} className="ml-2" src={arrowRight} /> */}
            {/* </div> */}
          </div>
        </div>

        <div className="flex justify-center px-[6vw] laptop:px-[20%] py-[5%] items-center max-[767px]:px-[5%] max-[767px]:flex-col">
          <div className="w-[49%] tablet:mr-12  max-[767px]:w-[100%]">
            <img
              alt="star"
              className="mb-[7%] max-[767px]:mb-[3%] max-[767px]:w-[10%]"
              src={starIcon}
            />
            <h1 className="text-[24px] leading-snug  font-semibold mb-[3%] w-[70%] max-[767px]:text-[24px] max-[767px]:w-[100%]">
              Tailored Solutions for Companies
            </h1>
            <h1 className="text-[16px] text-[#555562] mb-[3%] max-[767px]:text-[16px] max-[767px]:w-[100%]">
              Mentra offers tailored solutions to meet the unique needs of your
              company. From company-wide access to personalized well-being
              programs, empower your organization with tools that make a
              positive impact on employee mental health.
            </h1>
            <div className="flex flex-col items-start sm:flex-row sm:items-center gap-4">
            <Link to="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={AppStore} className="w-48" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={PlayStore}   className="w-44"/>
            </Link>
          </div>
          </div>

          <div className="w-[49%] flex justify-start max-[767px]:w-[80%] max-[767px]:max-w-[430px] max-[767px]:mt-10">
            <img alt="creator" width={500} src={creator5} />
          </div>
        </div>
      </div>

      {/* <div className="text-center py-[5%] bg-[#F9F7F3]">
        <h1 className="text-[20px] text-[#626366] font-semibold max-[767px]:text-[16px]">
          800 companies have already use Mentra
        </h1>
        <div className="flex justify-center items-center mt-[3%] max-[767px]:flex-wrap">
          <div className="mx-[2%] max-[767px]:w-[30%] max-[767px]:mx-[5%] max-[767px]:mt-[5%]">
            <Image src={company1} />
          </div>
          <div className="mx-[2%] max-[767px]:w-[30%] max-[767px]:mx-[5%] max-[767px]:mt-[5%]">
            <Image src={company2} />
          </div>
          <div className="mx-[2%] max-[767px]:w-[30%] max-[767px]:mx-[5%] max-[767px]:mt-[5%]">
            <Image src={company3} />
          </div>
          <div className="mx-[2%] max-[767px]:w-[30%] max-[767px]:mx-[5%] max-[767px]:mt-[5%]">
            <Image src={company4} />
          </div>
          <div className="mx-[2%] max-[767px]:w-[30%] max-[767px]:mx-[5%] max-[767px]:mt-[5%]">
            <Image src={company5} />
          </div>
        </div>
      </div> */}

      <div className="flex justify-center px-[6vw] laptop:px-[20%] py-[5%] items-center bg-[#F9F7F3] mb-0 tablett:mb-8 max-[767px]:px-[5%] max-[767px]:flex-col-reverse">
        <div className="w-[49%] flex justify-start max-[767px]:w-[80%] max-[767px]:max-w-[430px] max-[767px]:mt-10">
          <img alt="creator" width={500} src={creator6} />
        </div>
        <div className="w-[49%] tablet:ml-12 max-[767px]:w-[100%]">
          <img
            alt="creator"
            className="mb-[7%] max-[767px]:mb-[3%] max-[767px]:w-[10%]"
            src={starIcon}
          />
          <h1 className="text-[24px] leading-snug  font-semibold mb-[3%]  max-[767px]:text-[24px] max-[767px]:w-[100%]">
            Explore the Advantages of Incorporating Mentra for Work into Your
            Organization's Culture.
          </h1>
          <h1 className="text-[16px] text-[#555562] mb-[3%]  max-[767px]:text-[16px] max-[767px]:w-[100%]">
            Elevate your workplace with Mentra. Our platform is designed to
            enhance employee well-being, contributing to higher satisfaction,
            increased engagement, and a happier, more resilient workforce.
          </h1>
          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-4">
            <Link to="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={AppStore} className="w-48" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noreferrer noopener">
              <img alt="app-links" src={PlayStore}   className="w-44"/>
            </Link>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
      </AnimatePresence>
      <JoinMentra />
      <Footer />
    </div>
  );
};

export default MentraForWork;
