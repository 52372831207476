import React from 'react'
import { useDispatch } from 'react-redux'
import { apiSlice } from '@/App/api/apiSlice'
import { useLazyShowConversationDetailsQuery } from '@/App/services/messagesSlice'
import moment from 'moment'
import user from '../../../assets/Assets/Images/Avatar.png'
import * as Icon from "react-feather"

export const ConversationCard = ({onClick, chatId, read, lastMessage, name, activeConvo, avatar, time}) => {

    const dispatch = useDispatch()
    const prefetchMessages = () => chatId && dispatch(apiSlice.endpoints.getMessages.initiate(chatId));
    const prefetchUnreadMessages = apiSlice.usePrefetch("unreadMessages");
    const [ trigger ] = useLazyShowConversationDetailsQuery(chatId);

  return (
    <div
        onMouseEnter={() => prefetchMessages()}
        onClick ={() => { onClick(); trigger(chatId); prefetchUnreadMessages(); }}
        className={` ${ activeConvo ? 'bg-[#F6F8FA]' : '' } rounded flex items-center justify-center flex-col space-y-4 cursor-pointer w-full hover:bg-[#F6F8FA] px-3 py-4`}
    >
        <div className='flex items-start justify-between space-x-3 w-full'>
            <div className= 'relative rounded-full'>
                {/* {
                    !read ?
                    <span className='absolute top-0.5 right-0.5'>
                        <Icon.Circle 
                            size={10} 
                            fill="#00FF00" 
                            strokeWidth={0}
                            color= "#FFF"
                        />
                    </span>
                    :
                    null
                } */}
                <img
                    className="w-10 h-10 rounded-full flex-shrink-0"
                    src={ avatar }
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        objectFit: "cover",
                    }}
                    onError={(e) => {
                        e.target.onerror = user;
                        e.target.src =  user;
                    }}
                    alt="profile"
                />
            </div>
            <div className={`flex-1 flex items-center space-x-3 truncate`}>
                <span className="flex-1 inline-flex flex-col text-sm truncate gap-2">
                    <span className="text-[#525866] truncate text-xs">
                        {name}
                    </span>
                    <p className='text-[#0A)D14] text-sm w-full text-left truncate'>
                        {lastMessage}
                    </p>
                </span>
            </div>
            <div className='flex items-center gap-1'>
                <span className='text-xs text-[#475467]'>{moment(time).format("dddd")}</span>
                <span className='text-xs text-[#475467]'>{moment(time).format("LT")}</span>
            </div>
        </div>
    </div>
  )
}