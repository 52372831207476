import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/Components/ui/dialog";
import { useState } from "react";
import calendar from "../../../Images/calendar.png";
import calendar2 from "../../../Images/calendar2.png";

import alarm from "../../../Images/alarm.png";
import timeImg from "../../../Images/time.png";

import { Label } from "@/Components/ui/label";
import { format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/Components/ui/button";
import { Calendar } from "@/Components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import ConfirmReschedule from "./ConfirmReschedule";
import { useRescheduleSessionMutation } from "@/App/services/sessionSlice";
import moment from "moment";
import { toast } from "sonner";
import { useGetBlockedDatesQuery } from "@/App/services/appointmentsApiSlice";

const yesterdaysDate = moment().subtract(1, "days").format();


function Reschedule({ openRescheduleModal, setRescheduleModal, sessionId, session, setPage }) {

  const [confirm, setConfirm] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState("");
  const [ampm, setAmPm] = useState("AM");
  const [duration, setDuration] = useState("");
  const [reason, setReason] = useState('')

  const { data:blockedDates } = useGetBlockedDatesQuery()
  const [ rescheduleSession, { isLoading } ] = useRescheduleSessionMutation();

  const blocked = blockedDates && blockedDates?.data?.map((date) => {
    return {
      from: date?.from_date,
      to: date?.to_date,
    }
  })

  const disabledDays = blocked ? [
    { from: new Date(2000, 0, 1), to: yesterdaysDate },
     ...blocked
  ] : null;



  const handleRescheduleSession = async() => {
    const isCurrentDateOrOlder = moment(date).isSameOrBefore();
    const currentTime = moment().format("HH:MM")

    if(currentTime > time && isCurrentDateOrOlder){
      return toast.error("You can't select a passed time")
    }
    try{
      const updatedSession = {
        date: moment(date).format('YYYY-MM-DD'),
        time: time,
        duration: duration,
        reason: reason
      }
      const res = await rescheduleSession({ id: sessionId, update: { ...updatedSession } }).unwrap()
      toast.success(res.message);
      setRescheduleModal(false);
      
    } catch(err){
      toast.error(err?.data?.message)
    }
  }

  function handleReschedule() {
    setConfirm(true);
  }

  React.useEffect(() => {
    setConfirm(() => false);
  }, [openRescheduleModal])

  return (
    <div>
      <Dialog open={openRescheduleModal} onOpenChange={setRescheduleModal}>
        {confirm ? (
          <ConfirmReschedule
            setModal={setRescheduleModal}
            setPage={setPage}
            setConfirm={setConfirm}
            isLoading={isLoading}
            handleSubmit={handleRescheduleSession}
            session={session}
            date={date}
            duration={duration}
            time={time}
          />
        ) : (
          <DialogContent
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="w-[90vw] overflow-y-scroll min-h-[ max-h-[80vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]"
          >
            <DialogHeader>
              <DialogTitle className="text-left text-[#0A0D14]">
                Reschedule Session
              </DialogTitle>
              <DialogDescription className="text-[#525866] text-left">
                Choose a new date and time for the session.
              </DialogDescription>
            </DialogHeader>
            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />

            <div className="flex justify-center items-center gap-[2%] rounded-[8px] p-[10px] bg-[#F6F8FA] *:flex *:flex-col *:justify-start *:items-start *:w-[49%]">
              <div>
                <h4 className="text-xs text-[#525866]">Date</h4>
                <div className="flex justify-start items-start gap-[6px] mt-2">
                  <img className="w-5" src={calendar} alt="date" />
                  <h2 className="text-sm text-[#0A0D14]">
                    {moment(session?.data?.starts_at).format("dddd,  MMMM Do YYYY")}
                  </h2>
                </div>
              </div>
              <div>
                <h4 className="text-xs text-[#525866]">Time</h4>
                <div className="flex justify-start items-start gap-[6px] mt-2">
                  <img className="w-5" src={timeImg} alt="time" />
                  <h2 className="text-sm text-[#0A0D14]">{moment(session?.data?.starts_at).format("LT")}</h2>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex flex-col justify-start items-start gap-2">
                <Label
                  htmlFor="name"
                  className="text-left font-semibold text-[#0A0D14]"
                >
                  New Date
                </Label>

                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                      )}
                      style={{
                        outline: "none",
                        border: "1.5px solid #E2E4E9",
                        boxShadow: "none",
                      }}
                    >
                      <img
                        className="w-5 ml-[-4px] mr-[9px]"
                        src={calendar2}
                        alt="time"
                      />
                      {date ? (
                        format(date, "PPP")
                      ) : (
                        <span className="text-[#525866]">Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={date}
                      onSelect={setDate}
                      initialFocus
                      disabled={disabledDays}
                      className='disabled:cursor-pointer'
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex flex-col justify-start items-start gap-2">
                <Label
                  htmlFor="name"
                  className="text-left font-semibold text-[#0A0D14]"
                >
                  Session Time
                </Label>

                <div className="flex w-full">
                  <Select
                    value={time}
                    onValueChange={(value) => setTime(value)}
                  >
                    <SelectTrigger className=" text-[#525866] w-full rounded-[6px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                      <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                        <img className="w-5" src={timeImg} alt="time" />
                        <SelectValue placeholder="Select a time" />
                      </div>
                    </SelectTrigger>
                    <SelectContent className="max-h-[165px]">
                      <SelectItem value="1:00">1:00</SelectItem>
                      <SelectItem value="2:00">2:00</SelectItem>
                      <SelectItem value="3:00">3:00</SelectItem>
                      <SelectItem value="4:00">4:00</SelectItem>
                      <SelectItem value="5:00">5:00</SelectItem>
                      <SelectItem value="6:00">6:00</SelectItem>
                      <SelectItem value="7:00">7:00</SelectItem>
                      <SelectItem value="8:00">8:00</SelectItem>
                      <SelectItem value="9:00">9:00</SelectItem>
                      <SelectItem value="10:00">10:00</SelectItem>
                      <SelectItem value="11:00">11:00</SelectItem>
                      <SelectItem value="12:00">12:00</SelectItem>
                      <SelectItem value="13:00">13:00</SelectItem>
                      <SelectItem value="14:00">14:00</SelectItem>
                      <SelectItem value="15:00">15:00</SelectItem>
                      <SelectItem value="16:00">16:00</SelectItem>
                      <SelectItem value="17:00">17:00</SelectItem>
                      <SelectItem value="18:00">18:00</SelectItem>
                      <SelectItem value="19:00">19:00</SelectItem>
                      <SelectItem value="20:00">20:00</SelectItem>
                      <SelectItem value="21:00">21:00</SelectItem>
                      <SelectItem value="22:00">22:00</SelectItem>
                      <SelectItem value="23:00">23:00</SelectItem>
                      <SelectItem value="00:00">00:00</SelectItem>
                    </SelectContent>
                  </Select>
                  {/* <Select
                    value={ampm}
                    onValueChange={(value) => setAmPm(value)}
                  >
                    <SelectTrigger className=" text-[#525866] w-[40%] rounded-r-[6px] rounded-l-[0px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="AM">AM</SelectItem>
                        <SelectItem value="PM">PM</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select> */}
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-2">
                <Label
                  htmlFor="name"
                  className="text-left font-semibold text-[#0A0D14]"
                >
                  Session Duration
                </Label>

                <div className=" w-full">
                  <Select
                    value={duration}
                    onValueChange={(value) => setDuration(value)}
                  >
                    <SelectTrigger className=" text-[#525866] w-full rounded-[6px]  border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                      <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                        <img className="w-5" src={alarm} alt="time" />
                        <SelectValue placeholder="Select the duration" />
                      </div>
                    </SelectTrigger>
                    <SelectContent className="">
                    <SelectItem value="30">30 mins</SelectItem>
                      <SelectItem value="60">1 hour</SelectItem>
                      <SelectItem value="90">1:30 mins</SelectItem>
                      <SelectItem value="120">2 hours</SelectItem>
                      <SelectItem value="180">3 hours</SelectItem>
                      <SelectItem value="240">4 hours</SelectItem>
                      <SelectItem value="300">5 hours</SelectItem>
                      <SelectItem value="360">6 hours</SelectItem>
                      <SelectItem value="420">7 hours</SelectItem>
                      <SelectItem value="480">8 hours</SelectItem>
                      <SelectItem value="540">9 hours</SelectItem>
                      <SelectItem value="600">10 hours</SelectItem>
                      {/* <SelectItem value="more">More than 4 hours</SelectItem> */}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-2">
                <Label
                  htmlFor="name"
                  className="text-left font-semibold text-[#0A0D14]"
                >
                  Reason
                </Label>

                <div className=" w-full">
                  <textarea
                    onChange={(event) => setReason(event.target.value)}
                    style={{
                      outline: "none",
                      border: "1.5px solid #E2E4E9",
                      boxShadow: "none",
                    }}
                    className="w-full min-h-[140px] text-[#525866] text-sm  rounded-[10px] p-3"
                    placeholder="Let the other party know why you are rescheduling this session"
                  />
                </div>
              </div>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />
              <div className="w-full flex justify-end items-center">
                <button
                  onClick={handleReschedule}
                  className="text-white font-meduim bg-[#1A6A73] text-sm rounded-[50px] py-[10px] px-4 "
                >
                  Reschedule Session
                </button>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

export default Reschedule;
