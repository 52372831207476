import pass from "../../Images/pass.png";
import fail from "../../Images/fail.png";
import updn from "../../Images/updn.png";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/Components/ui/table";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function ClientTable({currentPageData, handleTableSort}) {

  const navigate = useNavigate()

  return (
    <Table classme="">
      {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
      <TableHeader className="bg-[#F6F8FA] hover:bg-[#F6F8FA]">
        <TableRow className="*:text-[#525866]">
          <TableHead className=" w-[23%] tablet:w-[25%]">
            <div
              onClick={() => handleTableSort("name")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Name</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="">
            <div
              onClick={() => handleTableSort("age")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Age</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left">
            <div
              onClick={() => handleTableSort("last")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Last Session</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left">
            <div
              onClick={() => handleTableSort("next")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">Next Session</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
          <TableHead className="text-left w-[100px]">
            <div
              onClick={() => handleTableSort("status")}
              className="flex cursor-pointer items-center justify-start gap-1"
            >
              <p className="text-sm capitalize text-[#525866]">status</p>
              <img src={updn} alt="sort" className="h-5" />
            </div>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {!currentPageData?.length <= 0 ? (
          currentPageData?.map((data) => (
            <TableRow
              onClick={() => navigate(`${data?.id}`)}
              className="*:min-w-[150px] cursor-pointer"
              key={data.id}
            >
              <TableCell className="font-semibold text-[#0A0D14]  capitalize">
                <div className="flex justify-start capitalize items-center gap-3">
                  <img src={data.avatar} className="w-10" alt="avatar" />
                  <p>{data.name}</p>
                </div>
              </TableCell>
              <TableCell className="text-[#0A0D14] font-medium capitalize">
                {moment().diff(moment(`${data.birth_year +'-01-01'}`, "YYYYMMDD"), 'years')}
              </TableCell>
              <TableCell className="text-left text-[#0A0D14] font-medium">
                {data.last_session ? moment(data.last_session).format('ddd, MMM Do YYYY, h:mm A') : "N/A"}
              </TableCell>
              <TableCell className="text-left text-[#0A0D14] font-medium">
                {data.next_session ? moment(data.next_session).format('ddd, MMM Do YYYY, h:mm A') : "N/A"}
              </TableCell>
              <TableCell className="text-left text-[#0A0D14] font-medium">
                <div className="flex justify-center items-center w-fit  gap-1 capitalize border boder-[#E2E4E9] rounded-[6px] py-1 pr-2 pl-1">
                  {
                    data?.status ?
                    <img
                      src={data?.status ? data?.status.toLowerCase() === "active" ? pass : fail : ''}
                      className="w-4"
                      alt="status"
                    />
                    : null
                  }
                  <p>{data?.status ?? '--'}</p>
                </div>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow className="w-full grid place-items">
            <p className="text-[#0A0D14]  text-center text-xl font-semibold capitalize mt-8">
              No clients found!
            </p>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default ClientTable;
