import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
// import SignupFormFive from "./SignupFormFive";
import { useSignUpContext } from "./SignUpContext";

const SignupFormFour = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(5);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.current_workplace);
  const [filled, setFilled] = useState(true);

  const handleClick = () => {
    if (option) {
      setSignupData((prevData) => ({
        ...prevData,
        current_workplace: option,
      }));
      setStep(6);
    } else {
      setFilled(false);
    }
  };
  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col shadow-lg items-center h-[100%] bg-white pb-8  rounded-[20px] mt-3 z-1 max-[767px]:w-[100%] tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] max-[767px]:pb-8  max-[1290px]:pb-[2%]  lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
            Where do you currently work?
          </h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className=" w-full">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>
          <div className="flex flex-col justify-center items-center gap-4 mb-4 w-full">
            <button
              value="Private Clinic/Hospital"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Private Clinic/Hospital"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Private Clinic/Hospital
            </button>
            <button
              value="Government Clinic/Hospital"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Government Clinic/Hospital"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Government Clinic/Hospital
            </button>
            <button
              value="Online Platform"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Online Platform" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Online Platform
            </button>
            <button
              value="Currently Unemployed"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Currently Unemployed"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Currently Unemployed
            </button>
            <button
              value="Other"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Other" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Other
            </button>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormFour;
