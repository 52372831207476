import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";

import tasksImg from "../../Images/worksheet-task.png";
import quesImg from "../../Images/worksheet-ques.png";
import { useState } from "react";
import Question from "./Questions/Question";
import TaskWorksheet from "./Tasks/TaskWorksheet";

function WorkSheetWrapper({ openModal, setModal }) {
  const [openEdits, setEdits] = useState(false);
  const [data, setData] = useState({});
  const [sessionPage, setSessionPage] = useState(0);

  function handleCancel() {
    setSessionPage(0);
  }

  function handleClick(value) {
    setSessionPage(value);
    setModal(false);
  }

  return (
    <div className="">
      <Dialog open={openModal} onOpenChange={setModal}>
        <DialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="w-[90vw] min-h-[400px] max-h-[80vh] overflow-y-scroll max-w-[959px] pt-10 pb-6 duo:py-[50px] px-4 duo:px-6 border-none rounded-[16px]"
        >
          <DialogHeader>
            <DialogTitle className="text-center font-bold text-3xl text-[28px] tablet:text-[2.5rem] tablet:leading-[3.15rem] text-[#0A0D14]">
              Create New Worksheet
            </DialogTitle>
            <DialogDescription className="text-center text-base text-[#525866] ">
              Explore, pick, and customize templates to your needs.
            </DialogDescription>
          </DialogHeader>
          <div className="w-full  flex flex-wrap justify-center items-center gap-6 mt-6">
            <div
              onClick={() => handleClick(1)}
              className="w-full group h-[313px]  max-w-[316px] outline outline-1 hover:outline-2 cursor-pointer outline-[#CDD0D5] hover:outline-[#8CCED3] overflow-hidden rounded-[16px]"
            >
              <div className="h-[190px] pt-2 bg-[#F6F8FA] overflow-hidden w-full">
                <img src={quesImg} className="w-full" alt="template" />
              </div>
              <div className="bg-white  group-hover:bg-[#8CCED3] py-4 px-5 *:text-left">
                <h2 className="font-bold text-base text-[#0A0D14]">
                  Questionnaire
                </h2>
                <p className="text-[#868C98] line-clamp-3 group-hover:text-[#525866] font-normal pt-[6px] text-sm leading-[21px]">
                  No Worksheets Available. Start creating worksheets to tailor
                  therapy sessions to your clients' needs
                </p>
              </div>
            </div>
            <div
              onClick={() => handleClick(2)}
              className="w-full h-[313px] group  max-w-[316px] outline outline-1 hover:outline-2 cursor-pointer outline-[#CDD0D5] hover:outline-[#8CCED3] overflow-hidden rounded-[16px]"
            >
              <div className="h-[190px] pt-2 bg-[#F6F8FA] overflow-hidden w-full">
                <img src={tasksImg} className="w-full" alt="template" />
              </div>
              <div className="bg-white  group-hover:bg-[#8CCED3] py-4 px-5 *:text-left">
                <h2 className="font-bold text-base text-[#0A0D14]">
                  Weekly Tasks
                </h2>
                <p className="text-[#868C98]  line-clamp-3 group-hover:text-[#525866] font-normal pt-[6px] text-sm leading-[21px]">
                  No Worksheets Available. Start creating worksheets to tailor
                  therapy sessions to your clients' needs
                </p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {sessionPage === 1 ? <Question handleCancel={handleCancel} /> : sessionPage === 2 ? <TaskWorksheet handleCancel={handleCancel} /> : null}
    </div>
  );
}

export default WorkSheetWrapper;
