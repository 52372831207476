import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/Components/ui/dialog";
import { useCreateSessionNoteMutation, useGetAllSessionNotesQuery, useGetSessionNoteQuery } from "@/App/services/sessionSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { Textarea } from "@/Components/ui/textarea";
import { cn } from "@/lib/utils";
import { FormLoader } from "@/Components/loader";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import NoteCard from "./notecard";
import * as Icon from 'react-feather'
import moment from "moment";

// const [openModal, setModal] = useState(false);
const variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
};


const noteKeys = [ 
  'assessment_interventions', 'assessment_progress', 'assessment_observations', 
  'subjective_history', 'subjective_psychological', 'subjective_relevant', 
  'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary' 
]

function Overview({ details }) {
  
  const { id } = useParams()
  const [note, setNote] = useState({
    ...noteKeys
  });
  const [edit, setEdit] = useState(false)

  const { data: sessionNote} = useGetSessionNoteQuery(details?.last_session?.id ?? skipToken);
  const { data: alllNotes, isLoading: allNotesLoading } = useGetAllSessionNotesQuery(id ?? skipToken)
  const [ createSessionNote, { isLoading } ] = useCreateSessionNoteMutation();

  const  handleInputChange = (e) => {
    const value = e.target.value;
    setNote({
        ...note,
        [e.target.name]: value
    });
  };

  const handleCreateSessionNote = async (event) => {
    try {
        const finalNote = {
            session_id: details?.last_session?.id,
            ...note
        }
        const res = await createSessionNote({...finalNote}).unwrap();
        toast.success("Session Note saved successfully")

    } catch(error){
        console.log(error)
        toast.error(error?.data?.message)
    }
    setEdit(false)
  }


  useEffect(() => {
    setNote(() => sessionNote?.data );
  }, [sessionNote]);

  console.log(alllNotes)
  console.log(sessionNote)
  
  return (
    <motion.div
      key="hello"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="w-full bg-white border p-4 tablet:p-5 pt-5 rounded-[16px] border-[#E2E4E9]">
        <h3 className="text-[#0A0D14] text-base font-semibold">
          Therapist Interaction Summary
        </h3>
        <div className="flex flex-col mt-5 justify-center items-center gap-4 tablet:flex-row tablet:gap-[2%] *:w-full *:tablet:w-[32%]">
          <div className=" border p-5 space-y-3 rounded-[16px] border-[#E2E4E9]">
            <p className="text-sm text-[#525866]">Total Sessions</p>
            <h2 className="text-2xl  font-medium text-[#525866]">{details?.total_session ?? 'N/A'}</h2>
          </div>
          <div className=" border p-5 space-y-3 rounded-[16px] border-[#E2E4E9]">
            <p className="text-sm text-[#525866]">Average Session Length</p>
            <h2 className="text-2xl  font-medium text-[#525866]">{(details?.average_session_length && (details?.average_session_length).toFixed(0)) ??  0} minutes</h2>
          </div>
          <div className=" border p-5 space-y-3 rounded-[16px] border-[#E2E4E9]">
            <p className="text-sm text-[#525866]">Session Attendance</p>
            <h2 className="text-2xl  font-medium text-[#525866]">{ (details?.session_attendance &&  Math.ceil(details?.session_attendance).toFixed(0)) ?? 0}%</h2>
          </div>
        </div>
      </div>

      {/* Session highlights and notes */}
      <div className="w-full bg-white border mt-5 px-4 tablet:px-5 py-5 rounded-[16px] border-[#E2E4E9]">
        <header className="flex items-center justify-between w-full">
          <h3 className="text-[#0A0D14] text-base font-medium">
            Latest Session Highlight
          </h3>
          <h3 className="text-[#0a0d1495] text-sm font-medium">
            {moment(sessionNote && sessionNote?.data?.updated_at).format("MMMM Do YYYY")}
          </h3>
        </header>
        <div className="mt-5 ">
          <p className="text-xs text-[#525866]">Summary</p>
          <p className="text-sm pt-1 text-[#0A0D14]">
            {sessionNote?.data?.summary ?? 'N/A'}
          </p>
          {/* <button className="text-sm font-medium mt-2 underline text-[#1A6A73]">
            Read Full Note
          </button> */}
          <Dialog>
            <DialogTrigger className="text-sm font-medium mt-2 underline text-[#1A6A73]">
              Read Full Note
            </DialogTrigger>
            <DialogContent style={{ scrollbarWidth: "none", msOverflowStyle: "none" }} className="w-[90vw] overflow-y-scroll max-h-[80vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]">
              <DialogHeader>
                <DialogTitle className="text-left text-[#0A0D14]">
                  Session Note
                </DialogTitle>
              </DialogHeader>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2" />
                <div className="space-y-2">
                  <section className="flex flex-col gap-1">
                      <header className="flex items-center gap-3 text-lg font-semibold">Summary<Icon.Edit3 className="cursor-pointer" onClick={() => setEdit((prev) => !prev)} size={15} /></header>
                      <EditNoteInput 
                        name='summary'
                        edit={edit}
                        onChange={handleInputChange}
                        value={note?.summary ?? 'N/A'}
                        rows={1}
                      />
                    </section>
                    <section className="flex flex-col gap-3">
                      <header className="text-lg font-semibold flex items-center gap-3">Assessment <Icon.Edit3 className="cursor-pointer" onClick={() => setEdit((prev) => !prev)} size={15} /></header>
                      <ul className="flex flex-col gap-3">
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Interventions</span>
                          <EditNoteInput 
                            name={noteKeys[0]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys?.[0]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Progress and Response</span>
                          <EditNoteInput 
                            name={noteKeys[1]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys[1]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Observations</span>
                          <EditNoteInput 
                            name={noteKeys[2]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys[2]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                      </ul>
                    </section>
                    <section className="flex flex-col gap-3">
                      <header className="text-lg font-semibold flex items-center gap-3">Subjective <Icon.Edit3 className="cursor-pointer" onClick={() => setEdit((prev) => !prev)} size={15} /></header>
                      <ul className="flex flex-col gap-3">
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">History of Presenting Complaint</span>
                          <EditNoteInput 
                            name={noteKeys[3]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys?.[3]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Psychological Factors</span>
                          <EditNoteInput 
                            name={noteKeys[4]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys[4]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                        <li className="flex flex-col gap-1">
                          <span className="text-sm text-[#0A0D14]">Relevant Personal and Social History</span>
                          <EditNoteInput 
                            name={noteKeys[5]}
                            edit={edit}
                            onChange={handleInputChange}
                            value={note?.[noteKeys[5]] ?? 'N/A'}
                            rows={1}
                          />
                        </li>
                      </ul>
                    </section>
                    <section className="flex flex-col gap-3">
                    <header className="text-lg font-semibold flex items-center gap-3">Objective <Icon.Edit3 className="cursor-pointer" onClick={() => setEdit((prev) => !prev)} size={15} /></header>
                    <ul className="flex flex-col gap-3">
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Appearance</span>
                        <EditNoteInput 
                          name={noteKeys[6]}
                          edit={edit}
                          onChange={handleInputChange}
                          value={note?.[noteKeys?.[6]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Behavior</span>
                        <EditNoteInput 
                          name={noteKeys[7]}
                          edit={edit}
                          onChange={handleInputChange}
                          value={note?.[noteKeys[7]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Symptoms Observed</span>
                        <EditNoteInput 
                          name={noteKeys[8]}
                          edit={edit}
                          onChange={handleInputChange}
                          value={note?.[noteKeys[8]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                      <li className="flex flex-col gap-1">
                        <span className="text-sm text-[#0A0D14]">Risk Assessment</span>
                        <EditNoteInput 
                          name={noteKeys[9]}
                          edit={edit}
                          onChange={handleInputChange}
                          value={note?.[noteKeys[9]] ?? 'N/A'}
                          rows={1}
                        />
                      </li>
                    </ul>
                  </section>
                  {/* <div>
                    {details?.last_session?.note ?? '---'}
                  </div> */}
                </div>
                <button onClick={handleCreateSessionNote} className={`w-fit rounded-[50px] border bg-[#242628] text-[#ffffff] text-sm  py-[10px] font-semibold px-4`}>
                  {isLoading ? <FormLoader /> : 'Update Note'}
                </button>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    
      {/* all notes */}
      <section className="flex flex-col gap-3">
        {
          alllNotes?.data?.map((note) => (
            <NoteCard note={note} />
          ))
        }
      </section>
    </motion.div>
  );
}

export default Overview;

export const EditNoteInput = ({ label, edit, name, onChange, placeholder, value, rows }) => {
  return (
    <label className='w-full flex flex-col gap-2'>
      <label className='font-medium text-xs'>{label}</label>
      <NoteTextarea
        style={{
            outline: "none",
            border: `${ edit ? "1.5px solid #E2E4E9" : 'none' }`,
            boxShadow: "none",
            padding: `${ edit ? "" : '3px 0px' }`,
            // height: '150px !important'
        }}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        // onBlur={onBlur}
        rows={2}
        className="placeholder:text-sm focus:outline-none rounded-xl w-full text-xs text-tblack-100"
        placeholder={placeholder}
        readOnly={!edit}
      />
    </label>
  )
}

const NoteTextarea = React.forwardRef(({ className, onKeyDown, onBlur, readOnly, ...props }, ref) => {
  return (
    (<textarea
      className={cn(
        `flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background
        placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring
        focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`,
        className
      )}
      ref={ref}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      readOnly={readOnly}
      {...props} />)
  );
})
Textarea.displayName = "Textarea"

export { Textarea }
