import React, { useState } from 'react'
import { useGetClientListQuery } from '@/App/services/clientApiSlice';
import { motion } from 'framer-motion';
import { messageVariants } from '@/App/pages/Messages';
import { useCreateNewConversationMutation } from '@/App/services/messagesSlice';
import { toast } from 'sonner';
import Loader from '@/Components/loader';
import searchIcon from "../../Images/search.png";
import empty from "../../Images/noWorksheet.png";
import * as Icon from 'react-feather'

const NewMessageSearch = ({ user, closeModal, setCurrentChat }) => {

    const [search, setSearch] = useState("");

    const { data:clients, isLoading } = useGetClientListQuery({ search: search });
    const [ createNewConversation, { isLoading:createLoad } ] = useCreateNewConversationMutation()

    // const randomId = function(length = 6) {
    //     return Math.random().toString(36).substring(2, length+2);
    // };

    const handleSubmit = async(client) => {

        try {
            const details = {
                sender_id: user?.id,
                receiver_id: (client?.id).toString()
            }
            const res = await createNewConversation({...details}).unwrap();
            setCurrentChat(() => res?.data)
        } catch (error) {
            toast.error(error?.data?.message ?? "An Error Occurred")
            console.log(error)
        }
        closeModal()
    }

  return (
    <motion.div variants={messageVariants} className='w-full flex flex-col bg-white border-[1px] border-[#E2E4E9] z-40 rounded-xl gap-4'>
        <header className='w-full flex items-center justify-between border-b border-[#E2E4E9] p-4'>
            <p className='text-base font-medium text-[#0A0D14]'>New Message</p>
            <Icon.X className='cursor-pointer' color='#0A0D14' onClick={() => closeModal()} size={12} />
        </header>

        <main className='flex flex-col w-full gap-6 p-4'>
            <div className="h-[36px] relative w-full">
                <img
                    src={searchIcon}
                    alt="search"
                    className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
                />
                <input
                    style={{
                    outline: "none",
                    border: "1px solid #E2E4E9",
                    boxShadow: "none",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="text-[#0A0D14] size-full w-full text-sm rounded-[8px] py-2 pl-9 pr-8"
                />
            </div>

            <section className='w-full flex items-start flex-col gap-3 min-h-[400px] h-[400px] overflow-auto'>
                {
                    isLoading ?
                    <Loader />
                    :
                    !clients?.data.length ?
                    <div className='flex items-center justify-center m-auto relative w-full h-full p-6 overflow-y-auto'>
                        <div className="w-full  flex-col my-auto flex justify-center items-center gap-2">
                            <img src={empty} className="w-[108px]" alt="empty" />
                            <p className="mt-3 text-sm text-[#868C98] text-center w-1/3">
                                No Clients Found! <br />
                            </p>
                        </div>
                    </div>
                    :
                    clients?.data?.map((client) => (
                        <div onClick={() => handleSubmit(client)} key={client.id} className="w-full flex cursor-pointer justify-between items-center py-2 hover:bg-[#F6F8FA]">
                            <div className="w-full flex justify-start gap-2 items-center ">
                                <img style={{ backgroundColor: `${client?.avatar_background}` }} className="w-10 rounded-full" src={client?.avatar} alt="avatar" />
                                <h2 className="text-[#0A0D14] capitalize font-medium text-sm ">
                                    {client?.name}
                                </h2>
                            </div>
                            {/* {createLoad ? <span className='text-[#000000] text-sm p-2 animate-pulse'>...</span> : null} */}
                        </div>
                    ))
                }
            </section>
        </main>
    </motion.div>
  )
}

export default NewMessageSearch