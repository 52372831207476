import joinMentra from "../assets/Assets/Images/joinMetra.png";

import arrowRight from "../assets/Assets/Svgs/arrowRight.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import Waitlist from "../Components/Waitlist";
import AppStore from "../assets/Assets/apple.png";
import PlayStore from "../assets/Assets/play.png";
const JoinMentra = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const onStaging = window.location.href.includes("stagingdd");
  const onLocal = window.location.href.includes("localhostdd");
  return (
    <div id="join" className="bg-[#FFFDF2] px-[5vw] pt-14 pb-6 tablet:pt-6 tablet:pb-0 laptop:pt-10 laptop:pb-14 laptop:px-[15%] max-[767px]:px-[5%]  max-[767px]:pb-[3%]">
      <div className="flex justify-between gap-[4%] max-[767px]:gap-5 items-center px-[5%] bg-[#FFEEC9] rounded-[48px] py-[2%] tablet:py-[4%] max-[767px]:flex-col max-[767px]:rounded-[24px]">
        <div className="w-[50%] max-[767px]:w-[100%]">
          <h1 className="philosopher text-[38px] text-[#251909] leading-[45px] font-bold max-[1500px]:text-[34px] max-[767px]:text-[20px] max-[767px]:pt-[5%] max-[767px]:leading-[30px]">
            Join Mentra's Circle of Support and Be a Beacon of Positivity!🌟
          </h1>
          <p className="mt-[3%] text-[16px] max-[767px]:text-[14px]">
            Are you a coach ready to make a meaningful impact? Embrace the
            opportunity to connect with individuals seeking your guidance on
            their mental well-being journey.{" "}
          </p>

          {/* <Link to="/signup">
            <div
              onClick={onStaging || onLocal ? null : openModal}
              className="flex bg-[#1A6A73] text-white  justify-center items-center   text-[16px]  rounded-[30px] shadow-lg tablet:text-[16px]   font-normal laptop:text-[16px] laptop:h-[45px] tablet:h-[50px]  cursor pointer  pr-[15px] pl-5 w-fit h-[45px]  mt-[5%] "
            >
              {onStaging || onLocal ? (
                <button>Sign up as a therapist</button>
              ) : (
                <button onClick={openModal}>Waitlist here</button>
              )}
              <img
                className=" ml-2 w-[20px] "
                src={arrowRight}
                alt="arrow right icon"
              />
            </div>
          </Link> */}
          <div className="flex flex-col items-start mt-10 sm:flex-row sm:items-center gap-4">
                  <Link
                    to="https://apps.apple.com/ng/app/mentra/id6502996664"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img alt="app-links" src={AppStore} className="w-48"/>
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.yourmentra.mentra"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img alt="app-links" src={PlayStore} className="w-44"/>
                  </Link>
                </div>
        </div>
        <div className="w-[45%] laptop:w-[40%] flex justify-center max-[767px]:w-[90%] max-[767px]:max-w-[400px] max-[767px]:mx-auto max-[767px]:mt-[5%] max-[767px]:pb-[5%]">
          <img alt="join" src={joinMentra} />
        </div>
      </div>
      <AnimatePresence>
        {isModalOpen && <Waitlist setModalOpen={setModalOpen} />}
      </AnimatePresence>
    </div>
  );
};

export default JoinMentra;
