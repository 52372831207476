import searchIcon from "../../../Images/search.png";
import { useGetClientListQuery } from "@/App/services/clientApiSlice";
import { useEffect, useState } from "react";
import { LuLoader2 } from "react-icons/lu";
import Cookies from 'js-cookie'
import { useCreateWorksheetMutation } from "@/App/services/worksheetApiSlice";
import { FormLoader } from "@/Components/loader";
import { toast } from "sonner";


function AssignWorksheet({ handleCancel, nextStep }) {

  const [worksheet, setWorkSheet] = useState(null)
  const [search, setSearch] = useState('')
  const { data: clients, isLoading } = useGetClientListQuery({search});
  const [ createWorksheet, { isLoading:createLoading } ] = useCreateWorksheetMutation();

  async function skip () {
    try {
      const data = {
        ...worksheet, 
      }
      const saveWorksheet = await createWorksheet(data).unwrap();
      console.log(saveWorksheet);
      if (saveWorksheet?.success) {
        toast.success(saveWorksheet?.message);
        handleCancel();
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.data?.message)
    } 
  }
  console.log(worksheet)

  function handleNextStep(client) {
    try {
      const data = {
        id: client?.id,
        name: client?.name,
        avatar: client?.avatar,
        email: client?.email
      }
      Cookies.set('client', JSON.stringify(data), { expires: 1 })
      nextStep(3);
    } catch (error) {
      console.log(error)
      toast.error(error)
    }
  }

  function handlePrevStep() {
    nextStep(1);
  }

  useEffect(() => {
    setWorkSheet(JSON.parse(Cookies.get('worksheet')))
  }, [])

  return (
    <div className=" w-full min-h-full">
      <div className="bg-white h-[55px] border-b border-[#ECECED] duo:h-[64px] z-10 flex justify-end tablet:justify-between items-center px-[5vw]  w-full sticky top-0">
        <h3 className="text-[#0A0D14] hidden tablet:inline text-base font-semibold">
          Create Worksheet
        </h3>
        <div className="flex justify-center items-center gap-3">
          <button
            onClick={handlePrevStep}
            className="border border-[#E2E4E9] text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-[#525866]"
          >
            Cancel
          </button>
          <button
            onClick={skip}
            className="flex bg-[#1A6A73] justify-center items-center gap-3 text-sm font-medium py-2 duo:py-[10px] text-center px-4 rounded-[50px] text-white"
          >
            <p>{createLoading ? <FormLoader/> : 'Skip'}</p>
            {/* <img src={chevron} alt="chevron" /> */}
          </button>
        </div>
      </div>
      <div className=" mt-11">
        <div className=" w-[90vw]  max-w-[638px] mx-auto ">
          <div className="w-full relative">
            <div className="h-[40px] mt-[50px]  bg-white rounded-[10px] relative w-full ">
              <img
                src={searchIcon}
                alt="search"
                className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
              />
              <input
                style={{
                  outline: "none",
                  border: "1px solid #E2E4E9",
                  boxShadow: "none",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
              />
            </div>
            <div
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              className="mt-4 rounded-[16px]  max-h-[74vh] space-y-4 border border-[#E2E4E9] py-4 px-6 overflow-y-scroll bg-white  w-full"
            >
              <h2 className="font-medium text-[#868C98] text-xs">
                CLIENT LIST
              </h2>
              <div className="space-y-4">
                {isLoading ? 
                  <div className="flex my-4 mx-auto text-center justify-center">
                    <LuLoader2 className="animate-spin text-[24px]" />
                  </div>
                  :
                  clients?.data?.map(item => (
                    <div
                      className="flex cursor-pointer justify-between items-center mt-4"
                      key={item.id + item.role}
                    >
                      <div className="flex justify-start gap-3 items-center ">
                        <img className="w-10" src={item.avatar} alt="avartar" />
                        <div>
                          <h2 className="text-[#0A0D14] capitalize font-medium text-sm ">
                            {item.name}
                          </h2>
                          <p className="text-[#525866] text-xs pt-[2px] ">
                            {item.email}
                          </p>
                        </div>
                      </div>
                      <button 
                        type="button"
                        onClick={() => handleNextStep(item)}
                        className="bg-[#C4E4E9] rounded-[50px] py-[6px] px-3 text-[#1A6A73] font-medium text-sm"
                      >
                        Assign
                      </button>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignWorksheet;
