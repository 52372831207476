import * as yup from "yup"

export const WorkSheetConstant = {
  REGISTRATION_FORM: 'registration-form',
  WEEKLY_TASK: 'weekly-task',
  SCOPE_OPTIONS: ['registration-form', 'weekly-task']
};


export const questionSchema = yup.object({ 
  title: yup.string().required('Please enter a title'), 
  description: yup.string().required('Please enter a description'), 
  user_id: yup.number().nullable().positive().integer(), 
  type: yup.string().required().oneOf(WorkSheetConstant.SCOPE_OPTIONS), 
  questions: yup.array().when('type', { 
    is: WorkSheetConstant.REGISTRATION_FORM,  
    then: () => yup.array().of(yup.string().required('Please enter a question')).required(), 
    otherwise: () => yup.array().notRequired() 
  }),  
  week_days: yup.array().when('type', { 
    is: WorkSheetConstant.WEEKLY_TASK, 
    then: () => yup.array().of(yup.object().shape({ 
      day: yup.string().required().oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']), 
      tasks: yup.array().of(yup.object().shape({ 
        time: yup.string().required().oneOf(['morning', 'afternoon', 'evening']), 
        task: yup.string().required() 
      }))
    })).required(), 
    otherwise: () => yup.array().notRequired() 
  }) 
}).required(); 