import { useState } from "react";

function usePINValidator() {
  const [isValid, setIsValid] = useState(false);

  function validatePIN(pin) {
    // Validate the PIN here
    const isValidPIN = ["",/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pin)];
    // Assuming PIN should be exactly 4 digits
    const hasLowercase = /[a-z]/.test(pin);
    const hasUppercase = /[A-Z]/.test(pin);
    const hasDigit = /\d/.test(pin);
    const hasSpecialChar = /[@$!%*?&]/.test(pin);
    const isValidLength = pin.length >= 8;


    if (!hasLowercase) {
      return ["Password must contain at least one lowercase letter",false];
    }
    if (!hasUppercase) {
      return ["Password must contain at least one uppercase letter",false];
    }
    if (!hasDigit) {
      return ["Password must contain at least one digit",false];
    }
    if (!hasSpecialChar) {
      return ["Password must contain at least one special character",false];
    }
    if (!isValidLength) {
      return ["Password must be at least 8 characters long",false];
    }


    setIsValid(isValidPIN);

    return isValidPIN;
  }

  return { isValid, validatePIN };
}

export default usePINValidator;
