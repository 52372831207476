import { motion } from "framer-motion";
import vision from "../../assets/Assets/Images/vision.jpg";

const Vision = () => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      key="vision"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <h1 className="philosopher text-[48px] text-center font-bold mb-3 max-[767px]:text-[24px]">
        Vision Statement
      </h1>
      <div className="w-full max-h-[300px] overflow-hidden rounded-[10px] border-[10px] border-[#f0f0f0]">
        <img
          className="w-full object-cover object-center max-h-[300px]  mx-auto"
          src={vision}
          alt="passion led us here"
        />
      </div>
      <div className="flex mt-5 flex-col items-center justify-center text-[#333333]">
        <div>
          <h1 className="text-[18px] font-medium mb-[1%] max-[767px]:text-[16px] text-center">
            Our vision is to become the world leader in mental and emotional
            wellness, and a global model for innovation, inclusivity, and
            impact. We aspire to create a world where support for mental and
            emotional wellbeing is seamlessly integrated into daily life, and
            accessible to all, regardless of location or circumstance, through
            continuous innovation and a deep understanding of the human
            experience.
          </h1>
        </div>
      </div>
    </motion.div>
  );
};

export default Vision;
